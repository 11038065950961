/***********************************************
bootstrap customization
************************************************/
.badge {
  background-color: #777;
  border: 1px solid #777;
}

.badge-danger {
  background-color: #d43f3a;
  border: 1px solid #d43f3a;
}

.badge-warning {
  background-color: #d58512;
  border: 1px solid #d58512;
}

.badge-success {
  background-color: #398439;
  border: 1px solid #398439;
}

.badge-info {
  background-color: #269abc;
  border: 1px solid #269abc;
}

.badge-inverse {
  background-color: #333;
  border: 1px solid #333;
}

.badge-small {
  border-radius: 8px;
  width: 16px;
  height: 16px;
  padding: 2px 0;
  font-size: 0.65em;
}

.badge-text-color {
    color: #616161 !important;
}

.panel-header-font-small .tooltip-wrapper {
   display: inline-block !important;
   margin-left: -10px !important;
   margin-top: 0 !important;
}
.panel-header-font-small .info-icon{
    margin-top: 3px !important;
}
.panel-header-font-small .tooltip{
    height: 155px !important;
}

.rectangle {
    width: 172px;
    height: 37px;
    margin: 6px 0px 0px 16px;
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 2px #269cd2;
    background-color: white;
    display: flex;
    .sync-icon, .eye-icon {
        width: 16px;
        height: 16px;
        margin: 3px 0 2px 0;;
        font-size: 16px;
        line-height: 1.13;
        background-color: #1898db;
      }
    .sync-icon{
        mask: url("/assets/images/sync.png") no-repeat center/contain;
        -webkit-mask: url("/assets/images/sync.png") no-repeat center/contain;
      }
    .eye-icon {
        width: 26px;
        color: #269cd2;
        mask: url("/assets/images/eye.png") no-repeat center/contain;
        -webkit-mask: url("/assets/images/eye.png") no-repeat center/contain;
    }
    .sync-with-drc, .view-JSON {
        width: 116px;
        height: 21px;
        background-color: #1898db;
    }
    .sync-with-drc{
        mask: url("/assets/images/Sync_with_DRC.png") no-repeat center/contain;
        -webkit-mask: url("/assets/images/Sync_with_DRC.png") no-repeat center/contain;
    }
    .view-JSON {
        mask: url("/assets/images/View_JSON.png") no-repeat center/contain;
        -webkit-mask: url("/assets/images/View_JSON.png") no-repeat center/contain;
    }
  }
.row-status{
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: solid 1px #dedede;
    background-color: #f7f7f8;
    margin: 16px 8px -8px 0px;
    padding: 13px 16px 13px 16px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2px;
}

.secondRectwidth {
    width: 134px;
}

.psdtable{
    .table__common table tbody tr {
        height: auto;
    }
    .table__common table tbody tr td{
        padding: 14px 8px !important;
        font-family: Poppins;
        font-size: 14px;
        line-height: 1.07;
        letter-spacing: 0.2px;
        text-align: left;
        color: #212b36;
    }
    .table__common table thead{
        padding: 14px 8px !important;
        height: auto;
    }
}
.accordion-toggle:focus {
  outline: none;
}

/*  those css make the modal dialog background darker and add grey shadow around so we can
    apply out of container looking close button
*/

.modal-backdrop.in {
  opacity: 0.75;
}

/* .modal.in .modal-dialog { */
/* -webkit-box-shadow:0 0 40px white; */
/* -moz-box-shadow: 0 0 40px white; */
/* box-shadow:0 0 40px white; */
/* } */

/********************************
adding button definition from 4.0 beta
************************************/

.btn-outline-primary {
  color: #0275d8;
  background-image: none;
  background-color: transparent;
  border-color: #0275d8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.open > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
}

.btn-outline-primary:active:hover,
.btn-outline-primary:active:focus,
.btn-outline-primary:active.focus,
.btn-outline-primary.active:hover,
.btn-outline-primary.active:focus,
.btn-outline-primary.active.focus,
.open > .btn-outline-primary.dropdown-toggle:hover,
.open > .btn-outline-primary.dropdown-toggle:focus,
.open > .btn-outline-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #014682;
  border-color: #01315a;
}

.btn-outline-primary.disabled:focus,
.btn-outline-primary.disabled.focus,
.btn-outline-primary:disabled:focus,
.btn-outline-primary:disabled.focus {
  border-color: #43a7fd;
}

.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover {
  border-color: #43a7fd;
}

.btn-outline-secondary {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.open > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-secondary:active:hover,
.btn-outline-secondary:active:focus,
.btn-outline-secondary:active.focus,
.btn-outline-secondary.active:hover,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.active.focus,
.open > .btn-outline-secondary.dropdown-toggle:hover,
.open > .btn-outline-secondary.dropdown-toggle:focus,
.open > .btn-outline-secondary.dropdown-toggle.focus {
  color: #fff;
  background-color: #a1a1a1;
  border-color: #8c8c8c;
}

.btn-outline-secondary.disabled:focus,
.btn-outline-secondary.disabled.focus,
.btn-outline-secondary:disabled:focus,
.btn-outline-secondary:disabled.focus {
  border-color: white;
}

.btn-outline-secondary.disabled:hover,
.btn-outline-secondary:disabled:hover {
  border-color: white;
}

.table.table-horizontal {
  tbody > tr {
    th:first-child,
    td:first-child {
      width: 1px;
    }
  }
}

.table.tbody-striped {
  tbody {
    border: none;
    background: white;
  }

  tbody:nth-child(even) {
    background: #f9f9f9;
  }

  tr,
  td {
    background: transparent;
  }
}

.table.transparent {
  background: transparent;

  tbody > tr {
    background: transparent;
  }
}

.table.borderless {
  tbody > tr {
    th,
    td {
      border: none;
    }
  }
}
.related-accounts-table-row {
    width: 80%;
    @media (max-width: 1040px) {
        width: 100%;
    }
}
.table.table-striped > tbody {
  background: white;
}

// if a table is striped but is also showing a different background color, stripe the background slightly
$bg-colors: ('bg-success': (background-color: #dff0d8), 'bg-danger': (background-color: #f2dede), 'bg-warning': (background-color: #fcf8e3), 'bg-info': (background-color: #d9edf7));

@each $selector,
$map in $bg-colors {
  $bg-color: map-get($map, background-color);

  .table.table-striped > tbody > tr {

    &:nth-child(2n + 1).#{$selector}>td,
    &:nth-child(2n + 1)>td.#{$selector} {
      background-color: $bg-color;
    }

    &:nth-child(2n).#{$selector}>td,
    &:nth-child(2n)>td.#{$selector} {
      background-color: lighten($bg-color, 3);
    }
  }

  .table.table-striped > thead > tr > th.no-border-bottom {
    border-bottom: none !important;
  }

  .table.table-striped > tbody > tr.related-accounts-row {
    background-color: white ;
    td {
        border: none;
        .underlined {
            text-decoration: underline;
        }
    }

  }

  .table.table-hover > tbody > tr {

    &.#{$selector}:hover>td,
    >td.#{$selector}:hover {
      background-color: darken($bg-color, 2);
    }
  }
}

.panel {
  &.panel-default > .panel-body {
    border-top: 1px solid #ddd;
  }

  &.panel-primary > .panel-body {
    border-top: 1px solid #337ab7;
  }

  &.panel-success > .panel-body {
    border-top: 1px solid #d6e9c6;
  }

  &.panel-info > .panel-body {
    border-top: 1px solid #bce8f1;
  }

  &.panel-warning > .panel-body {
    border-top: 1px solid #faebcc;
  }

  &.panel-danger > .panel-body {
    border-top: 1px solid #ebccd1;
  }

  &.panel-transparent {
    background: none;
    border: none;
    padding: 0;
    box-shadow: none;

    .panel-heading {
      background: transparent;
      border: none;
      padding: 0;
    }

    .panel-body {
      background: transparent;
      border: none !important;
      padding: 0;
    }
  }
}

// make entire accordion title clickable
.panel-title > a {
  display: block;
}

code.plain {
  @include normal-text;

  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}

uib-accordion.panel-group-no-margin > .panel-group {
  margin: 0 !important;
}

.uib-daypicker table thead tr:first-child {
  display: table-row !important;
}

.form-group.form-group-no-margin {
  margin: 0 !important;
}
