@import url(https://fonts.googleapis.com/css?family=Montserrat);
* {
  margin: 0;
  padding: 0;
}
div.wizard-container {
  position: relative;
  width: 100%;
  height: 100%;
}
div.wizard-container table.wizard-table {
  font-family: montserrat, arial, verdana;
  text-align: left;
}
div.wizard-container table.wizard-table.horizontal {
  width: 100%;
  color: white;
  background: lightcyan;
  text-align: center;
}
div.wizard-container table.wizard-table.horizontal td {
  position: relative;
}
div.wizard-container table.wizard-table.horizontal td div[disabled],
div.wizard-container table.wizard-table.horizontal td button[disabled],
div.wizard-container table.wizard-table.horizontal td .btn[disabled],
div.wizard-container table.wizard-table.horizontal td div.disabled,
div.wizard-container table.wizard-table.horizontal td button.disabled,
div.wizard-container table.wizard-table.horizontal td .btn.disabled {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
}
div.wizard-container table.wizard-table.horizontal td div.stepTitle,
div.wizard-container table.wizard-table.horizontal td button.stepTitle,
div.wizard-container table.wizard-table.horizontal td .btn.stepTitle {
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
  margin: 5px;
  color: black;
}
div.wizard-container table.wizard-table.horizontal td div.stepTitle.default,
div.wizard-container table.wizard-table.horizontal td button.stepTitle.default,
div.wizard-container table.wizard-table.horizontal td .btn.stepTitle.default {
  color: black;
}
div.wizard-container table.wizard-table.horizontal td div.stepTitle.disabled,
div.wizard-container table.wizard-table.horizontal td button.stepTitle.disabled,
div.wizard-container table.wizard-table.horizontal td .btn.stepTitle.disabled {
  color: darkslategray;
}
div.wizard-container table.wizard-table.horizontal td div.stepTitle.visited,
div.wizard-container table.wizard-table.horizontal td button.stepTitle.visited,
div.wizard-container table.wizard-table.horizontal td .btn.stepTitle.visited {
  color: #ffa430;
}
div.wizard-container table.wizard-table.horizontal td div.stepTitle.completed,
div.wizard-container table.wizard-table.horizontal td button.stepTitle.completed,
div.wizard-container table.wizard-table.horizontal td .btn.stepTitle.completed {
  color: darkgreen;
}
div.wizard-container table.wizard-table.horizontal td div.firstHalf,
div.wizard-container table.wizard-table.horizontal td button.firstHalf,
div.wizard-container table.wizard-table.horizontal td .btn.firstHalf {
  width: 50%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 25%;
}
div.wizard-container table.wizard-table.horizontal td div.secondHalf,
div.wizard-container table.wizard-table.horizontal td button.secondHalf,
div.wizard-container table.wizard-table.horizontal td .btn.secondHalf {
  width: 50%;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 25%;
}
div.wizard-container table.wizard-table.horizontal td div.stepNumber,
div.wizard-container table.wizard-table.horizontal td button.stepNumber,
div.wizard-container table.wizard-table.horizontal td .btn.stepNumber {
  position: relative;
  z-index: 999 !important;
}
div.wizard-container table.wizard-table.horizontal td div.connector-completed,
div.wizard-container table.wizard-table.horizontal td button.connector-completed,
div.wizard-container table.wizard-table.horizontal td .btn.connector-completed {
  background-color: green;
}
div.wizard-container table.wizard-table.horizontal td div.connector-not-completed,
div.wizard-container table.wizard-table.horizontal td button.connector-not-completed,
div.wizard-container table.wizard-table.horizontal td .btn.connector-not-completed {
  background-color: dimgray;
}
div.wizard-container table.wizard-table.vertical {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  color: white;
  background: lightcyan;
}
div.wizard-container table.wizard-table.vertical td {
  position: relative;
  vertical-align: middle;
  text-align: center;
}
div.wizard-container table.wizard-table.vertical td div[disabled],
div.wizard-container table.wizard-table.vertical td button[disabled],
div.wizard-container table.wizard-table.vertical td .btn[disabled],
div.wizard-container table.wizard-table.vertical td div.disabled,
div.wizard-container table.wizard-table.vertical td button.disabled,
div.wizard-container table.wizard-table.vertical td .btn.disabled {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
}
div.wizard-container table.wizard-table.vertical td div.stepTitle,
div.wizard-container table.wizard-table.vertical td button.stepTitle,
div.wizard-container table.wizard-table.vertical td .btn.stepTitle {
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
  text-align: left;
  margin: 5px;
  color: black;
}
div.wizard-container table.wizard-table.vertical td div.stepTitle.default,
div.wizard-container table.wizard-table.vertical td button.stepTitle.default,
div.wizard-container table.wizard-table.vertical td .btn.stepTitle.default {
  color: black;
}
div.wizard-container table.wizard-table.vertical td div.stepTitle.disabled,
div.wizard-container table.wizard-table.vertical td button.stepTitle.disabled,
div.wizard-container table.wizard-table.vertical td .btn.stepTitle.disabled {
  color: darkslategray;
}
div.wizard-container table.wizard-table.vertical td div.stepTitle .current,
div.wizard-container table.wizard-table.vertical td button.stepTitle .current,
div.wizard-container table.wizard-table.vertical td .btn.stepTitle .current {
  color: #2124ed;
}
div.wizard-container table.wizard-table.vertical td div.stepTitle.visited,
div.wizard-container table.wizard-table.vertical td button.stepTitle.visited,
div.wizard-container table.wizard-table.vertical td .btn.stepTitle.visited {
  color: #ffa430;
}
div.wizard-container table.wizard-table.vertical td div.stepTitle.completed,
div.wizard-container table.wizard-table.vertical td button.stepTitle.completed,
div.wizard-container table.wizard-table.vertical td .btn.stepTitle.completed {
  color: darkgreen;
}
div.wizard-container table.wizard-table.vertical td div.firstHalf,
div.wizard-container table.wizard-table.vertical td button.firstHalf,
div.wizard-container table.wizard-table.vertical td .btn.firstHalf {
  width: 2px;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 0;
}
div.wizard-container table.wizard-table.vertical td div.secondHalf,
div.wizard-container table.wizard-table.vertical td button.secondHalf,
div.wizard-container table.wizard-table.vertical td .btn.secondHalf {
  width: 2px;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
}
div.wizard-container table.wizard-table.vertical td div.stepNumber,
div.wizard-container table.wizard-table.vertical td button.stepNumber,
div.wizard-container table.wizard-table.vertical td .btn.stepNumber {
  position: relative;
  z-index: 999 !important;
}
div.wizard-container table.wizard-table.vertical td div.connector-completed,
div.wizard-container table.wizard-table.vertical td button.connector-completed,
div.wizard-container table.wizard-table.vertical td .btn.connector-completed {
  background-color: green;
}
div.wizard-container table.wizard-table.vertical td div.connector-not-completed,
div.wizard-container table.wizard-table.vertical td button.connector-not-completed,
div.wizard-container table.wizard-table.vertical td .btn.connector-not-completed {
  background-color: dimgray;
}
div.wizard-container div.vertical-steps-container {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: auto;
}
