.search-filter-expand-link {
  display: block;
  position: relative;
  color: gray !important;
  text-align: center;
  margin: 10px 5%;

  div {
    display: inline-block;
    position: relative;
    font-size: 10pt;
    background: #f0f0f0;
    padding: 0 10px;
    white-space: nowrap;
  }


  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    display: inline-block;
    border-bottom: 1px solid lightgray;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &:hover
  {
    color: #555f61 !important;
  }
}
