﻿$base-border-color: #dfe3e8;
$base-background-color: #ebf5fa;
$th-background: #ebf5fa;
$font-family: "Poppins", sans-serif;
$base-padding: 16px;
$base-margin: 16px;
$base-font-size: 14px;
$small-font-size: 12px;
$base-line-blue: #0098db;
$base-white-color: #fff;
$base-blue-color: #e4f3e5;
$base-light-blue-color: #003e65;
$base-black-color: #212b36;
$base-green-color: #4caf50;
$base-grey-light-color: #dfe3e8;
$base-grey-medium-color: #919eab;
$base-blue-light-color: #006fb3;
$light-blue-color: #4686cb;
$base-grey-color: #707070;
$grey-medium-color: #f1f4f6;
$grey-bold-color: #d9e0e8;
$light-grey-color: #c4cdd5;
$base-yellow-color: #fdf8e6;
$light-yellow-color: #f2c831;
$warning-color: #f1c21b;
$warning-background-color: #fef9e7;
$base-red-color: #f4dee2;
$light-red-color: #b00020;
$primary-color: #0098db;
$white-color: #fff;
$base-mirage-color: #131523;
$athens-grey-color: #fafafb;
$boulder-color: #777;
$border-color: #dfe3e8;
$checkbox-color: #003e65;
$layout-border-color: #c4cdd5;
$shadow-color-small: rgba(0, 0, 0, 0.09);
$shadow-color-medium: rgba(0, 0, 0, 0.16);
$base-distance: 16px;
$preview-header-height: 26px;
$dark-color: #212b36;
$blue-color: #269cd2;
$tool-option-primary-color: #003e65;
$gray97-color: #f7f7f7;
$light-grey: #dedede;
$light-yellow-color: #ccbea0;
$gray86-color:#dbdbdb;
$silver-color: #b1b6ba;
$blue-turquoise: #50ae55;
$white-smoke-color: #f5f5f5;
$red-border-color: #912337;
$ghost-white-color: #f7f7f8;
$gray-close-color: #5d5d5d;
$screenSize800: 800px;
$screenSize660: 660px;
$screenSize850: 850px;
$screenSize900: 900px;
$screenSize750: 750px;
$screenSize689: 689px;

@mixin baseFLex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin baseTooltip {
  display: block;
  opacity: 1;
  font-family: $font-family;
  font-weight: 500;
  line-height: 1.14;
  text-align: left;
  color: #212b36;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d9e0e8;
  background-color: #f4f6f8;
  padding: 8px;
  position: absolute;
  z-index: 1000;
  animation: tooltips-html 200ms ease-out forwards;
}

@mixin orderError {
  border-color: $light-red-color;
  background-color: $light-red-color;
  color: white;
}
@mixin orderInProgress {
  border-color: $light-yellow-color;
  background-color: $light-yellow-color;
  color: $base-black-color;
}
@mixin orderInitiated {
  border-color: $gray86-color;
  background-color: $gray86-color;
  color: black;
}
@mixin orderCancelled {
  border-color: $silver-color;
  background-color: $silver-color;
  color: black;
}
@mixin orderCompleted {
  border-color: $blue-turquoise;
  background-color: $blue-turquoise;
  color: white;
}

@mixin tooltipArrow {
  content: "";
  position: absolute;
  bottom: -10px;
  background-color: #f4f6f8;
  transform: rotate(45deg);
  border-right: 1px solid #d9e0e8;
  border-bottom: 1px solid #d9e0e8;

  text-transform: none;
  user-select: none;
  pointer-events: none;
}

@mixin flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin customDropDownButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $drop-down-height;
    width: $drop-down-width;
    border-radius: 4px;
    border: solid 1px #e9e9ea;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #212b36;

    .caret {
        width: 13px;
        border-right: 6.5px solid transparent;
        border-left: 6.5px solid transparent;
        border-top: 6.5px dashed;
        color: #4d4d4d;
    }
}
@mixin infoIcon {
  width: 20px;
  height: 20px;
  margin-top: 1px;
  mask: url("/assets/images/table/info_icon.png") no-repeat center/contain;
  -webkit-mask: url("/assets/images/table/info_icon.png") no-repeat center/contain;
  background: $base-blue-light-color;
}
