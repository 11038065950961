/**********************************
system wide loader background container
***********************************/
#spinnerContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    display: none;
  }

  /**********************************
  floater to show information
  ***********************************/
  #topRightCornerFloater {
    position: absolute;
    width: 500px;
    height: 100%;
    overflow: auto;
    background: rgba(200, 200, 200, 1);
    z-index: 1000;
    top: 0;
    right: 0;
  }

  #topRightSearchFloater {
    position: absolute;
    height: 100%;
    overflow: auto;
    width: 100%;
    background: rgb(182, 200, 199);
    top: 0;
    right: 0;
    border: medium solid;
  }

  /* Remove list style */
  .list-style-none {
    list-style-type: none;
  }

  /****************************************************************************
  alignment classes
  top and left justified does not require any classes because they are the default behavior
  ******************************************************************************/
  .clear-float {
    clear: both;
  }

  .right-justified {
    float: right;
  }

  .hcenter-justified {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .vmiddle-justified {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .bottom-aligned {
    position: relative;
    top: 100%;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  ul.simpleList {
    list-style-position: inside;

    li {
      padding-left: 10px;
    }
  }

  ul.dashed {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;

    &>li:before {
      display: inline-block;
      content: '-';
      width: 1em;
      margin-left: -1em;
    }
  }

  .hand-pointer:hover {
    cursor: pointer;
  }

  // overflow
  .overflow-y-auto {
    overflow-y: auto;
  }

  // not visible but still there
  .invisible {
    visibility: hidden;
  }

  // margin and padding
  @each $prop in margin,
  padding {

    @each $label,
    $size in (no: 0, small: 5px, small-medium: 7px, medium: 10px, medium-large: 15px, large: 20px) {
      .#{$label}-#{$prop} {
        #{$prop}: $size !important;
      }

      .#{$label}-#{$prop}-top {
        #{$prop}-top: $size !important;
      }

      .#{$label}-#{$prop}-right {
        #{$prop}-right: $size !important;
      }

      .#{$label}-#{$prop}-bottom {
        #{$prop}-bottom: $size !important;
      }

      .#{$label}-#{$prop}-left {
        #{$prop}-left: $size !important;
      }
    }
  }

  .highlighted {
    background-color: yellow;
  }

  .normal-text {
    @include normal-text;
  }

  .code-wrapper {
    @include normal-text;

    background: #f0f0f0;
    border: 1px solid #dcdcdc;
    padding: 15px;
    border-radius: 3px;
  }

  .accordion-no-margin-bottom .panel-group {
    margin-bottom: 0;
  }

  .cursor-wait {
    cursor: wait;
  }

  hr.black-line {
    border-top: 1px solid black;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .color-enabled {
    $color: blue;

    color: $color;

    &:hover {
      color: darken($color, 20);
    }

    &:active {
      color: darken($color, 30);
    }
  }

  .not-editable {
    background-color: #eeeeee !important;
  }

  .not-editable-button {
    color: gray;
    &:hover {
        color: gray;
    }

  }

  .vertical-rule-background {
    position: relative;
    margin: 2px 0;

    >span {
      position: relative;
      background: white;
      color: #999;
      font-size: 0.8em;
    }

    &::before {
      top: 0;
      left: 50%;
      position: absolute;
      height: 100%;
      content: '';
      border-left: 1px solid #d0d0d0;
    }
  }

  // if the vertical rule element is inside a hover table, adjust the background to match
  .table-hover>tbody>tr:hover .vertical-rule-background>span {
    background-color: #f5f5f5;
  }

  // limit width of the first column of a table
  .table td.first-table-column-limit-width {
    white-space: normal;
    word-break: break-word;

    &:first-child {
      width: 25%;
      min-width: 150px;
      max-width: 200px;
    }
  }

  .color-deselected {
    $color: #8a8a8a;

    color: $color;

    &:hover {
      color: darken($color, 20);
    }

    &:active {
      color: darken($color, 30);
    }
  }

  .color-disabled {
    color: #8a8a8a;
  }

  .bg-changed {
    background: #b6eea9;
  }

  tr.disabled-row {
    background-color: lightgrey;
  }

  .footer-acadia {
    position: fixed;
    left: 0px;
    bottom: 0px;
    height: 30px;
    width: 100%;
    background: #999;
    padding-top: 5px;
    padding-right: 50px;
  }

  .line-height-30 {
    line-height: 30px;
  }

  .panel-header-font-huge {
    font-size: 30px;
    font-weight: bold;
  }

  .panel-header-font-medium {
    font-size: 24px;
    font-weight: bold;
  }

  .panel-header-font-small {
    font-size: 16px;
    font-weight: bold;
  }

  .span-font-medium {
    font-size: 13px;
  }

  .span-font-small {
    font-size: 0.8em;
  }

  label.required-field:after {
    content: ' *';
    color: red;
  }

  .fieldset-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;

    legend {
      text-align: left !important;
      width: auto;
      padding: 0 10px;
      border-bottom: none;
    }
  }

  .vcentered {
    vertical-align: middle;
  }

  .btn.right-margin {
    margin-right: 8px;
  }

  .dropzone {
    width: 100%;
    height: 100%;
    border: 2px dashed #0087F7;
    border-radius: 5px;
    background: white;
    padding: 0px 0px 0px 0px;
  }

  /* skin.css Style*/
  .upload-drop-zone {
    background: white;
    border: 2px dashed #0087F7;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .upload-drop-zone.drop {
    color: #222;
    border-color: #222;
  }

  .element-scrollable {
    height: 170px;
    overflow: auto;
  }

  /**************************************************
  alternate color divs inside container
  ***************************************************/
  // div.alternate-color-container {
  // }

  // div.alternate-color-container > div.row {
  // }

  div.alternate-color-container>div.row:nth-of-type(odd) {
    background: #989898;
  }

  .alternate-color-table tr:nth-child(odd) {
    background: #e7e7e7;
  }

  /*.alternate-color-table tr:nth-child(even){*/
  /*background: #dae5f4;*/
  /*}*/
  /**************************************************
  ng dialog width
  ***************************************************/

  .ngdialog.ngdialog-theme-default.large-dialog-width .ngdialog-content {
    width: 80%;
  }

  .custom-collapse-button {
    margin-right: 1px;
    margin-left: 1px;
  }

  .vcenter {
    position: relative;
    vertical-align: middle;
  }

  /**************************************************
  Non resizable text area
  ***************************************************/
  textarea.non-resize {
    resize: none;
  }

  textarea {
    resize: vertical;
  }

  /***********************************************
  Program related, but can be used any where
  ************************************************/
  .filter-modal-window .modal-dialog {
    width: 700px;
  }

  .tabbar-modal-window .modal-content {
    height: 360px;
    overflow: hidden;
  }
  .tableClass .row{
    border: 2px solid #006aa5;
    border-bottom: none;
    padding: 5px;
    &:last-child {
        border-bottom: 2px solid #006aa5;
      }
  }
   .scrollbarVisible{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 170px;
   }
   .marginLeft{
    margin-left: 1px;
    margin-right: 1px;
   }
  .border-around-div {
    display: inline-block;
    width: 670px;
    border: 1px solid red;
  }

  .border-around-item {
    display: inline-block;
    width: 200px;
    border: 1px solid red;
  }

  /****************************************
   for Image crop
  *****************************************/

  .cropArea {
    background: #E4E4E4;
    overflow: hidden;
    width: 350px;
    height: 250px;

    &.size-large {
      width: 600px;
      height: 400px;
    }
  }

  .branding-logo .cropArea {
    width: 550px;
    height: 300px;
  }

  // an image that fit to the parent div
  img.fit-to-div {
    object-fit: contain;
    width: 100%
  }


  /****************************************
   overwrite pop over css to make it bigger
  *****************************************/

  .popover {
    max-width: 600px;
    /* original max-width is only 276px */

    &.popover-normal-width {
      max-width: 278px;
    }

    &.popover-large-help-width {
      max-width: 800px;
    }

    &.rule-status-popover {
      .popover-body {
        max-width: 100%;
        font-family: Poppins;
        padding-bottom: 0px;

        .popover-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .verified-icon img,
          .close-icon img {
            display: inline-block;
            height: 14px;
          }

          .popover-title {
            font-weight: 500;
            text-align: start;
            flex-grow: 1;
            background: none;
            border: none;
          }
        }

        hr {
          margin: 0px;
        }
      }

      .popover-content {
        text-align: right;

        .cancel-popup {
          font-weight: 600;
          border: none;
        }
      }
    }
  }

  /**************************************
  Text overlay for preview image
  **************************************/
  div.previewProgramCategory {
    width: 340px;
    height: 136px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .previewProgramCategory>img.categoryImage {
    width: 100%;
    height: 100%;
  }

  .previewProgramCategory>img-crop.categoryImage {
    width: 100%;
    height: 100%;
  }

  /***********************************************
  Todo: Change the font later
  ************************************************/
  .previewProgramCategory>div.title {
    position: absolute;
    left: 30px;
    top: 120px;
    color: white;
    font-family: 'Arial Rounded MT Bold';
    font-size: 24pt;
  }

  .previewProgramCategory>div.programCount {
    position: absolute;
    left: 30px;
    top: 150px;
    color: white;
    font-family: 'Arial Rounded MT Bold';
    font-size: 14pt;
  }

  .img-pmi-logo {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url('../../assets/images/pmi_logo.png') no-repeat center top;
    background-size: contain;
  }

  .img-vibrent-logo {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url('../../assets/images/vibrent_logo.png') no-repeat center top;
    background-size: contain;
  }

  /*************************************************
  icon of close button similar to lightbox design
  **************************************************/
  .out-of-box-top-icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  /*************************************************
  input with red border for error
  **************************************************/
  .input-error {
    border-color: #ea0003;
  }

  .localize-input {
    display: inline;
    vertical-align: middle;
  }

  /*  todo: the width and height should match what's displayed in webapp, this css should be defined in common shared util */
  .form-icon-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }

  .tree-list-container {
    width: 100%;
    height: 500px;
    overflow: auto;
    border-style: dotted;
    border-width: 1px;

    &.no-border {
      border-style: none;
      border-width: 0;

    }

    &.max-height {
      height: auto;
    }
  }

  /**************************************************
  separator with title
  ***************************************************/
  div.separator-with-title {
    font: 15px sans-serif;
    font-style: italic;
    position: relative;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
  }

  div.separator-with-title:before {
    border-top: 2px solid #9a9a9a;
    content: '';
    margin: 0 auto;
    /* this centers the line to the full width specified */
    position: absolute;
    /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }

  div.separator-with-title span.title {
    /* to hide the lines from behind the text, you have to set the background color the same as the container */
    background: #fff;
    padding: 0 15px;
  }

  .box-border-with-shadow {
    border-style: solid;
    border-width: thin;
    border-radius: 3px;
  }

  .box-border-with-shadow:hover {
    -webkit-box-shadow: 0px 0px 30px 10px #292929;
    -moz-box-shadow: 0px 0px 30px 10px #292929;
    box-shadow: 0px 0px 30px 10px #292929;
  }

  .selected-container-node {
    background: #d5f2f5;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  /**************************************************
  others
  ***************************************************/
  div.vertical-input-field-group {
    margin-bottom: 15px;
  }

  .with-margin-below {
    margin-bottom: 10px;
  }

  .full-size-span {
    @extend .full-width !optional;

    display: block;
  }

  .preview-window {
    overflow-y: auto;
    background: white;
  }

  .half-center-span {
    display: block;
    width: 50%;
    margin: 0 auto;
  }

  pre.wrapped {
    white-space: pre-wrap;
  }

  pre.no-wrap {
    white-space: nowrap;
  }

  .export-table-width-id {
    width: 10%;
  }

  .export-table-width-name {
    width: 70%;
  }

  .filter-notification {
    display: inline-block;
    border-radius: 9px;
    background-color: #d9edf7;
    padding: 3px 18px 3px 6px;
    margin: 5px 5px 0 0;
    vertical-align: top;
    position: relative;
    cursor: default;

    &:hover {
      background-color: darken(#d9edf7, 5);
    }

    &:active {
      .fa {
        color: #dc3545;

        &:after {
          background: white;
        }
      }
    }

    .fa {
      position: relative;
      z-index: 0;

      &:after {
        content: '';
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        z-index: -1;
      }
    }

    .notification-close {
      position: absolute;
      top: 3px;
      right: 6px;
      display: inline-block;

      .notification-close-link {
        position: relative;
        border-radius: 50%;
        display: inline;

        &:hover .fa {
          color: #dc3545;

          &:after {
            background: white;
          }
        }

        &:active .fa {
          color: darken(#dc3545, 10);
        }
      }
    }
  }

  .accordion-arrow {
    color: white;
    background: blue;
    padding: 2px;
    border-radius: 3px;
  }

  .condition-query {
    border: 2px solid black;
    margin: 1%;
    padding: 2%;
    background-color: gainsboro;
  }

  .condition-item {
    border: 1px solid yellow;
    margin: 1%;
    padding: 2%;
    background-color: #f8faff;
  }

  .condition-criteria {
    border: 3px solid darkkhaki;
    margin: 1%;
    padding: 2%;
    background-color: whitesmoke;
  }

  /**********************************
  selection table
  ***********************************/
  // table header link colors
  .sortable-table-header-color-neutral {
    color: #666 !important;
  }

  table.highlight-selection {

    tr.selected,
    th.selected,
    td.selected {
      padding: 15px;
      color: black;

      //text-shadow:1px 1px 1px #568F23;
      border: 5px dotted #9ED929;
      background-color: #feffdd;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius: 5px 5px 0px 0px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  /**********************************
  hide element unless parent is being hovered on
  ***********************************/
  .show-children-on-hover {
    .show-on-parent-hover {
      display: none;
    }

    &:hover .show-on-parent-hover {
      display: inline-block;
    }
  }

  .scrollable-panel {
    overflow-x: scroll;
  }

  .custom-alert-danger {
    padding: 2px 5px;
    color: #b94a48;
    background-color: #f2dede;
    border: 1px solid #eed3d7;
    border-radius: 3px;
  }

  /*******************************************
  view detail page
   *******************************************/
  .view-label {
    padding-top: 15px;
    color: #333333;
    font-weight: bold;
  }

  .view-content {
    color: #444444;
  }

  .disabled-toggle img {
    pointer-events: none;
    opacity: 0.5;
  }

  /******************************************
  simple field sets
   ******************************************/
  fieldset.simple-field-set {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
  }

  legend.simple-field-set-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
  }

  /*******************************************
  preview screen shot
   *******************************************/

  img.screen-shot-preview {
    opacity: 0.75;
    filter: alpha(opacity=75);
    max-width: 100%;
    padding: 3px;
    border: 2px dashed green;
  }

  .details-box-with-border {
    margin: 5px;
    padding: 5px;
    border: 1px solid #9aaeea;
  }

  .details-box {
    margin: 5px;
    padding: 5px;
  }

  .details-box-with-shadow {
    margin: 5px;
    padding: 5px;
    box-shadow: 0px 0px 20px 2px #292929;
  }

  .margin-left-small {
    margin-left: 10px;
  }

  .margin-left-medium {
    margin-left: 15px;
  }

  .margin-left-large {
    margin-left: 18px;
  }

  .margin-left-tiny {
    margin-left: .5px;
  }

  .margin-top-medium {
    margin-top: 7px;
  }

  .margin-top-large {
    margin-top: 15px;
  }

  .margin-top-small {
    margin-top: 5px;
  }

  .margin-bottom-small {
    margin-bottom: 10px;
  }

  .margin-bottom-tiny {
    margin-bottom: 5px;
  }

  .underline {
    text-decoration: underline;
  }

  // scss code added to override jqx input field
  .jqx-widget-content {
    outline: #000;
    background: white !important;
    list-style-type: none;
    border-radius: 2px;
    border: 1px solid;
    padding-bottom: 5px;
    padding-left: 5px;

  }

  .wordBreak {
    max-width: 140px;
    word-wrap: break-word;

  }

  .formSearchTile {
    padding: 2px;
    box-shadow: 1px 1px 1px 1px #888888;

  }

  .scrollStyle {
    max-height: 250px;
    overflow-y: scroll;
  }

  .validation-success{
    color:#42ba96;
  }
  .warning {
    color: #eed202;
  }
  .validation-error {
    color:red;
  }
  .footerMargin {
    margin-top: 10px !important;
  }
  .marginB5{
    margin-bottom: 5px;
  }

  .btnUnderline {
    text-decoration: underline;
    padding: 1px;
  }

  .validation-feedback-main{
      border-top: 1px solid black;
       margin-top:20px
  }
  .validation-notes {
      border:1px solid;
      width:510px;
      margin-bottom: 10px;
      margin-top:10px;
      padding:5px;
  }
  .validation-completed-text{
      font-size:14px;
      font-weight:bold;
  }
  .validation-feed-div {
      font-size:18px;
      font-weight:bold;
      margin-bottom: 5px;
  }
  .marginL16{
      margin-left: 16px;
  }
  .marginTop20{

      margin-top: 20px;
  }
  .marginL5 {
      margin-left: 5px;
  }
  .header-text {
    width: 142px;
    height: 28px;
    margin: 0 404px 3px 1px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #003e65;
}

.sub-header-txt {
    width: 475px;
    height: 22px;
    margin: 3px 71px 16px 1px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #919eab;
}

.android-txt {
    margin: 5px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: rgba(33, 43, 54, 0.8);
}

.noticication-box {

    padding: 10px 10px 10px 16px;
    border-radius: 4px;
    border: solid 1px #4caf50;
    background-color: #e4f3e5;
}

.noticication-box-err {
    padding: 10px 10px 10px 16px;
    border-radius: 4px;
    border: solid 1px #f1c21b;
    background-color: #fef9e7;
}

.build-txt {
    margin: 0 0 6px 6px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #212b36;
}

.bld-txt-detail {
    margin: 6px 29px 0 24px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #003e65;
}

.times {
    margin: 0px 0 0px 122px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #212b36;
    float: right;
}

.panel-border {
    border: solid 0.8px #dfe3e8;
}

.container-margin {
    margin: 10px;
}

/*******************************************
 enhanced checkbox styling
 *******************************************/

.checkbox-lg .form-check-input{
  top: .8rem;
  scale: 1.4;
  margin-right: 0.7rem;
}

.checkbox-lg .form-check-label {
  padding-top: 13px;
}

.checkbox-xl .form-check-input {
  top: 1.2rem;
  scale: 1.7;
  margin-right: 0.8rem;
}

.checkbox-xl .form-check-label {
  padding-top: 19px;
}

.resetButton {
  width: 77px;
  height: 46px;
  padding: 13px 19px;
  border-radius: 4px;
  border: solid 1px #e9e9ea;
  background-color: #fff;
  font-family: "Poppins",sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1898db;
  opacity: .7;
}
