/* a lot of modification here for the css, the original css in the library doesn't work when put together with vibrent sass, a number of things needs to be overwritten */

.fc-canvas {
  position: relative;
  width: 100%;
  height: 100%;

  * {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: normal;
    -webkit-font-smoothing: auto;
    -webkit-box-sizing:content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    outline: none;
  }

  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }

  p {
    margin-bottom : 15px;
    margin-top: 15px;

    &.node-description {
      max-width: 220px;
    }
  }

  .fc-node {
    z-index: 1;
  }

  .innerNode {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    border-radius: 5px;

    background-color: #f1d1ac;
    color: #0b0e2b;
    font-size: 16px;

    &.pure-navigation-node {
      background-color: #bababa;
    }
  }

  .fc-node.fc-hover {
    -webkit-filter: brightness(70%);
    filter: brightness(70%);;
  }

  .fc-node.fc-selected {
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
  }

  .fc-node.fc-dragging {
    z-index: 10;
  }

  .fc-node p {
    padding: 0 15px;
    text-align: center;
  }

  .fc-topConnectors, .fc-bottomConnectors {
    position: absolute;
    left: 0;
    width: 100%;

    display: flex;
    flex-direction: row;

    z-index: -10;
  }

  .fc-topConnectors {
    top: -40px;
  }

  .fc-bottomConnectors {
    bottom: -40px;
  }

  .fc-magnet {
    display: flex;
    flex-grow: 1;
    height: 60px;

    justify-content: center;
  }

  .fc-topConnectors .fc-magnet {
    align-items: flex-end;
  }

  .fc-bottomConnectors .fc-magnet {
    align-items: flex-start;
  }

  .fc-connector {
    width: 18px;
    height: 18px;

    border: 10px solid transparent;
    -moz-background-clip: padding; /* Firefox 3.6 */
    -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
    background-clip: padding-box;
    border-radius: 50% 50%;
    background-color: #F7A789;
    color: #fff;
  }

  .fc-connector.fc-hover {
    background-color: #000;
  }

  .fc-edge {
    stroke: gray;
    stroke-width: 4;
    fill: transparent;
  }

  .fc-edge.fc-hover {
    stroke: gray;
    stroke-width: 6;
    fill: transparent;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 500;
    }
  }

  .fc-edge.fc-selected {
    stroke: red;
    stroke-width: 4;
    fill: transparent;
  }

  .fc-edge.fc-active {
    animation: dash 3s linear infinite;
    stroke-dasharray: 20;
  }

  .fc-edge.fc-dragging {
    pointer-events: none;
  }

  .edge-endpoint {
    fill: gray;
  }

  .fc-nodedelete {
    display: none;
  }

  .fc-selected .fc-nodedelete {
    display: block;
    position: absolute;
    right: -13px;
    top: -13px;
    border: solid 2px white;

    border-radius: 50%;
    font-weight: 600;
    font-size: 16px;

    height: 25px;
    padding-top: 2px;
    width: 27px;

    background: #494949;
    color: #fff;
    text-align: center;
    vertical-align: bottom;
  }

}


.button-overlay {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 10;
}

.button-overlay button {
  display: block;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 14px;
  background-color: #F15B26;
}

.button-overlay button:hover:not(:disabled) {
  border: 4px solid #b03911;
  border-radius: 5px;

  margin: -4px;
  margin-bottom: 11px;
}

.button-overlay button:disabled {
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
}



