@import "common";

.tooltip-wrapper {
  position: relative;
  color: $base-blue-light-color;
  padding-left: 10px;
  width: 20px;
  height: 20px;
  .info-icon {
    @include infoIcon;
  }
  &:hover .tooltip {
    @include baseTooltip;
    height: 164px;
    width: 399px;
    top: -177px;
    right: -207px;
    padding: 14px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px $base-border-color;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: normal;
    text-align: left;
    color: rgba(33, 43, 54, 0.8);
    .tooltip-title {
      line-height: 1.71;
      color: rgba(33, 43, 54, 0.8);
    }
    .tooltip-content {
      font-weight: normal;
      line-height: 1.5;
      color: $dark-color;
    }
  }
}
