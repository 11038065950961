.cron-table-cell {
  padding: 5px;
  margin: 10px;
  vertical-align: top;
}

.verticalSeparator {
  margin-left: 10px;
  margin-right: 10px;
  border-right: thin solid rgba(0, 0, 0, 0.1);
}

.cron-config-body {
  padding: 5px;
  margin-left: 15px;
}
dsfgikljha sdlkhjad iuh iousaf
.form-control-inline {
  min-width: 0;
  width: auto;
  display: inline;
}

.cron-row {
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: middle;
}

.cron-row > label {
  font-weight: normal;
}

.cron-title-label {
  font-size: 12pt;
  font-weight: bold;
}

.cron-button {
  margin-top: 10px;
  margin-bottom: 10px;
}
