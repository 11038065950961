.template-config-edit-dialog {
  .ngdialog-content {
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    flex-grow: 1;
  }
}
