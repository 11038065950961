/**************************************************
CSS for div-based tables. These tables do not behave like normal tables but need to be styled
like them.
***************************************************/
@import "common";
.div-table {
  .div-thead {
    padding-bottom: 5px;

     .div-tr {
      .div-th {
        font-weight: bold;
      }
    }
  }

   .div-tbody {
    .div-tr {
      margin: 0;

       &:first-child {
        border-top: 2px solid #ddd;
      }

       &:not(:first-child) {
        border-top: 1px solid #ddd;
      }

       .div-td {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

   &.striped .div-tbody .div-tr {
    &:nth-child(odd) {
      background-color: #f9f9f9;
    }

     &:nth-child(even) {
      background-color: white;
    }
  }
}

.jp-table-responsive {
  @extend .table-responsive;
  margin-bottom: 2.5rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-right: 1px #0098db solid;
  border-left: 1px #0098db solid;
  border-bottom: 1px #0098db solid;

  table.table {
    margin-bottom: 0;

    &.jp-table-colors {
      thead tr {
        padding: 0 1rem;
        background-color: #0098db;

        th {
          padding-bottom: 12px;
          padding-top: 10px;
          color: white;
          &:first-child {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }

          a.title-header {
            background: transparent;
            color: white;
            text-decoration: none;
            margin: 0;
            padding: 0;
            border: none;
            font-weight: bold;

            &:hover {
              text-decoration: underline;
            }
            &:focus {
              outline: none;
            }
            &:active {
              background: transparent;
            }
          }
        }
      }
      tbody tr {
        &:nth-child(even) {
          background-color: #ddf5ff;
        }
        td {
          &:first-child {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }
        }
      }
    }
  }
}

@mixin textWordWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

table {
  .table-field-expression-name {
    max-width: 130px;
    @include textWordWrap;
  }
  .table-property-expression-name {
    max-width: 140px;
    @include textWordWrap;
  }
}
.table__common {
  table, td, th {
    border: solid 1px $light-grey;
  }
  table {
    font-family: Poppins;
    width: 100%;
    border-collapse: collapse;
    max-height: calc(100vh - 300px);
    position: relative;
    overflow-x: auto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    thead {
      width: 100%;
      height: 58px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.23);
      border: solid 1px light-grey;
      background-color: $base-background-color;
      th {
        vertical-align: inherit;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 1.02px;
        color: $base-mirage-color;
      }
    }
    tbody {

      line-height: 1.31;
      letter-spacing: 0.23px;
      color: $dark-color;
      tr {
        .no-data-available {
          display: flex;
          justify-content: center;
          margin-top: 3px;
          .info-icon {
            @include infoIcon;
            background: $base-black-color;
            margin: 0px 8px 0px 0px;
          }
          .no-data-text {
            font-size: 16px;
          }
        }
        .bold-text {
          font-weight: 600;
        }
        .created-date, .updated-date {
          margin-top: -8px;
        }
        .updatedDate{
            div{
               padding-right: 5px;
               display: flex;
            }
         }
        height: 79px;
        &:nth-child(even) {
          background-color: $gray97-color;
        }
        &:nth-child(odd) {
          background-color: white;
        }
        td {
          padding-top: 26px;
          .order-status {
            border-radius: 16px;
            display: initial;
            padding: 4px 8px;
          }
          .order-status.error {
            @include orderError;
          }
          .order-status.in_progress {
            @include orderInProgress;
          }
          .order-status.initiated {
            @include orderInitiated;
          }
          .order-status.cancelled {
            @include orderCancelled;
          }
          .order-status.completed {
            @include orderCompleted;
          }

        }
      }
      tr:hover {
        background-color: $base-background-color;
      }

    }
    /* SORTER */
    .sorter {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      top: 3px;
      cursor: pointer;
      position: relative;

      .up {
        position: absolute;
        color: $base-grey-medium-color;
      }
      .up.active {
        color: $base-blue-light-color;
      }
      .down {
        color: $base-grey-medium-color;
      }
      .down.active {
        color: $base-blue-light-color;
      }
    }
    .sorter.active .up,
    .sorter.active .down {
      color: $base-blue-light-color;
    }
    .sorting-column {
      display: flex;
      justify-content: left;
      margin-top: 3px;
      padding-right: 10px;
    }
  }
  .view-button {
    width: 37px;
    height: 35px;
    padding: 8px 10px;
    margin-top: -5px;
    border-radius: 4px;
    background-color: $blue-color;
    position: relative;
    color: white;
    &:hover .tooltip {
      @include baseTooltip;
      width: 182px;
      height: 46px;
      top: -70px;
      right: -70px;
      padding: 14px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
      border: solid 1px $base-border-color;
      font-size: 14px;
      font-weight: 600;
      color: rgba(33, 43, 54, 0.8);
    }
  }
}
