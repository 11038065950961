// calculate square roots
@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

// firefox only mixin
@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}

// main text color
@mixin normal-text {
  color: #333;
}
