.single-line-search-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0;

  label {
    padding-top: 2px;
  }

  // outlined search type button
  .btn-outline-primary.btn-info {
    padding: 6px 7px;
    margin-left: 7px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    outline: 0;

    &:hover {
      background: #0098db;
    }
    &:focus {
      outline: 0;
    }
  }

  .btn.btn-primary {
    outline: 0;
    &:focus {
      outline: 0;
    }
  }

  // search bar
  input.form-control {
    display: flex;
    position: unset;
    width: unset;
    height: unset;
    flex-direction: row;
    text-align: right;
    flex: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

