@import "common";

@mixin fontBase {
  font-family: "OpenSans-Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

@mixin flexBase {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin activeMode {
  background-color: $primary-color;
  color: $white-color;
}

@mixin mode {
  @include fontBase;
  background-color: $border-color;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  color: #919eab;
  border: 1px solid #dee3e7;
  width: 62px;
  height: 36px;
}

@mixin activeDevice {
  content: "";
  position: absolute;
  background-color: $blue-color;
  top: 44px;
  height: 2px;
  left: 0;
}

@mixin previewLayout {
  border-radius: 4px;
  border: solid 1px $layout-border-color;
}

@mixin content {
  height: calc(100% - 53px - 54px);
  margin-top: 8px;
}

@mixin desktopLayout {
  width: 1024px;
  height: 768px;
}

@mixin mobileLayout {
  width: 360px;
  height: 800px;
}

@mixin inputNoResult {
  border: solid 1px $border-color;
  box-shadow: 0 3px 6px 0 $shadow-color-medium;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

@mixin videoContent {
  container-flex-wrapper {
    preview-form-field-video-display > div:first-child {
      width: 568px !important; // for new preview (side by side)
      iframe {
        height: 320px !important;
      }
    }
  }
}

@mixin customToolCheckbox {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 6px;
}

@mixin scrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: $border-color;
    background-clip: content-box;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bcc0c4;
  }
}

// CONTAINER ELEMENT
.preview {
  height: 100vh;
  background-color: $white-color;
  // HEADER
  .preview__header {
    @include flexBase;
    width: calc(100% + 30px);
    height: 64px;
    box-shadow: 0 3px 3px 0 $shadow-color-small;
    margin: 0 -15px;
    padding: 14px 24px;
    background-color: $white-color;
    position: relative;
    z-index: 2;

    .preview__header__mode {
      width: 224.16px;
      .preview__header__mode__edit {
        @include mode;
        border-radius: 4px 0 0 4px;
        margin-right: -4px;
        width: 85px;
      }
      .preview__header__mode__view {
        @include mode;
        border-radius: 0 4px 4px 0;
        width: 93px;
      }
      .active {
        @include activeMode;
      }
    }

    .preview__header__device {
      display: flex;
      .preview__header__device__desktop {
        position: relative;
        cursor: pointer;
        height: $preview-header-height;
      }
      .preview__header__device__desktop--mobile:hover .tooltip,
      .preview__header__device__desktop:hover .tooltip,
      .preview__header__device__mobile:hover .tooltip,
      .preview__header__device__tablet:hover .tooltip {
        @include baseTooltip;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        width: 105px;
        height: 32px;
        top: 32px;
        margin-left: -34px;
        text-align: center;
        padding: 5px;
      }
      .preview__header__device__mobile:hover .tooltip,
      .preview__header__device__tablet:hover .tooltip {
        margin-left: -40px;
      }
      .preview__header__device__desktop--mobile:hover .tooltip {
        width: 221px;
        margin-left: -85px;
      }
      .preview__header__device__desktop--mobile:hover .tooltip::after,
      .preview__header__device__desktop:hover .tooltip::after,
      .preview__header__device__mobile:hover .tooltip::after,
      .preview__header__device__tablet:hover .tooltip::after {
        top: -5px;
        width: 6px;
        height: 8px;
        transform: rotate(225deg);
      }
      .preview__header__device__desktop--mobile,
      .preview__header__device__desktop,
      .preview__header__device__mobile,
      .preview__header__device__tablet {
        position: relative;
        cursor: pointer;
        margin: 0 12px;
        height: $preview-header-height;
      }
      .preview__header__device__desktop--mobile {
        position: relative;
        height: $preview-header-height;

        label {
          cursor: pointer;
        }

        .preview__desktop__mobile {
          display: none;
        }

        .preview__header__device__desktop--mobile__notify {
          @include fontBase;
          position: absolute;
          display: none;
          top: 48px;
          left: -75px;
          width: 203px;
          height: 31.3px;
          border-radius: 4px;
          box-shadow: 0 0 4px 0 $shadow-color-medium;
          border: solid 1px #d9e0e8;
          background-color: #f4f6f8;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          ul {
            list-style: none;
            li {
              @include fontBase;
              font-weight: normal;
              line-height: 1.5;
              color: #171725;
              margin-bottom: 8px;
            }
            li.active {
              color: #1898db;
            }
          }
        }

        .preview__header__device__desktop--mobile__notify::after {
          content: "";
          position: absolute;
          top: -30px;
          left: 0;
          width: 203px;
          height: 30px;
          background: transparent;
        }
        .preview__header__device__desktop--mobile__notify::before {
          content: "";
          position: absolute;
          top: -8px;
          left: 81px;
          width: 15px;
          height: 15px;
          background: #f4f6f8;
          transform: rotate(45deg);
          border-top: 1px solid #d9e0e8;
          border-left: 1px solid #d9e0e8;
          z-index: 1;
        }
      }

      .preview__header__device__desktop--mobile.active::after {
        @include activeDevice;
        width: 32px;
      }
      .preview__header__device__mobile.active::after {
        @include activeDevice;
        width: 32px;
        left: -5px;
      }
      .preview__header__device__tablet.active::after {
        @include activeDevice;
        width: 32px;
        left: -4px;
      }
      .preview__header__device__desktop.active::after {
        @include activeDevice;
        width: 30px;
        left: 2px;
      }

      .preview__header__device__desktop--mobile:hover
        .preview__header__device__desktop--mobile__notify {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .preview__header__function {
      @include fontBase;
      display: flex;
      align-items: center;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: normal;
      color: $primary-color;

      .preview__header__function__language {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        i {
          margin-left: 4px;
          font-size: 20px;
        }
        .preview__header__function__language__icon {
          display: flex;
          align-items: center;
        }
        .preview__header__function__language__option {
          position: absolute;
          width: 160px;
          // height: 107.5px;
          top: 41px;
          right: -20px;
          padding: 8px 10.2px;
          box-shadow: 0 0 4px 0 $shadow-color-medium;
          border: solid 1px $border-color;
          border-radius: 4px;
          background-color: $white-color;
          cursor: default;
          display: none;

          .arrow {
            position: absolute;
            top: -8.5px;
            right: $base-distance;
            width: 16.5px;
            height: $base-distance;
            transform: rotate(45deg);
            background: $white-color;
            border-left: 1px solid $border-color;
            border-top: 1px solid $border-color;
          }

          .preview__header__function__language__option--select {
            @include fontBase;
            line-height: 1.5;
            letter-spacing: normal;
            color: #919eab;
            margin-bottom: 6.5px;
          }

          .preview__header__function__language__option--select__spanish,
          .preview__header__function__language__option--select__english {
            @include fontBase;
            line-height: 1.5;
            letter-spacing: normal;
            color: $dark-color;
            margin-bottom: 9px;
            cursor: pointer;
          }
        }
      }

      .preview__header__function__language:hover
        .preview__header__function__language__option {
        display: block;
      }
      .preview__header__function__language:before {
        content: "";
        position: absolute;
        width: 160px;
        height: 35.5px;
        right: -20px;
        top: 6px;
      }
      .preview__header__function__chart {
        display: flex;
        align-items: center;
        cursor: pointer;
        .preview__header__function__chart__icon {
          margin-left: 19.2px;
        }
        .preview__header__function__chart__text {
          margin-left: 8px;
        }
      }
    }
  }

  // TOOLBAR
  .preview__toolbar {
    @include flexBase;
    width: calc(100% + 30px);
    height: 64px;
    margin: 0 -15px;
    padding: 14px 24px;
    border-bottom: solid 1px $border-color;
    background-color: $white-color;
    user-select: none;

    .preview__toolbar__option {
      display: flex;

      .preview__toolbar__option_icon {
        @include fontBase;
        font-size: $base-distance;
        display: flex;
        align-items: center;
        color: #003E65;
        margin-top: 6px;

        i {
          font-size: 22px;
          margin-bottom: 4px;
          margin-right: 6px;
        }
      }

      .preview__toolbar__option__items {
        @include fontBase;
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        color: $dark-color;
        cursor: pointer;

        .preview__toolbar__option__dropdown {
          @include customDropDownButton;
          width: 220px;
          cursor: pointer;

          span:nth-child(1) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .preview__toolbar__option__dropdown.no__option {
          opacity: 0.7;
        }
        .preview__toolbar__option__dropdown.has__option {
          opacity: 1;
          color: $tool-option-primary-color;
          font-weight: 600;
        }

        .dropdown__menu__days__item {
          width: 220px;
          padding: 10px 12px;
          background-color: $white-color;
          border-width: 0;
          margin-top: $base-margin;
          filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.16));
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.16);

          input[type="checkbox"].checkbox-filter {
            height: 18px;
            width: 18px;
            border-radius: 18px;
            vertical-align: middle;
            border: 2px solid $tool-option-primary-color;
            outline: none;
            -webkit-appearance: none;
            -webkit-transition: box-shadow 200ms;
          }

          input[type="checkbox"].checkbox-filter:checked {
            background-color: $tool-option-primary-color;
          }

          input[type="checkbox"].checkbox-filter:checked::after {
            @include customToolCheckbox;
            right: -7px;
            background: $white-color;
          }

          input[type="checkbox"].checkbox-filter:not(:checked)::after {
            @include customToolCheckbox;
            right: -7px;
            background: $tool-option-primary-color;
          }

          li {
            padding-bottom: 8px;
            input {
              margin-left: 0;
            }
          }
        }

        .dropdown__menu__days__item::after {
          right: -4px;
          @include arrowUp;
        }

        .dropdown__menu__days__item::before {
          content: "";
          position: absolute;
          top: -16px;
          left: 0;
          width: 220px;
          height: 16px;
        }

        div {
          img {
            width: $base-distance;
            height: $base-distance;
          }

          label {
            @include fontBase;
            font-weight: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $dark-color;
            margin-bottom: 0;
            cursor: pointer;
          }
        }

        input[type="checkbox"] {
          cursor: pointer;
          margin: 0 4px 2px $base-distance;
          height: 18px;
          width: 18px;
          vertical-align: middle;
          border-radius: 2px;
          border: 2px solid $layout-border-color;
          outline: none;
          -webkit-appearance: none;
          -webkit-transition: box-shadow 200ms;
        }

        input[type="checkbox"]:checked {
          border-color: $checkbox-color;
          background-color: $checkbox-color;
        }

        input[type="checkbox"]:checked ~ label {
          color: $primary-color;
        }

        input[type="checkbox"]:checked::after {
          content: "";
          display: block;
          width: 7px;
          height: 12px;
          border: solid $white-color;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          margin-left: 3.8px;
          margin-top: -1px;
        }

        .navigation__disabled {
          pointer-events: none;
          color: #707070;
        }
      }

      .preview__toolbar__option__items:hover {
        .dropdown__menu__days__item {
          display: block;
        }
        .preview__toolbar__option__dropdown,
        .preview__toolbar__option__dropdown.no__option {
          opacity: 1;
          color: $tool-option-primary-color;
        }

        .preview__toolbar__option__dropdown.has__option {
          font-weight: normal;
        }
      }

      @media (max-width: 1660px) {
        .preview__toolbar__option__items.wrap {
          max-width: 560px;
          flex-wrap: wrap;
        }
      }
    }
  }

  // PREVIEW
  .preview__solution__screen {
    @include flexBase;
    @include scrollbar;
    width: calc(100% + 30px);
    height: calc(100vh - 128px);
    background-color: $white-color;
    margin: 0 -15px;
    overflow: auto;
    justify-content: center;
  }

  .preview__solution__screen__container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 16px;
    .preview-body {
      border: 0;
      overflow: hidden;
    }
  }
}

.preview__toolbar__search {
  position: fixed;
  top: 79px;
  right: 0;

  .input-group.search {
    position: relative;
    max-width: 520px;

    .search-btn-icon {
      position: absolute;
      right: 24px;
      top: -6px;
      width: 82px;
      height: 46px;
      background-color: #007bc7;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 0;
      padding: 6px;
      .search-text {
        @include fontBase;
        color: $white-color;
      }
      .search-icon-navigation {
        right: 5px;
        top: 15px;
      }
    }

    input[type="text"]#search {
      @include fontBase;
      float: right;
      width: 95px;
      height: 46px;
      border-radius: 4px;
      padding: 6px 28px 6px 4px;
      margin-right: 24px;
      margin-top: -6px;
      border: solid 1px $border-color;
      box-shadow: none;
      outline: 0 none;
      z-index: 0;
      box-shadow: 0 3px 6px 0 $shadow-color-medium;
    }

    input[type="text"]#search.search-full-width {
      min-width: 520px;
      padding: 6px 91px 6px 8px;
    }

    input[type="text"]#search.show-autocomplete {
      box-shadow: 0 3px 6px 0 $shadow-color-small;
      border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    input[type="text"]#search.show-autocomplete ~ .autocomplete {
      display: block;
    }

    ::placeholder {
      font-family: "OpenSans-Regular", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.4);
    }

    i {
      position: absolute;
      right: 0;
      top: 8px;
      right: 32px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 21px;
      z-index: 0;
      margin-top: -2px;
    }

    .autocomplete {
      position: absolute;
      top: 38px;
      left: 0;
      min-width: 520px;
      max-height: 180px;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 0 0 4px 4px;
      border: solid 1px $border-color;
      border-top-width: 0;
      background-color: $white-color;
      display: none;
      z-index: -1;

      ul {
        list-style: none;
        cursor: pointer;
        li {
          @include fontBase;

          width: 100%;
          margin: 0 -16px;
          padding-left: 8px;
          line-height: 1.14;
          letter-spacing: normal;
          color: #000;
          margin: 5px 0;
          height: 30.5px;

          div {
            @include flexBase;
            height: 30.5px;
            width: 100%;
          }
        }
        li:nth-child(1) {
          margin-top: 8px;
        }

        li:hover {
          background-color: #f4f6f8;
        }
      }
    }

    .autocomplete.hidden {
      height: 0;
    }

    @media only screen and (max-width: 1600px) {
      input[type="text"]#search.search-full-width,
      .autocomplete {
        min-width: 360px;
        max-width: 360px;
      }
    }

    @media only screen and (max-width: 1400px) {
      input[type="text"]#search.search-full-width,
      .autocomplete {
        min-width: 280px;
        max-width: 280px;
      }
    }
  }

  .input-group.search:not(:hover) {
    input[type="text"]#search ~ .autocomplete {
      display: none;
    }

    input[type="text"]#search.show-autocomplete {
      @include inputNoResult;
    }
  }
}

// PROGRESS
.progress__layout {
  width: calc(100% + 16px);
  border-radius: 4px 4px 0 0;
  height: 54px;
  margin-left: -8px;
  padding: 12px $base-distance;
  box-shadow: 0 3px 5px 0 $shadow-color-small;
  background-color: $white-color;
  overflow: hidden;
  @include flexBase;

  .progress__layout__breadcrumb {
    @include fontBase;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: normal;

    .progress__layout__breadcrumb__dashboard {
      color: $primary-color;
    }

    .progress__layout__breadcrumb__content {
      color: $dark-color;
    }
  }

  .progress__layout__progress {
    .progress__layout__progress__container {
      position: relative;
      width: var(--width);
      height: 7px;
      border-radius: 3px;
      background-color: #99dcc3;
      overflow: hidden;

      .progress__layout__progress__percent {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--percent);
        height: 7px;
        border-radius: 3px;
        background-color: #11836d;
      }
    }
  }
}

// DESKTOP
.desktop__layout {
  @include previewLayout;
  @include desktopLayout;
  margin: $base-distance auto;
  overflow: hidden;

  .form-wrapper-window {
    position: relative;
  }
  .form-wrapper-window
    > div[ng-if="!displayObject || displayObject.templateType.name == templateType.NONE.name"] {
    padding: 8px;
  }

  .preview-page-modal {
    padding: 0 8px;
    div.form-page-content {
      @include content;
    }
  }
}

.desktop__layout.show__profile__value,
.desktop__layout.show__form__entry {
  @include videoContent;
}

// MOBILE
.mobile__layout {
  @include previewLayout;
  @include mobileLayout;
  margin: $base-distance auto;
  overflow: hidden;

  .progress__layout__breadcrumb {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }

  .form-wrapper-window {
    position: relative;
  }

  .form-wrapper-window
    > div[ng-if="!displayObject || displayObject.templateType.name == templateType.NONE.name"] {
    padding: 8px;
  }

  .preview-page-modal {
    padding: 0 8px;
    div.form-page-content {
      @include content;
    }
  }

  .preview-page-modal {
    div.form-page-btn-bar {
      .btn-primary {
        padding: 0 6px !important;
      }
    }
  }

  preview-form-field-text-label > div:first-child {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

// Tabet
.tablet__layout {
  @include previewLayout;
  margin: 16px auto;
  width: 768px;
  height: 1024px;
  overflow: hidden;

  .progress__layout__breadcrumb {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 500px;
  }

  .form-wrapper-window {
    position: relative;
  }

  .form-wrapper-window
    > div[ng-if="!displayObject || displayObject.templateType.name == templateType.NONE.name"] {
    padding: 8px;
  }

  .preview-page-modal {
    padding: 0 8px;
    div.form-page-content {
      @include content;
    }
  }
}

// DESKTOP + MOBILE
.mobile__desktop__layout {
  display: flex;
  justify-content: space-between;
  .desktop {
    min-width: 70%;
    margin: 16px 4px 16px 0;
    overflow: auto;
    .desktop__layout {
      height: 800px;
      margin: 0 auto;
      width: 100%;
    }

    .desktop__layout.desktop__with__entry {
      .default-system-styles {
        width: initial !important;
      }
      .form-wrapper-window > div {
        width: initial !important;
      }

      @media (min-width: 1800px) {
        preview-form-mode[element="previewItem.formVersionWrapper.viewModeWrapper"] {
          container-flex-wrapper {
            preview-form-field-view-any-reference > div:first-child {
              width: 1400px !important;
            }
          }
        }

        preview-form-field-signature-box > div:first-child,
        preview-form-sub-field-radio-options > div:first-child > div:first-child,
        preview-form-field-radio-selector > div:first-child,
        preview-form-field-day-selector > div:first-child,
        preview-form-field-boolean-selector > div:first-child,
        preview-form-component > div:first-child,
        preview-form-field-text-label > div:first-child,
        preview-form-field-number-input > div:first-child,
        preview-form-sub-field-dropdown-options > div:first-child,
        preview-form-field-dropdown > div:first-child,
        preview-form-field-text-input > div:first-child,
        preview-form-field-multi-select > div:first-child,
        preview-sub-field-multi-select-options > div:first-child {
          width: 1400px !important; // for new preview (side by side)
        }
        preview-form-field-text-label div[aria-label="Show transcript"],
        preview-form-field-text-label div[aria-label="Ocultar guion del audio"],
        preview-form-field-text-label div[aria-label="Mostrar guion del audio"] {
          margin-left: 350px;
        }
        address-verification > div:first-child {
          width: 1400px !important; // for new preview (side by side)
        }
        container-flex-wrapper > div:first-child {
          justify-content: center !important; // for new preview (side by side)
        }
      }

      @media (max-width: 1600px) {
        preview-form-mode[element="previewItem.formVersionWrapper.viewModeWrapper"] {
          container-flex-wrapper {
            preview-form-field-view-any-reference > div:first-child {
              width: 1000px !important;
            }
          }
        }

        preview-form-field-signature-box > div:first-child,
        preview-form-sub-field-radio-options > div:first-child > div:first-child,
        preview-form-field-radio-selector > div:first-child,
        preview-form-field-day-selector > div:first-child,
        preview-form-field-boolean-selector > div:first-child,
        preview-form-component > div:first-child,
        preview-form-field-text-label > div:first-child,
        preview-form-field-number-input > div:first-child,
        preview-form-sub-field-dropdown-options > div:first-child,
        preview-form-field-dropdown > div:first-child,
        preview-form-field-text-input > div:first-child,
        preview-form-field-multi-select > div:first-child,
        preview-sub-field-multi-select-options > div:first-child {
          width: 1000px !important; // for new preview (side by side)
        }
        preview-form-field-text-label div[aria-label="Show transcript"],
        preview-form-field-text-label div[aria-label="Ocultar guion del audio"],
        preview-form-field-text-label div[aria-label="Mostrar guion del audio"] {
          margin-left: 150px;
        }
        address-verification > div:first-child {
          width: 1000px !important; // for new preview (side by side)
        }
        container-flex-wrapper > div:first-child {
          justify-content: center !important; // for new preview (side by side)
        }
      }

      @media (max-width: 1400px) {
        preview-form-mode[element="previewItem.formVersionWrapper.viewModeWrapper"] {
          container-flex-wrapper {
            preview-form-field-view-any-reference > div:first-child {
              width: 750px !important;
            }
          }
        }

        preview-form-field-signature-box > div:first-child,
        preview-form-sub-field-radio-options > div:first-child > div:first-child,
        preview-form-field-radio-selector > div:first-child,
        preview-form-field-day-selector > div:first-child,
        preview-form-field-boolean-selector > div:first-child,
        preview-form-component > div:first-child,
        preview-form-field-text-label > div:first-child,
        preview-form-field-number-input > div:first-child,
        preview-form-sub-field-dropdown-options > div:first-child,
        preview-form-field-dropdown > div:first-child,
        preview-form-field-text-input > div:first-child,
        preview-form-field-multi-select > div:first-child,
        preview-sub-field-multi-select-options > div:first-child {
          width: 750px !important; // for new preview (side by side)
        }
        preview-form-field-text-label div[aria-label="Show transcript"],
        preview-form-field-text-label div[aria-label="Ocultar guion del audio"],
        preview-form-field-text-label div[aria-label="Mostrar guion del audio"] {
          margin-left: 25px;
        }
        address-verification > div:first-child {
          width: 750px !important; // for new preview (side by side)
        }
        container-flex-wrapper > div:first-child {
          justify-content: center !important; // for new preview (side by side)
        }
      }

      container-flex-wrapper {
        preview-form-field-video-display > div:first-child {
          width: 700px !important; // for new preview (side by side)

          iframe {
            height: 390px !important;
          }
        }
      }
      .preview-page-modal {
        div.form-page-btn-bar {
          width: 100% !important; // for new preview (side by side)
        }
      }
      preview-form-sub-field-canvas > div:first-child {
        width: 1340px !important; // for new preview (side by side)
      }
    }

    .desktop__layout.show__profile__value,
    .desktop__layout.show__form__entry {
      @include videoContent;
    }
  }
  .mobile {
    overflow: auto;
    min-width: 360px;
    margin: 16px 0 0 0;
    .mobile__layout {
      margin: 0;
    }
  }

  @media (min-width: 1800px) {
    .desktop {
        min-width: 80%;
    }
  }
}

// PREVIEW CONTENT
.desktop__layout,
.mobile__layout,
.tablet__layout,
.mobile__desktop__layout {
  div.form-wrapper-window {
    height: auto;
  }

  .preview-page-modal {
    position: relative;
    padding: 0 8px;
    div.form-page-btn-bar {
      position: absolute;
      bottom: 0;
      left: -1px;
      box-shadow: 0 -1px 6px 0 $shadow-color-medium;
      border-radius: 0 0 4px 4px;
    }
    div.form-page-content {
      @include content;
      padding: 10px 10px 10px 20px;
    }

    @include scrollbar;
  }
  .form-page-btn-bar {
    button.btn-secondary {
      border-color: $primary-color;
    }
  }
  .new-preview-main-container {
    top: 7px;
    position: relative;
    .new-form-preview-body {
      border: 0;
    }
    .preview-body {
      border: 0;
    }
  }
}

// LAYOUT FORM ENTRY
.preview__solution__screen.show__form__entry,
.preview__solution__screen.show__profile__value {
  width: 52.5%;
}
.desktop__layout.show__form__entry,
.desktop__layout.show__profile__value,
.mobile__desktop__layout .desktop .desktop__layout.show__form__entry,
.mobile__desktop__layout .desktop .desktop__layout.show__profile__value {
  width: 946px;
  height: 893px;
  margin: 16px auto;
}
.mobile__desktop__layout .desktop.show__form__entry,
.mobile__desktop__layout .desktop.show__profile__value {
  min-width: inherit;
  overflow: inherit;
  margin: 0 auto;
}

.mobile__layout.show__form__entry,
.tablet__layout.show__form__entry,
.mobile__layout.show__profile__value,
.tablet__layout.show__profile__value {
  margin: 16px auto;
}

.ignore__validation {
  .form-input-default.ng-invalid,
  .ng-invalid.form-input-danger,
  .ng-invalid.form-input-info,
  .ng-invalid.form-input-primary,
  .ng-invalid.form-input-success,
  .ng-invalid.form-input-warning {
    border: 1px solid #ccc;
  }
}

// LAYOUT FORM ENTRY
.preview__solution__screen.show__form__entry {
  width: 52.5%;
}
.desktop__layout.show__form__entry,
.mobile__desktop__layout .desktop .desktop__layout.show__form__entry {
  width: 946px;
  height: 893px;
  margin: 16px auto;
}
.mobile__desktop__layout .desktop.show__form__entry {
  min-width: inherit;
  overflow: inherit;
  margin: 0 auto;
}

.mobile__layout.show__form__entry,
.tablet__layout.show__form__entry {
  margin: 16px auto;
}

.ignore__validation {
  .form-input-default.ng-invalid,
  .ng-invalid.form-input-danger,
  .ng-invalid.form-input-info,
  .ng-invalid.form-input-primary,
  .ng-invalid.form-input-success,
  .ng-invalid.form-input-warning {
    border: 1px solid #ccc;
  }
  .address-error-style {
    display: none;
  }
}
.tooltip_box{
    position: absolute;
    top: 115px;
    padding: 16px 16px 16px 16px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px #dfe3e8;
    background-color: #f4f6f8;
    z-index: 1;
    visibility: hidden;
    border-radius: 5px;
    width: 311px;
    height: 121px;
  }
  .ignore_validation-header,
  .show_hidden_question_header,
  .profile_value-header,
  .form_entry-header,
  .form_navigation-header{
    width: 123px;
    height: 20px;
    margin: 0 143px 1px 0;
    font-family: "OpenSans-Regular";
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: rgba(33, 43, 54, 0.8);
    display: flex;
  }
  .show_hidden_question_header{
    display: table-footer-group;
  }
  .Info_icon_img:hover .tooltip_box{
    visibility: visible;
  }
.Info_icon_img{
    display: contents;
}
.profile_value_tooltip_box{
    height: 100px;
}
.Info_icon_img .tooltip_box::after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 55%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #f4f6f8 transparent;
}
.Info_icon_img .show_hidden_question_tooltip_box::after{
    left: 68%;
}
.Info_icon_img .profile_value_tooltip_box::after{
    left: 45%;
}
.Info_icon_img .form_entry_tooltip_box::after{
    left: 40%;
}
