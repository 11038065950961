/**
 * Created by Jamie Nola on 06/26/2018
 */

.green-striped-progress-bar {
  $light-green: #77C277;
  $dark-green: #60B660;
  $light-red: #a94442;
  $dark-red: #9b2926;
  $stripe-size: 20px;
  $movement: $stripe-size * 4 / sqrt(2);

  @keyframes animatedBackground {
    from {
      transform: translateX(-$movement);
    }

    to {
      transform: translateX(0);
    }
  }

  background: white;
  border: 1px solid $dark-green;
  border-radius: 3px;
  width: 100%;
  height: 17px;
  position: relative;
  overflow: hidden;

  .bar {
    position: relative;
    height: 100%;
    overflow: hidden;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 200%;
      min-width: $movement * 2;
      background: repeating-linear-gradient(45deg, $light-green, $light-green $stripe-size, $dark-green $stripe-size, $dark-green ($stripe-size * 2));
    }
  }

  .label-back {
    color: $light-green;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0.9em;
    font-weight: bold;
    width: 100%;
    line-height: 1;
    text-align: center;

    @include firefox-only {
      top: 1px;
    }
  }

  .label-front {
    @extend .label-back;

    color: white;
  }

  &.in-progress {
    .bar {
      border-right: 1px solid $dark-green;

      .bg {
        animation: animatedBackground 0.5s linear infinite;
      }
    }
  }

  &.has-failed {
    border-color: $dark-red;

    .bar .bg {
      background: repeating-linear-gradient(45deg, $light-red, $light-red $stripe-size, $dark-red $stripe-size, $dark-red ($stripe-size * 2));
    }

    .label-back {
      color: $light-red;
    }

    .label-front {
      color: white;
    }
  }
}
