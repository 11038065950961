//be sure to use ./styles/fonts/NAMEOFFONT.ttf to reference fonts (for build processes)

/* Montserrat Family */
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./styles/fonts/Montserrat-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./styles/fonts/Montserrat-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Legacy Fonts (Still used by older forms) */
@font-face {
  font-family: 'AvenirNext-Regular';
  src: url(./styles/fonts/AvenirNext-Regular.ttf);
}

@font-face {
  font-family: 'AvenirNext-Medium';
  src: url(./styles/fonts/AvenirNext-Medium.ttf);
}

@font-face {
  font-family: 'avenirnextdemibold';
  src: url(./styles/fonts/avenirnextdemibold.ttf);
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url(./styles/fonts/OpenSans-Light.ttf);
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url(./styles/fonts/OpenSans-Regular.ttf);
}


@font-face {
  font-family: 'OpenSans-Regular';
  src: url(./styles/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./styles/fonts/NotoSans-Regular.ttf');
}

@font-face {
  font-family: 'Noto Sans Bold';
  src: url('./styles/fonts/NotoSans-Bold.ttf');
}

@font-face {
  font-family: 'Noto Sans Italic';
  src: url('./styles/fonts/NotoSans-Italic.ttf');
}

@font-face {
  font-family: 'Noto Sans BoldItalic';
  src: url('./styles/fonts/NotoSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./styles/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: url('../fonts/Montserrat-Italic.ttf');
}

@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: url('../fonts/Montserrat-BoldItalic.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./styles/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: url('../fonts/Montserrat-MediumItalic.ttf');
}
