/**************************************************
CSS For tree
***************************************************/
.angular-ui-tree {
  margin: 5px;

  // I'm aware of how ugly this is. It's not super efficient, but for now it will get the job done.
  // If you have any questions about this, send me a message, but don't edit it. -Jamie
  & > .angular-ui-tree-nodes > .angular-ui-tree-node > * > .angular-ui-tree-nodes > .angular-ui-tree-node > * > .tree-node {
    & > .angular-ui-tree-nodes:last-child {
      // for some reason, adding 1px of padding makes about 8px of it show up.
      padding-bottom: 1px;
      margin-bottom: 8px;
    }

    & > :not(:first-child) {
      padding-right: 8px;
    }
  }
}

.angular-ui-tree-handle {
  background: #f8faff;
  border-left-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: #9aaeea;
  color: #7c9eb2;
  padding: 3px 2px 3px 5px;
}

.angular-ui-tree-handle-default {
  @extend .angular-ui-tree-handle;

  cursor: default;
}

.angular-ui-tree-handle-nochild {
  border-color: #ea0003;
}

.angular-ui-tree-placeholder {
  background: #17ff1b;
  border: 2px dashed #2b33db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.group-title {
  background-color: #687074 !important;
  color: #FFF !important;
}

/* --- Tree --- */
.tree-node {
  /*border-bottom-width: 1px;*/
  /*border-bottom-style: solid;*/
  /*border-bottom-color: #7e868e;*/
  background: #f8faff;
  color: #2e2e2e;
  margin-bottom: 5px;
  margin-top: 5px;

  .label {
    line-height: normal;
  }
}

.nodrop {
  background-color: #f2dede;
}

.tree-handle {
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #428bca;
  color: #FFF;
}

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 2px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 5px;

  /* in case we need addition padding*/
  &.larger-padding-15 {
    padding-left:15px;
  }

  &.larger-padding-25 {
    padding-left:25px;
  }
}

.tree-node-content {
  margin: 0;

  &.hidden-element-background {
    background: #747265;
  }

  &.section-tree-node {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.tree-node-dialog {
  color: #438eb9;

  button {
    color: #438eb9;
  }
}

.tree-node-content-row {
  /*outline: none;*/
  color: #253647;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;

  /*font-family: 'Playfair Display', serif;*/
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  &.extra-spacing {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.sections-node {
    background: #b4d7e6;
  }

  &.state-rules-node {
    background: #e0dbe6;
  }

  &.highlighted-node {
    background: #b2b9e6;
  }

  &.selected {
    color: blue;
    background-color: #fffbc0;
    font-weight: bold;
  }

  &.hasError {
    border: solid red medium;
  }

  &.disabled {
    background: #8f8f8f;
    color: black;
    font-style: italic;
  }

  > div {
    display: flex;

    &:first-child {
      align-items: center;
      min-width: 0;

      > * {
        margin-right: 3px;
      }
    }

    &:not(:first-child):not(.flex-align-center) {
      align-items: start;
      padding-left: 5px;
    }
  }

  .show-only-on-node-hover {
    display: none;
  }

  .tree-node-table {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &:hover, &.force-hover {
    color: #438eb9;
    background: #b3eecd;
    border-color: #dce2e8;

    .show-only-on-node-hover {
      display: inline-block;
    }
  }

  &.is-input-field {
    background: #b6c8c7;
  }
}
