/* *************************************************
form sections
************************************************** */
.form-config-container {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 60px);
  width: 100%;
  margin-top: 60px;
  overflow: auto;

  .page-title {
    font-size: 20pt;
    font-weight: bold;
    height: 40px;
  }

  .main-content {
    position: relative;
    height: calc(100% - 60px);
    overflow: hidden;

    .left-panel {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 5px;
      width: 500px;
      height: calc(100% - 5px);

      /* with large left panel, give 700px to the left side */
      &.large-left-panel {
        width: 700px;
      }

      .panel-title {
        padding: 5px;
      }

      .panel-content {
        width: 100%;
        height: calc(100% - 30px);
        padding-bottom: 23px;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px solid #c0c0c0;
        box-shadow: 5px 5px 4px -1px rgba(0, 0, 0, 0.5);
      }
    }

    .center-panel {
      position: absolute;
      top: 0;
      left: 510px;
      margin-top: 5px;
      width: 500px;
      height: calc(100% - 5px);

      .panel-title {
        padding: 5px;
      }

      .panel-content {
        width: 100%;
        height: calc(100% - 30px);
        background: #d6d6d6;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px solid #c0c0c0;
        box-shadow: 5px 5px 4px -1px rgba(0, 0, 0, 0.5);
      }
    }

    .right-panel {
      border: 2px;
      position: absolute;
      top: 0;
      margin-top: 5px;
      margin-bottom: 10px;
      margin-left: 10px;

      /*padding-bottom:60px;*/

      height: calc(100% - 10px);
      overflow-y: auto;
      overflow-x: hidden;
      left: 510px;
      width: calc(100% - 520px);

      /* with large left panel, give 700px to the left side, ps. this configuration doesn't support the center panel */
      &.large-left-panel {
        left: 710px;
        width: calc(100% - 720px);
      }

      &.include-center-panel {
        left: 1030px;
        width: calc(100% - 1040px);
      }
    }
  }
}

.form-config-label {
  font-size: 12pt;
  font-weight: bold;
}

.form-error-message {
  font-size: 9pt;
  color: red;
}

.form-row-buffer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-color-display {
  width: 30px;
  height: 30px;
  border: 1px solid black;
}

.form-tab-title {
  font-size: 16pt;
  font-weight: bold;
}

.form-config-box {
  -webkit-box-shadow: 2px 2px 6px 0px rgba(23, 20, 23, 1);
  -moz-box-shadow: 2px 2px 6px 0px rgba(23, 20, 23, 1);
  box-shadow: 2px 2px 6px 0px rgba(23, 20, 23, 1);
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  margin: 4px;
  padding: 4px 8px;
}

.support-chaining-checkbox {
    padding-left: 0 !important;
}

.form-config-crossed-out {
  text-decoration: line-through;
  opacity: 0.4;
  cursor: not-allowed;
}

.custom-field-width {
  width: 88.5%;
  display: inline-block;
  @media only screen and (max-width: 720px) {
    width: 90%;
  }
}

/* ***************************************
vertical nav bar using ul
*************************************** */
ul.vertical-nav-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  border: 1px solid #555;
}

ul.vertical-nav-bar>li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}

ul.vertical-nav-bar>li {
  /*text-align: center;*/
  border-bottom: 1px solid #555;
}

ul.vertical-nav-bar>li:last-child {
  border-bottom: none;
}

ul.vertical-nav-bar>li a.active {
  background-color: #4CAF50;
  color: white;
}

ul.vertical-nav-bar>li a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.tree-node-id-display {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  border-radius: 3px;
  border: 1px solid #73AD21;
}

/* ************************************
some classes over-written used in preview
************************************* */

hr.hr-separator {
  display: block;
  margin: 0.5em auto;
  border-top: 1px inset #454545;
}

div.horizontal-divider {
  border-left: 1px solid #454545;
  margin-left: 10px;
  margin-right: 10px;
}

table.table-inner-border {
  border-collapse: collapse;
  width: 100%;
}

table.table-inner-border td:not(:first-child),
table.table-inner-border th:not(:first-child) {
  border-left: 1px solid #454545;
}

table.table-inner-border tr:not(:first-child) {
  border-top: 1px solid #454545;
}

table.table-inner-border td,
table.table-inner-border th {
  padding: 10px;
}

/* ************************************
some classes used in configuration
************************************* */
.form-config-row-title {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid cadetblue;
}

.form-config-row-config {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  font-size: 14px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #788087;
}

.form-config-text-larger {
  font-size: 18px;
}

.form-title-label {
  margin-top: 10px;
}

.preview-body {
  border: 5px #83caca solid;
}

.preview-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(92, 92, 92, 0.3);
  background-color: #F5F5F5;
}

.preview-body::-webkit-scrollbar {
  width: 8px;
  background-color: #929292;
  height: 3px;
}

.preview-body::-webkit-scrollbar-thumb {
  background-color: #99bea7;
  border: 1px solid #e9e9e9;
}

.always-show-vertical-scrollbar {
  overflow-y: scroll;
}

.preview-body-container {
  width: 100%;
  padding: 0;
  margin: auto;

  *:focus {
    outline: 0;
  }
}

.form-config-row-ruleType {
  margin-bottom: 10px;
}

/* Pravin: Adding css classes to match the preview to WebApp form rendering*/
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

table.table-inner-border {
  border-collapse: collapse;
  width: 100%;
}

table.table-inner-border td:not(:first-child),
table.table-inner-border th:not(:first-child) {
  border-left: 1px solid #454545;
}

table.table-inner-border tr:not(:first-child) {
  border-top: 1px solid #454545;
}

table.table-inner-border td,
table.table-inner-border th {
  padding: 10px;
}

.form-title-text {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

hr.hr-separator {
  display: block;
  margin: 0.5em auto;
  border-top: 1px inset #454545;
}

div.horizontal-divider {
  border-left: 1px solid #454545;
  margin-left: 10px;
  margin-right: 10px;
}

.modal-md {
  width: 470px;
}

form-modal-body .modal-title-align-left {
  margin: 0 40%;
  height: 22px;
}

form-modal-body .modal-title-align-left a {
  float: right;
}

.error-message-style {
  color: red;
}

.signature-element {
  display: inline-block;
  position: relative;
}

.signature-element .signature-controls {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.signature-element button:not(:first-of-type) {
  margin-left: 10px;
}

.signature-element .canvases {
  position: relative;
  display: inline-block;
}

.signature-element .main-canvas {
  cursor: none;
}

.signature-element canvas:not(.main-canvas) {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1300px) {
  .modal>.modal-lg {
    width: 1208px;
  }
}

/* Modal flip animation */
.flip-card-enter {
  transition: 0.250s ease-in transform;
  transform: rotateY(90deg);
}

.flip-card-enter-active {
  transition: 0s transform;
  transform: rotateY(-90deg);
}

.flip-card-enter-end {
  transition: 0.250s ease-out transform;
  transform: rotateY(0deg);
}

.flip-card-leave {
  transition: 0.250s ease-in transform;
  transform: rotateY(-90deg);
}

.flip-card-leave-active {
  transition: 0s transform;
  transform: rotateY(90deg);
}

.flip-card-leave-end {
  transition: 0.250s ease-out transform;
  transform: rotateY(0deg);
}

.admin-preview {
  margin-left: 10px;
  margin-right: 10px;

  // allow some inline blocks to work similar to table cells
  .admin-inline-block {
    display: inline-block;
  }

  .preview-body {
    overflow: hidden;
  }
}

//to give the button view in the form rendering in admin portal
.vbr-btn {
  @extend .btn,
  .btn-primary;
}

.vbr-btn-clear {
  @extend .btn,
  .btn-outline-primary;
}

// -------------------------- form comparison css --------------------
.title-common {
  padding: 2px;
  color: #3a51bf;
  background-color: #e9e9e9;
  font-weight: bold;
  border-bottom: 2px blue solid;
}

.title-level-1 {
  @extend .title-common;

  font-size: 24pt;
  padding-left: 5px;
}

.title-level-2 {
  @extend .title-common;

  color: #10a78c;
  font-size: 21pt;
  padding-left: 15px;
}

.title-level-3 {
  @extend .title-common;

  color: #24a0a7;
  font-size: 18pt;
  padding-left: 25px;
}

.title-level-4 {
  @extend .title-common;

  color: #3385a7;
  font-size: 15pt;
  padding-left: 35px;
}

.title-level-5 {
  @extend .title-common;

  color: #607ea7;
  font-size: 12pt;
  padding-left: 45px;
}

.title-level-6 {
  @extend .title-common;

  color: #a79c0f;
  font-size: 12pt;
  padding-left: 55px;
}

.title-level-7 {
  @extend .title-common;

  color: #a79c0f;
  font-size: 12pt;
  padding-left: 65px;
}

.hoverDiv {
  background: #fff;

  &:hover {
    background: #f5f5f5;
  }

  &.row-added {
    background: #caf3dc;

    &:hover {
      background: #add1bc;
    }
  }

  &.row-removed {
    background: #f3d4cc;

    &:hover {
      background: #cc98b2;
    }
  }

  &.row-modified {
    background: #d5f2f5;

    &:hover {
      background: #acc5c8;
    }
  }
}

.blue-bold-large-text {
  font-size: 24px;
  color: blue;
  font-weight: bold;
}

.copy-or-cut-element {
  border: dotted blue;
}

.image20 {
  width: 20px;
  height: 20px;
}

.form-menu {
  li {
    margin: 5px 10px;
    border: #333333;

    button {
      width: 30px !important;
      margin-right: 5px;
    }
  }
}

/* ************************************
ck editor overwrites
************************************* */
.cke_editable {
  font-family: 'Noto Sans', sans-serif !important;
  font-size: 14pt !important;
  line-height: 1.28 !important;
}
