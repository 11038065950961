﻿@import "common";

$active-color: #4caf50;
$one-hundred-percent: 100%;
$base-border: solid 1px #dfe3e8;
$active-border: solid 1px $active-color;

@mixin collapseHeader {
  width: 870px;
  height: 40px;
  padding: 10px 16px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: $grey-medium-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

@mixin collapseContent {
  width: 870px;
  height: auto;
  padding: 12px 18px;
  border: solid 1px $base-grey-light-color;
  margin-bottom: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  word-wrap: break-word;
}

@mixin collapseToggle {
  width: 14px;
  height: 16px;
}

@mixin tableHeader {
  width: 844px;
  height: 49px;
  border-radius: 6px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.23);
  background-color: $base-background-color;

  font-family: $font-family;
  font-size: $base-font-size;
  font-weight: 600;
  letter-spacing: 1.02px;
  color: $base-mirage-color;

  tr th {
    padding: 0 16px;
  }
  tr th:nth-child(1) {
    width: 120px;
    border-right: $base-border;
    border-top-left-radius: 4px;
    min-width: 98px;
  }
  tr th:nth-child(2) {
    border-top: 0;
    max-width: 330px;
    min-width: 180px;
    border-right: $base-border;
  }
  tr th:nth-child(3) {
    width: 180px;
    border-top: 0;
    border-right: $base-border;
  }
  tr th:nth-child(4) {
    width: 180px;
    border-top: 0;
    border-right: 0;
    border-top-right-radius: 4px;
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    tr th:nth-child(2) {
      max-width: 180px;
    }
  }
  @media (min-width: 1600px) {
    tr th:nth-child(2) {
      min-width: 330px;
    }
  }
}

@mixin tableBody {
  tr td {
    padding: 0 16px;
  }
  tr td:nth-child(1) {
    width: 120px;
    border-left: $base-border;
    word-wrap: break-word;
    min-width: 98px;
  }
  tr td:nth-child(2) {
    border-left: $base-border;
    max-width: 330px;
    min-width: 180px;
    word-wrap: break-word;
  }
  tr td:nth-child(3) {
    width: 180px;
    border-left: $base-border;
    word-wrap: break-word;
  }
  tr td:nth-child(4) {
    width: 180px;
    border-left: $base-border;
    word-wrap: break-word;
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    tr td:nth-child(2) {
      max-width: 180px;
    }
  }
  @media (min-width: 1600px) {
    tr td:nth-child(2) {
      min-width: 330px;
    }
  }
}

@mixin tableRow {
  width: 844px;
  height: 49px;
  background-color: $base-white-color;
  border: $base-border;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 500;
}

@mixin tableHighlight {
  background-color: $athens-grey-color;
}

.form__navigation__container {
  .form__navigation_detail {
    font-family: $font-family;
    font-size: $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    .nodes__visited__title,
    .pages__visited__title,
    .possible__navigation__title,
    .other__pages__title {
      font-weight: 600;
      color: $checkbox-color;
    }
    .pages__visited__note {
      font-weight: 600;
      color: $dark-color;
    }
    .nodes__visited__content {
      color: $dark-color;
      .last__node__visited {
        font-weight: 600;
        color: $base-green-color;
      }
      .nodes__visited__comma {
        margin-left: -3px;
      }
    }

    .other__pages__header,
    .possible__navigation__header,
    .nodes__visited__header,
    .pages__visited__header {
      @include collapseHeader;
    }

    .other__pages__content,
    .possible__navigation__content,
    .nodes__visited__content,
    .pages__visited__content {
      @include collapseContent;
    }

    .other__pages__toggle,
    .possible__navigation__toggle,
    .nodes__visited__toggle,
    .pages__visited__toggle {
      @include collapseToggle;
    }

    .pages__visited__up__arrow {
      width: 10px;
      height: 16px;
    }

    .other__pages__table,
    .possible__navigation__table,
    .pages__visited__table {
      width: $one-hundred-percent;
    }

    .other__pages__thead,
    .possible__navigation__thead,
    .pages__visited__thead {
      @include tableHeader;
    }

    .other__pages__tbody,
    .possible__navigation__tbody,
    .pages__visited__tbody {
      @include tableBody;
      .pages__visited__advanced__debugging {
        font-family: $font-family;
        font-size: $base-font-size;
        font-weight: 500;
        color: $dark-color;
        text-decoration: underline;
        cursor: pointer;
        display: inherit;
        position: relative;
      }
    }

    .other__pages__tr,
    .possible__navigation__tr,
    .pages__visited__tr {
      @include tableRow;
    }

    .other__pages__tr.highlight,
    .possible__navigation__tr.highlight,
    .pages__visited__tr.highlight {
      @include tableHighlight;
    }

    .other__pages__tr.disabled__node,
    .possible__navigation__tr.disabled__node,
    .pages__visited__tr.disabled__node {
      cursor: not-allowed;
      color: $boulder-color;
    }

    .pages__visited__tbody .pages__visited__tr.active {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.23px;
      color: $active-color;

      td:nth-child(1) {
        border-top: $active-border;
        border-left: $active-border;
        border-bottom: $active-border;
      }
      td:nth-child(2) {
        border-top: $active-border;
        border-bottom: $active-border;
      }
      td:nth-child(3) {
        border-top: $active-border;
        border-bottom: $active-border;
      }
      td:nth-child(4) {
        border-top: $active-border;
        border-right: $active-border;
        border-bottom: $active-border;
      }
    }
  }
  .margin__section {
    margin-bottom: 10px;
  }
}
.pages__visited__advanced__debugging:hover .tooltip {
    @include baseTooltip;
    font-size: 12px;
    top: -50px;
    left: 108%;
    background-color: white;
    height: 290px;
    width: 270px;
    word-spacing: 3px;
    line-height: 20px;
    position: absolute;
  }

div .scrollbar{
     overflow-y: scroll;
     height: 280px;
  }

.pages__visited__advanced__debugging:hover .tooltip::after {
    @include tooltipArrow;
    left: -10px;
    top: 15%;
    width: 18px;
    height: 18px;
    background-color: white;
    border-left: 1px solid #d9e0e8;
    border-bottom: 1px solid #d9e0e8;
    border-right: transparent;
    overflow-y: auto;
}
li{
    list-style: none;
}
.key{
    color: blue;
}
