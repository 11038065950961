	/*
  	Flaticon icon font: Flaticon
  	Creation date: 23/02/2018 23:32
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-collapse-button:before { content: "\f100"; }
.flaticon-expand-button:before { content: "\f101"; }
.flaticon-text-editor:before { content: "\f102"; }
.flaticon-transport:before { content: "\f103"; }
.flaticon-mountain:before { content: "\f104"; }
.flaticon-tracks:before { content: "\f105"; }
.flaticon-road-and-tracks:before { content: "\f106"; }
.flaticon-settings:before { content: "\f107"; }
.flaticon-controls:before { content: "\f108"; }
.flaticon-push-notification-1:before { content: "\f109"; }
.flaticon-view-selection:before { content: "\f10a"; }
.flaticon-personal-form:before { content: "\f10b"; }
.flaticon-pause:before { content: "\f10c"; }
.flaticon-exit:before { content: "\f10d"; }
.flaticon-user-info1:before { content: "\f10e"; }
.flaticon-internet:before { content: "\f10f"; }
.flaticon-reset-field-entry-value:before { content: "\f110"; }
.flaticon-pdf1:before { content: "\f111"; }
.flaticon-set-current-page:before { content: "\f112"; }
.flaticon-email:before { content: "\f113"; }
.flaticon-page-link:before { content: "\f114"; }
.flaticon-bucket:before { content: "\f115"; }
.flaticon-avatar:before { content: "\f116"; }
.flaticon-link:before { content: "\f117"; }
.flaticon-weight:before { content: "\f118"; }
.flaticon-window:before { content: "\f119"; }
.flaticon-arrows-down:before { content: "\f11a"; }
.flaticon-pen:before { content: "\f11b"; }
.flaticon-line:before { content: "\f11c"; }
.flaticon-justify-center:before { content: "\f11d"; }
.flaticon-photo:before { content: "\f11e"; }
.flaticon-justify-right:before { content: "\f11f"; }
.flaticon-justiiy-left:before { content: "\f120"; }
.flaticon-take-picture:before { content: "\f121"; }
.flaticon-notification:before { content: "\f122"; }
.flaticon-slider:before { content: "\f123"; }
.flaticon-workflow:before { content: "\f124"; }
.flaticon-padding:before { content: "\f125"; }
.flaticon-padding-right:before { content: "\f126"; }
.flaticon-padding-left:before { content: "\f127"; }
.flaticon-padding-bottom:before { content: "\f128"; }
.flaticon-padding-top:before { content: "\f129"; }
.flaticon-margin-top:before { content: "\f12a"; }
.flaticon-margin-bottom:before { content: "\f12b"; }
.flaticon-margin-left:before { content: "\f12c"; }
.flaticon-margin:before { content: "\f12d"; }
.flaticon-view-input:before { content: "\f12e"; }
.flaticon-function:before { content: "\f12f"; }
.flaticon-check-boxes:before { content: "\f130"; }
.flaticon-circle:before { content: "\f131"; }
.flaticon-color-picker:before { content: "\f132"; }
.flaticon-line-dashed:before { content: "\f133"; }
.flaticon-click:before { content: "\f134"; }
.flaticon-clicker:before { content: "\f135"; }
.flaticon-label-reference:before { content: "\f136"; }
.flaticon-boolean-selector:before { content: "\f137"; }
.flaticon-text-check:before { content: "\f138"; }
.flaticon-target:before { content: "\f139"; }
.flaticon-change-setting:before { content: "\f13a"; }
.flaticon-text-label:before { content: "\f13b"; }
.flaticon-section-normal:before { content: "\f13c"; }
.flaticon-file-submit:before { content: "\f13d"; }
.flaticon-condensed:before { content: "\f13e"; }
.flaticon-form:before { content: "\f13f"; }
.flaticon-party:before { content: "\f140"; }
.flaticon-state:before { content: "\f141"; }
.flaticon-come-in:before { content: "\f142"; }
.flaticon-font-large:before { content: "\f143"; }
.flaticon-gear:before { content: "\f144"; }
.flaticon-video-click:before { content: "\f145"; }
.flaticon-title:before { content: "\f146"; }
.flaticon-days:before { content: "\f147"; }
.flaticon-color-palate:before { content: "\f148"; }
.flaticon-number-fun:before { content: "\f149"; }
.flaticon-handshake:before { content: "\f14a"; }
.flaticon-youtube:before { content: "\f14b"; }
.flaticon-preview:before { content: "\f14c"; }
.flaticon-eye-crossed:before { content: "\f14d"; }
.flaticon-form-black:before { content: "\f14e"; }
.flaticon-check-narrow:before { content: "\f14f"; }
.flaticon-expand:before { content: "\f150"; }
.flaticon-refresh:before { content: "\f151"; }
.flaticon-round-remove-button:before { content: "\f152"; }
.flaticon-radio-button:before { content: "\f153"; }
.flaticon-rectangle:before { content: "\f154"; }
.flaticon-state-rule:before { content: "\f155"; }
.flaticon-eye:before { content: "\f156"; }
.flaticon-sections:before { content: "\f157"; }
.flaticon-clock:before { content: "\f158"; }
.flaticon-number-circle:before { content: "\f159"; }
.flaticon-symbol:before { content: "\f15a"; }
.flaticon-actions:before { content: "\f15b"; }
.flaticon-person-info:before { content: "\f15c"; }
.flaticon-pencil:before { content: "\f15d"; }
.flaticon-wheel:before { content: "\f15e"; }
.flaticon-width:before { content: "\f15f"; }
.flaticon-height:before { content: "\f160"; }
.flaticon-trigger:before { content: "\f161"; }
.flaticon-check-black:before { content: "\f162"; }
.flaticon-custom:before { content: "\f163"; }
.flaticon-function-mathematical-symbol:before { content: "\f164"; }
.flaticon-form-read:before { content: "\f165"; }
.flaticon-check-mark:before { content: "\f166"; }
.flaticon-horizontal:before { content: "\f167"; }
.flaticon-vertical:before { content: "\f168"; }
.flaticon-editing:before { content: "\f169"; }
.flaticon-slider-small:before { content: "\f16a"; }
.flaticon-id-card:before { content: "\f16b"; }
.flaticon-radio-buttons:before { content: "\f16c"; }
.flaticon-dropdown:before { content: "\f16d"; }
.flaticon-font-type:before { content: "\f16e"; }
.flaticon-copy-document:before { content: "\f16f"; }
.flaticon-save:before { content: "\f170"; }
.flaticon-text-small:before { content: "\f171"; }
.flaticon-square:before { content: "\f172"; }
.flaticon-spinner-of-dots:before { content: "\f173"; }
.flaticon-font:before { content: "\f174"; }
.flaticon-day:before { content: "\f175"; }
.flaticon-user-info:before { content: "\f176"; }
.flaticon-line-height-1:before { content: "\f177"; }
.flaticon-push-notification:before { content: "\f178"; }
.flaticon-profile-image:before { content: "\f179"; }
.flaticon-trigger1:before { content: "\f17a"; }
.flaticon-profile-program:before { content: "\f17b"; }
.flaticon-phone:before { content: "\f17c"; }
.flaticon-divider:before { content: "\f17d"; }
.flaticon-font-size:before { content: "\f17e"; }
.flaticon-line-height:before { content: "\f17f"; }
.flaticon-save-black:before { content: "\f180"; }
.flaticon-section-page:before { content: "\f181"; }
.flaticon-input-text:before { content: "\f182"; }
.flaticon-refresh-page-arrow-shape:before { content: "\f183"; }
.flaticon-identical:before { content: "\f184"; }
.flaticon-usb-input:before { content: "\f185"; }
.flaticon-variable-symbol-in-window:before { content: "\f186"; }
.flaticon-signature-with-a-pen:before { content: "\f187"; }
.flaticon-disabled:before { content: "\f188"; }
.flaticon-section:before { content: "\f189"; }
.flaticon-arrows:before { content: "\f18a"; }
.flaticon-margin-right:before { content: "\f18b"; }
.flaticon-view-slider:before { content: "\f18c"; }