.deep-link-page {
  .action-button {
    visibility: hidden;
  }

  .deep-link-table-row:hover {
    .action-button {
      visibility: visible;
    }
  }
}
