/**
 * Created by Jamie Nola on 04/10/2018
 */

.tile-dashboard {
  $space-between-tiles: 20px;

  .tile-container {
    margin: 0 (-$space-between-tiles) 0 0;
  }

  .tile {
    display: inline-block;
    float: none;
    max-width: 420px;
    vertical-align: top;
    padding: 0 $space-between-tiles 0 0;
    margin: 0 0 $space-between-tiles 0;
  }

  .panel {
    margin-bottom: 0;
  }

  .panel-heading {
    min-height: 121px;
  }

  .tile-info-container {
    display: table;
    width: 100%;
  }

  .tile-icon {
    display: table-cell;
    text-align: center;
    width: 1px;
    min-width: 50px;
  }

  .tile-info {
    display: table-cell;
    padding-left: 5px;
    vertical-align: top;

    .count {
      margin-bottom: -7px;
    }

    h4 {
      word-break: break-word;
      line-height: 1.4em;
      margin-bottom: 0;
    }
  }
}
