$white-color: #fff;
$border-color: #ebebeb;
$form-title-color: #667081;
$checkbox-color: #003e65;
$primary-button-color: #269cd2;
$db-color: #dbdbdb;
$shadow-color-small: rgba(0, 0, 0, 0.16);
$shadow-color-medium: rgba(0, 0, 0, 0.23);

@mixin fontBase {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin checkboxCustom {
  input[type="checkbox"].np_new_profile_value {
    cursor: pointer;
    margin: 0 4px 2px $base-distance;
    height: 18px;
    width: 18px;
    vertical-align: middle;
    border-radius: 2px;
    border: 2px solid $layout-border-color;
    outline: none;
    -webkit-appearance: none;
    -webkit-transition: box-shadow 200ms;
  }

  input[type="checkbox"].np_new_profile_value:checked {
    border-color: $checkbox-color;
    background-color: $checkbox-color;
  }

  input[type="checkbox"].np_new_profile_value:checked ~ label {
    color: $primary-color;
  }

  input[type="checkbox"].np_new_profile_value:checked::after {
    content: "";
    display: block;
    width: 7px;
    height: 12px;
    border: solid $white-color;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    margin-left: 3.8px;
    margin-top: -1px;
    -webkit-transform: rotate(45deg);
  }
}

@mixin header {
  @include fontBase;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.95px;
  width: 100%;
  height: 49px;
  color: #131523;
  background-color: #ebf5fa;
  box-shadow: 0 2px 3px 0 $shadow-color-medium;
}

@mixin body {
  @include heightContent;
  border: solid 1px #dfe3e8;
  background-color: #fafafb;
}
@mixin scrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #dfe3e8;
    border-radius: 2.5px;
    margin-left: 48px;
    margin-right: 214px;
    padding: 16px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background-color: #919eab;
    background-clip: content-box;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #919eab;
  }
}

@mixin heightContent {
  height: 64px;
}

@mixin firstColumn {
  @include checkboxCustom;
  min-width: 48px;
}

@mixin secondColumn {
  min-width: 374px;
}

@mixin thirdColumn {
  min-width: 72px;
}

@mixin fourColumn {
  min-width: 178px;
}

@mixin lastColumn {
  min-width: 205px;
}

@mixin stickyColumn {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

@mixin titleText {
  @include fontBase;
  font-size: 16px;
  color: $checkbox-color;
  margin-right: 16px;
}

@mixin warningMessage {
  @include flexCenter;
  width: 311.9px;
  height: 35px;
  border: solid 1px #f1c21b;
  background-color: #fef9e7;
  img {
    width: 18px;
    height: 18px;
  }
  span {
    @include fontBase;
    line-height: 1.43;
    color: #212b36;
    margin-left: 6px;
  }
}

.profile__value__preview {
  position: fixed;
  top: 64px;
  right: 0;
  height: calc(100vh - 64px);
  width: 48%;
  box-shadow: 0 3px 6px 0 $shadow-color-small;
  border: solid 1px #e4e4e4;
  background-color: $white-color;
  z-index: 0;

  /* CONTAINER */
  .profile__value__container {
    margin: 16px 16px 0;
    @include scrollbar;

    /* HEADER */
    .profile__value__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      .profile__value__header__title {
        @include fontBase;
        font-size: 22px;
        line-height: normal;
        color: $form-title-color;
      }

      .profile__value__header__subtitle {
        @include fontBase;
        line-height: normal;
        color: $form-title-color;
      }

      .profile__value__header__close__profile__value {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }

    /* CONTENT PROFILE VALUES */
    .profile__values__list {
      height: calc(100vh - 165px);
      overflow-y: auto;
      padding-right: 4px;
      overflow-x: hidden;
      .profile__values__list__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .profile__values__list__title__left {
          display: flex;
          .profile__values__list__title__text {
            @include titleText;
          }
          .profile__values__list__title__remove__all {
            @include fontBase;
            position: relative;
            font-size: 12px;
            font-weight: bold;
            line-height: 1.5;
            color: #fff;

            width: 99px;
            height: 25px;
            padding: 4px 8px;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;
            background-color: #b00020;

            display: flex;
            justify-content: space-between;

            span.icon {
              transform: scale(1.5, 0.8);
              font-weight: 500;
            }

            label {
              cursor: pointer;
            }

            input[type="checkbox"]#profile__values__list__title__remove__all__check__box {
              display: none;
            }

            input[type="checkbox"]#profile__values__list__title__remove__all__check__box:checked
              ~ .pop-up-delete {
              display: block;
            }

            .pop-up-delete {
              display: none;
              position: absolute;
              width: 283px;
              height: 90px;
              border: solid 1px #f1c21b;
              background-color: #fef9e7;
              padding: 8px 10px;
              top: 35px;
              right: 0;
              border-radius: 4px;
              cursor: default;
              z-index: 2;

              .pop-up-delete-title {
                display: flex;
                justify-content: space-between;
                .icon-warning img {
                  width: 21px;
                  height: 18px;
                }
                .text {
                  @include fontBase;
                  line-height: 1.43;
                  color: #212b36;
                  padding: 0 8px;
                }
                .icon-close img {
                  width: 13px;
                  height: 16px;
                  cursor: pointer;
                }
              }
              .pop-up-delete-action {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .cancel {
                  @include fontBase;
                  line-height: 1.43;
                  color: #212b36;
                  margin-right: 10px;
                  cursor: pointer;
                }
                .yes {
                  width: 68px;
                  height: 28px;
                  border-radius: 4px;
                  border: solid 2px #b00720;
                  background-color: #b00020;
                  cursor: pointer;

                  label {
                    width: 68px;
                    height: 23px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }

            .pop-up-delete::before {
              content: "";
              position: absolute;
              width: 15px;
              height: 15px;
              right: 15px;
              top: 0;
              border: 1px solid;
              border-color: transparent transparent #f1c21b #f1c21b;
              transform-origin: 0 0;
              transform: rotate(135deg);
              background: #fef9e7;
            }
          }
        }

        .profile__values__list__title__right {
          .profile__values__list__message__clear__all {
            width: 311.9px;
            height: 35px;
            padding: 6px 16px;
            border: solid 1px #4caf50;
            background-color: #e4f3e5;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .profile__values__list__message__clear__all > div:nth-child(1) {
            display: flex;
            align-items: center;
            img {
              width: 18px;
              height: 18px;
              margin-right: 6px;
            }
            span {
              @include fontBase;
              color: #212b36;
            }
          }
          .profile__values__list__message__clear__all > div:nth-child(2) {
            img {
              width: 13px;
              height: 16px;
            }
          }
        }
      }
      .profile__values__list__content {
        max-height: 312px;
        overflow: auto;

        table.profile__values__list__table {
          border-collapse: separate;

          thead {
            position: relative;
            z-index: 1;

            tr.profile__values__table__header {
              @include header;

              th:nth-child(1) {
                @include firstColumn;
                @include stickyColumn;
                background-color: #ebf5fa;
                left: 0;
              }
              th:nth-child(2) {
                @include secondColumn;
                padding-left: 16px;
              }
              th:nth-child(3) {
                @include thirdColumn;
              }
              th:nth-child(4) {
                @include fourColumn;
              }
              th:nth-child(5) {
                @include lastColumn;
                @include stickyColumn;
                background-color: #ebf5fa;
                right: 0;
                padding-left: 24px;
              }
            }
          }

          tbody {
            position: relative;
            z-index: 0;

            tr.profile__values__table__body {
              @include body;

              td:nth-child(1) {
                @include firstColumn;
                @include heightContent;
                @include stickyColumn;
                input[type="checkbox"].np_new_profile_value {
                  cursor: default;
                }
                left: 0;
                background-color: #f4f6f8;
                box-shadow: 0 0 4px 0 $shadow-color-small;
                border-left: solid 1px #dfe3e8;
                border-bottom: solid 1px #dfe3e8;
              }
              td:nth-child(2) {
                @include secondColumn;
                @include heightContent;
                @include flexCenter;
                border-bottom: solid 1px #dfe3e8;

                .np_new_profile_value_id_input_container {
                  position: relative;
                  z-index: 3;
                  input:nth-child(1) {
                    background-color: transparent !important;
                  }
                  input.np_new_profile_value_id_input:disabled {
                    background-color: #f4f6f8 !important;
                  }
                  input.np_new_profile_value_id_input {
                    @include fontBase;
                    width: 390px;
                    height: 48px;
                    border-radius: 4px;
                    border: solid 1px #dededf;
                    background-color: $white-color !important;
                    padding: 16px;
                    outline: none;
                  }
                  input.np_new_profile_value_id_input.focus {
                    border: solid 1px #0098db;
                  }
                  input.np_new_profile_value_id_input.disable {
                    opacity: 0.5;
                    cursor: not-allowed;
                  }
                  .np_new_profile_value_icon {
                    position: absolute;
                    top: 21.8px;
                    right: 18.7px;
                    width: 8.3px;
                    border-right: 4.1px solid transparent;
                    border-left: 4.1px solid transparent;
                    border-top: 4.1px dashed;
                    color: #4d4d4d;
                  }
                  input.np_new_profile_value_id_input.disable
                    ~ .np_new_profile_value_icon {
                    opacity: 0.5;
                  }
                  ul.dropdown-menu {
                    @include fontBase;
                    width: 390px;
                  }
                }
              }
              td:nth-child(3) {
                @include thirdColumn;
                @include heightContent;
                border-bottom: solid 1px #dfe3e8;

                .np_new_profile_value_type {
                  @include fontBase;
                  color: #212b36;
                  font-weight: 600;
                  line-height: 1.29;
                }
                .np_new_profile_value_type.disable {
                  opacity: 0.5;
                  cursor: not-allowed;
                }
              }
              td:nth-child(4) {
                @include fourColumn;
                @include heightContent;
                border-bottom: solid 1px #dfe3e8;

                .select.np_new_profile_value_value_input:disabled,
                .np_new_profile_value_value_input:disabled {
                  background-color: #f4f6f8 !important;
                }

                .np_new_profile_value_value_input {
                  @include fontBase;
                  width: 172px;
                  height: 48px;
                  border-radius: 4px;
                  border: solid 1px $border-color;
                  background-color: $white-color;
                  padding: 16px;
                  outline: none;
                }
                select.np_new_profile_value_value_input {
                  padding: 8px 16px;
                }

                .np_new_profile_value_value_input.focus,
                select.np_new_profile_value_value_input.focus {
                  border: solid 1px #0098db;
                }

                .np_new_profile_value_value_input.disable,
                .select.np_new_profile_value_value_input.disable {
                  opacity: 0.5;
                  cursor: not-allowed;
                }
              }
              td:nth-child(5) {
                @include lastColumn;
                @include heightContent;
                @include stickyColumn;
                border-bottom: solid 1px #dfe3e8;
                border-right: solid 1px #dfe3e8;
                right: 0;
                background-color: #ebf5fa;
                box-shadow: 0 0 4px 0 $shadow-color-small;

                .profile__values__table__content__action__action {
                  display: flex;
                  align-items: center;
                  padding-left: 24px;
                  .profile__values__table__content__action__toggle {
                    width: 133px;
                    height: 36px;
                    display: flex;
                    .save-button {
                      display: block;
                    }
                    .saved-button {
                      display: none;
                      justify-content: space-between;
                      margin-left: -13px;
                      align-items: center;
                      img {
                        width: 12px;
                        height: 13px;
                        margin-right: 2px;
                      }
                    }
                    input {
                      display: none;
                    }
                    input:checked ~ .saved-button {
                      display: flex;
                    }
                    input:checked ~ .save-button {
                      display: none;
                    }
                    .profile__values__table__content__action__edit {
                      width: 69px;
                      height: 36px;
                      padding: 8px 17px;
                      border-radius: 0;
                      border-top-left-radius: 4px;
                      border-bottom-left-radius: 4px;
                      border: solid 1px #269cd2;
                      background-color: #269cd2;
                      @include fontBase;
                      font-weight: 600;
                      color: $white-color;
                    }
                    .profile__values__table__content__action__save {
                      width: 65px;
                      height: 36px;
                      padding: 8px 20px;
                      border: solid 1px #269cd2;
                      background-color: $white-color;
                      border-radius: 0;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      @include fontBase;
                      font-weight: 600;
                      color: #269cd2;
                    }

                    .profile__values__table__content__action__edit.disable {
                      border: solid 1px #dbdbdb;
                      background-color: #dbdbdb;
                      cursor: default;
                    }
                    .profile__values__table__content__action__save.disable {
                      color: #dbdbdb;
                      border: solid 1px #dbdbdb;
                      background-color: $white-color;
                      cursor: default;
                    }
                  }
                  .profile__values__table__content__action__remove {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 25px;
                    height: 36px;
                    border: solid 1px $border-color;
                    margin-left: 4px;
                    position: relative;
                    .profile__values__table__content__action__confirm {
                      position: absolute;
                      top: 44px;
                      left: -232px;
                      display: none;
                      width: 283px;
                      height: 90px;
                      border-radius: 4px;
                      padding: 10px 8px 12px;
                      border: solid 1px #f1c21b;
                      background-color: #fef9e7;

                      .profile__values__table__content__action__confirm__title {
                        display: flex;
                        justify-content: space-between;
                        .profile__values__table__content__action__confirm__icon
                          img {
                          width: 21px;
                          height: 18px;
                        }
                        .profile__values__table__content__action__confirm__close
                          img {
                          width: 14px;
                          height: 16px;
                          cursor: pointer;
                        }
                        .profile__values__table__content__action__confirm__text {
                          @include fontBase;
                          line-height: 1.43;
                          color: #212b36;
                          padding: 0 8px;
                        }
                      }
                    }
                    .profile__values__table__content__action__confirm__action {
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;

                      .profile__values__table__content__action__confirm__cancel {
                        @include fontBase;
                        width: 50px;
                        height: 20px;
                        text-align: center;
                        color: #212b36;
                        margin: 0;
                        cursor: pointer;
                      }
                      .profile__values__table__content__action__confirm__yes {
                        @include fontBase;
                        color: #fff;
                        width: 68px;
                        height: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        border: solid 2px #b00720;
                        background-color: #b00020;
                        margin-left: 10px;
                        cursor: pointer;
                      }
                    }
                    input#check__box__profile__values__table__content__action__confirm {
                      display: none;
                    }
                    img[ng-if="profile.property"].profile__values__table__content__action__remove__icon {
                        cursor: pointer;
                    }
                    .profile__values__table__content__action__remove__icon {
                      position: relative;
                      width: 16px;
                      height: 18px;
                    }
                    .profile__values__table__content__action__confirm::before {
                      content: "";
                      position: absolute;
                      width: 15px;
                      height: 15px;
                      left: 235px;
                      top: -8px;
                      transform: rotate(135deg);
                      background: #fef9e7;
                      border-bottom: 1px solid #f1c21b;
                      border-left: 1px solid #f1c21b;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .profile__values__list__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .profile__values__list__message__no__profile {
          @include warningMessage;
        }
        .profile__values__list__new__profile {
          width: 144px;
          height: 36px;
          padding: 8px 24px;
          margin: 16px 0;
          border-radius: 4px;
          background-color: $primary-button-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 0;

          img {
            width: 11px;
            height: 14px;
          }

          span {
            @include fontBase;
            color: $white-color;
            font-weight: 600;
          }
        }
      }

      div.hr {
        width: 892px;
        height: 1px;
        margin-bottom: 16px;
        background-color: #dfe3e8;
      }

      .user__profile__list__title {
        ::-webkit-scrollbar-track {
          margin-left: 0;
          margin-right: 0;
        }
        .user__profile__list__title__text {
          @include titleText;
        }
        .user__profile__list__action__add {
          margin-bottom: 18px;
          label {
            @include fontBase;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.5;
            color: #212b36;
            margin-right: 6px;
          }
          .user__profile__list__action__add__input {
            @include fontBase;
            width: 172px;
            height: 38.6px;
            border: solid 1px #ebebeb;
            margin-right: 16px;
            border-radius: 4px;
            padding: 0 16px;
          }
          .user__profile__list__action__add__button {
            width: 77px;
            height: 36px;
            padding: 8px 24px;
            border-radius: 4px;
            border: solid 2px #269cd2;
            background-color: #269cd2;
            margin-right: 8px;

            @include fontBase;
            font-weight: 600;
            color: $white-color;
          }
          .user__profile__list__action__clear__input {
            @include fontBase;
            font-weight: 600;
            width: 169px;
            height: 36px;
            padding: 8px 20px;
            border-radius: 4px;
            border: solid 2px #269cd2;
            color: #269cd2;
            background-color: $white-color;
          }

          .user__profile__list__action__clear__input.disable {
            border: solid 2px #dbdbdb;
            color: #dbdbdb;
            cursor: default;
          }
        }
        .user__profile__list__action {
          margin-top: 8px;
          margin-bottom: 18px;
          .user__profile__list__message__has__profile {
            @include warningMessage;
            width: 813.2px;
          }
          .user__profile__list__message__no__profile {
            @include warningMessage;
            width: 298px;
          }

          @media (max-width: 1660px) {
            .user__profile__list__message__has__profile {
              width: 565px;
              padding: 4px;
              height: 40px;
            }
          }
        }
        .user__profile__list__content {
          max-height: 460px;
          overflow: auto;

          table.user__profile__list__table {
            border-collapse: separate;

            thead {

              tr.user__profile__list__table__header {
                @include header;
                background-color: #ebf5fa;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.23);

                th:nth-child(1) {
                  min-width: 422px;
                  padding: 0 25px;
                }
                th:nth-child(2) {
                  min-width: 90px;
                }
                th:nth-child(3) {
                  min-width: 372px;
                }
              }
            }

            tbody {

              tr.user__profile__list__table__body {
                z-index: -1;
                position: relative;
                @include body;
                td:nth-child(1) {
                  position: relative;
                  min-width: 422px;
                  padding: 0 25px;
                  border-top: solid 1px #dfe3e8;
                  border-left: solid 1px #dfe3e8;
                  border-bottom: solid 1px #dfe3e8;

                  input,
                  input.np_user__profile_value__property {
                    @include fontBase;
                    width: 352px;
                    height: 48px;
                    padding: 16px;
                    border-radius: 4px;
                    border: solid 1px #dededf;
                    background-color: #f4f6f8 !important;
                    outline: none;
                    border-radius: 4px;
                  }
                  input.np_user__profile_value__property.disable {
                    opacity: 0.5;
                    cursor: not-allowed;
                  }
                  .np_user__profile_value__property__icon {
                    position: absolute;
                    top: 30.8px;
                    right: 62.7px;
                    opacity: 0.5;
                    width: 8.3px;
                    border-right: 4.1px solid transparent;
                    border-left: 4.1px solid transparent;
                    border-top: 4.1px dashed;
                    color: #4d4d4d;
                  }
                }
                td:nth-child(2) {
                  @include fontBase;
                  border-top: solid 1px #dfe3e8;
                  border-bottom: solid 1px #dfe3e8;
                  min-width: 90px;
                  cursor: not-allowed;

                  .np_user__profile_value__type {
                    font-weight: 600;
                    color: #212b36;
                    opacity: 0.5;
                    line-height: 1.29;
                  }
                }
                td:nth-child(3) {
                  min-width: 372px;
                  border-top: solid 1px #dfe3e8;
                  border-right: solid 1px #dfe3e8;
                  border-bottom: solid 1px #dfe3e8;

                  input,
                  select {
                    padding: 0 16px;
                    width: 173px;
                    height: 48px;
                    opacity: 0.5;
                    border: solid 1px #ebebeb;
                    background-color: #f4f6f8;
                    border-radius: 4px;
                    cursor: not-allowed;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.profile__value__preview.show {
  display: block;
}
