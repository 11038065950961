@media print {
  .program-detail-view {
    a[href].accordion-toggle:after, a[href].title-header:after {
      content: '' !important;
    }

    .panel-collapse {
      height: auto !important;
      display: block !important;
    }
  }
}
