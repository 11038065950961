@import "common";

body {
  height: 100vh;
  background-color: #f3f3f3;
}

.form__dashboard {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 95px);
}

.form__header {
  height: 33px;
}

.form__header__no__item {
  height: 0px;
}

.form__table__container {
  margin-top: $base-margin;
  flex: 1;
  overflow: hidden;
}

.form__table__filter {
  table {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 300px);
    margin-top: $base-margin;
    margin-bottom: 0;
    padding-right: 4px;
    position: relative;
    overflow-x: auto;

    thead {
      width: 100%;
      min-width: 2110px;
      height: 49px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.23);
      background-color: $th-background;
      position: sticky;
      top: 0;
      z-index: 3;
      border-radius: 6px;

      tr {
        display: flex;

        th {
          display: flex;
          align-items: center;
          height: 49px;
          letter-spacing: 1.02px;
          line-height: 1.5;
          border-bottom-width: 0;
          font-family: $font-family;
          font-weight: 600;
          text-align: left;
          color: #131523;
          font-size: $base-font-size;
          border: solid 1px $base-border-color;
          padding: 0 8px;
        }

        th:nth-child(1) {
          min-width: 146px;
          max-width: 146px;
          border: solid 1px $base-border-color;
          border-right-width: 0;
          justify-content: flex-start;
          background-color: $th-background;
          border-top-left-radius: 6px;
          z-index: 2;
        }

        th:nth-child(2), th:nth-child(3) {
          min-width: 315px;
          max-width: 315px;
          border: solid 1px $base-border-color;
          border-left-width: 0;
          justify-content: flex-start;
          background-color: $th-background;
          padding-left: 19px;
          z-index: 2;
        }

        th:nth-child(4) {
          flex: 1;
          border: solid 1px $base-border-color;
        }

        th:nth-child(5) {
          min-width: 441px;
          max-width: 441px;
          border: solid 1px $base-border-color;
        }

        th:nth-child(6) {
          min-width: 150px;
          max-width: 150px;
          padding-right: 0;
          border: solid 1px $base-border-color;
        }

        th:nth-child(7) {
          min-width: 250px;
          max-width: 250px;
          border: solid 1px $base-border-color;
        }

        th:nth-child(8) {
          width: 166px;
          border: solid 1px $base-border-color;
          background-color: $th-background;
          position: sticky;
          right: 0px;
          border-top-right-radius: 6px;
        }
      }
      .thClass {
        position: initial !important;
        display: initial!important;
      }
    }

    tbody {
      flex: 1;
      //   overflow-y: auto;
      //   overflow-x: hidden;
      width: 100%;
      min-width: 2110px;
      border: solid 1px #ebebeb;

      tr {
        display: flex;

        td {
          padding: 8px;
          font-family: $font-family;
          text-align: left;
          height: 76px;
        }

        td:nth-child(1) {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          min-width: 146px;
          max-width: 146px;
          border-right-width: 0;
          font-size: $base-margin;
          font-weight: 500;
          letter-spacing: 0.23px;
          color: #212b36;
          z-index: 1;
          .form-data-type {
            width: 62px;
            .form-type {
              padding: 0 2px;
              border-radius: 4px;
              border: solid 1px #707070;
              background-color: #fffaf3;

              height: 17px;
              font-family: $font-family;
              font-size: 12px;
              font-weight: bold;
              color: #7a4d05;

              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              margin-right: 2px;
              width: fit-content;
            }
          }
        }

        td:nth-child(1):hover {
          z-index: 3;
        }

        td:nth-child(1):hover ~ .third-col {
          z-index: 4;
        }

        td:nth-child(2) {
          min-width: 315px;
          max-width: 315px;
          border-left-width: 0;
          font-size: $base-margin;
          font-weight: 500;
          color: #212b36;
          padding-left: $base-margin;
          z-index: 2;

          .verified-form {
            padding: 4px 14px;
            border-radius: 4px;
            border: solid 1px #f1c21b;
            background-color: #f1c21b;
          }
          .unverified-form {
            padding: 4px 14px;
            border-radius: 4px;
            border: solid 1px #919eab;
            background-color: #c4cdd5;
          }
        }

        td:nth-child(3) {
          min-width: 315px;
          max-width: 315px;
          border: solid 1px #dfe3e8;
          font-size: $base-margin;
          font-weight: 500;
          color: #212b36;
          padding-left: $base-margin;
          z-index: 2;

          .form-name-status-txt {
            font-family: $font-family;
            font-size: 14px;
            font-weight: 500;
            line-height: 0.93;
          }
          .form-name-status-txt.warning {
            color: #ec6922;
          }
          .form-name-status-txt.danger {
            color: #ba2842;
          }
        }

        td:nth-child(4) {
          flex: 1;
          border: solid 1px #dfe3e8;
          font-size: $base-font-size;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #212b36;
        }

        td:nth-child(5) {
          min-width: 441px;
          max-width: 441px;
          border: solid 1px #dfe3e8;
          font-size: $base-font-size;
          font-weight: 500;
          letter-spacing: normal;
          color: #131523;
        }

        td:nth-child(6) {
          min-width: 150px;
          max-width: 150px;
          border: solid 1px #dfe3e8;
          font-size: $base-font-size;
          font-weight: 500;
          letter-spacing: normal;
          color: #131523;
        }

        td:nth-child(7) {
          min-width: 250px;
          max-width: 250px;
          border: solid 1px #dfe3e8;
          font-size: $base-margin;
          font-weight: 500;
          letter-spacing: 0.23px;
          color: #131523;

          display: flex;
          justify-content: space-between;

          span.version {
            cursor: pointer;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .status-version {
            display: flex;
          }
          img.active,
          img.inactive {
            width: 22px;
            height: 22px;
            margin-bottom: 4px;
          }
          span.inactive {
            color: #919eab;
            max-width: 48px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        td:nth-child(8) {
          width: 165px;
          border: solid 1px #dfe3e8;
          background-color: $base-white-color;
          font-size: $base-margin;
          font-weight: 500;
          letter-spacing: 0.23px;
          color: #007bc7;

          position: sticky;
          right: 0px;
          display: flex;
          align-items: center;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);

          .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: relative;

            .manage-versions {
              margin: 0 5px 2px 0;
              font-family: $font-family;
              font-size: $base-margin;
              font-weight: 500;
              line-height: 1.56;
              letter-spacing: 0.23px;
              color: #007bc7;
              text-decoration-color: #007bc7;
              cursor: pointer;
              position: relative;

              .manage-versions-icon {
                width: 20px;
                height: 18px;
                margin-top: -3px;
              }
              .manage-versions-txt {
                text-decoration: underline;
              }
              .tooltip {
                font-size: 14px;
                height: 62px;
                width: 175px;
              }
            }
            .actions-versions {
              @include baseFLex;
              border-radius: 4px;
              box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
              background-color: #f8ffff;
              cursor: pointer;
              position: absolute;
              right: 0;

              .icon {
                @include baseFLex;
                min-width: 30px;
                height: 30px;
                flex-direction: column;
                span {
                  display: flex;
                  flex-direction: row;
                  width: 6px;
                  height: 6px;
                  background-color: #4686cb;
                  border-radius: 6px;
                  margin: 1px 0;
                }
              }
              .view {
                min-width: 30px;
                height: 30px;
                border-right: solid 1px #dbdbdb;
                justify-content: center;
                align-items: center;
                display: none;
                position: relative;
                img {
                  width: 16.4px;
                  height: 13.1px;
                }
              }
              .clone {
                min-width: 30px;
                height: 30px;
                border-right: solid 1px #dbdbdb;
                justify-content: center;
                align-items: center;
                display: none;
                position: relative;
                .tooltip-content{
                  text-align: center;
                  margin-bottom: 0;
                }
              }
              .edit {
                min-width: 30px;
                height: 30px;
                border-right: solid 1px #dbdbdb;
                justify-content: center;
                align-items: center;
                display: none;
                i {
                  width: 12.4px;
                  height: 12.4px;
                  color: #2185d0;
                  margin-bottom: 5px;
                }
                div.tooltip {
                  top: -45px;
                  width: 135px;
                }
              }
              .delete {
                min-width: 30px;
                height: 30px;
                border-right: solid 1px #dbdbdb;
                justify-content: center;
                align-items: center;
                display: none;
                img {
                  width: 13.9px;
                  height: 16.2px;
                }
              }
              div.disableicon {
                pointer-events: none;
                cursor: default;
                i {
                  color: #707070;
                }
              }
            }

            .actions-versions:hover {
              .clone,
              .view,
              .edit,
              .delete {
                display: flex;
                padding: 0 2px;
              }
              .icon {
                span {
                  border: solid 1px #707070;
                  background-color: #707070;
                }
              }
            }
          }
        }
      }

      tr.no-data {
        flex-direction: column;
        flex: 1;
        height: 100%;
        @include baseFLex;
        td {
          border: none;
          font-size: $base-margin;
          font-weight: 500;
          color: #212b36;
          background-color: transparent;
          min-width: 250px;
          justify-content: center;
        }
      }

      tr.highlight {
        background-color: #fafafb;
      }
      .tdClass{
        height: auto !important;
        display: initial !important;
        width: auto !important;
        position: initial!important;
      }
    }
  }

  table.form__table__no_data {
    height: calc(100vh - 300px);
  }

  /* SORTER */
  .sorter {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    cursor: pointer;
    position: relative;

    .up {
      position: absolute;
      color: #919eab;
    }
    .up.active {
      color: #006fb3;
    }
    .down {
      color: #919eab;
    }
    .down.active {
      color: #006fb3;
    }
  }
  .sorter.active .up,
  .sorter.active .down {
    color: #006fb3;
  }
  .sorting-column {
    @include baseFLex;
  }

  /* CUSTOM SCROLL-BAR */
  ::-webkit-scrollbar {
    /* width */
    width: 8px;
    max-height: 8px;
    scroll-margin-left: 10px;
  }

  ::-webkit-scrollbar-track {
    /* Track */
    border-radius: 4px;
    background-color: #dfe3e8;
  }

  ::-webkit-scrollbar-thumb {
    /* Handle */
    border-radius: 3px;
    background-color: #919eab;
  }

  ::-webkit-scrollbar-track {
    margin-top: 49px;
    margin-left: 775px;
    margin-right: 167px;
  }
}
.form__table--responsive {
  .form__table th:nth-child(8)::after,
  .form__table td:nth-child(8)::after {
    content: "";
    position: absolute;
    right: -5px;
    top: -2px;
    width: 4px;
    min-height: 78px;
    background-color: #fff;
    z-index: 4;
  }
}

/* TOOLTIP */
.tooltip {
  top: -82px;
  border-radius: 5px;
  display: none;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  z-index: 1000;

  .tooltip-header {
    font-size: 14px;
    font-weight: 600;
    font-family: $font-family;
    color: #212b36;
    margin-bottom: 4px;
  }
  .tooltip-content {
    margin-bottom: 4px;
  }
}

.delete:hover .tooltip,
.edit:hover .tooltip,
.view:hover .tooltip,
.clone:hover .tooltip {
  @include baseTooltip;
  width: 165px;
  font-size: 12px;
}
.manage-versions:hover .tooltip {
  @include baseTooltip;
  width: 165px;
  font-size: 12px;
  top: -78px;
}

.manage-versions:hover .tooltip {
  left: -86px;
}

.manage-versions:hover .tooltip.first_tooltip_manager {
  position: fixed;
  top: 195px;
  right: 55px;
  left: initial;
}

.delete:hover .tooltip.firstRow,
.view:hover .tooltip.firstRow,
.clone:hover .tooltip.firstRow {
  top: 40px;
}

.edit:hover .tooltip.firstRow {
  top: 34px;
}

.manage-versions:hover .tooltip.firstRow {
  @include baseTooltip;
  top: 30px;
  width: 165px;
  font-size: 12px;
}

.formId:hover .tooltip {
  @include baseTooltip;
  @include baseFLex;
  font-size: 14px;
  height: 36px;
  width: 195px;
  top: -53px;
  left: -30px;
}
.versionId:hover .tooltip {
  @include baseTooltip;
  @include baseFLex;
  font-size: 14px;
  height: 36px;
  width: 100px;
  top: -53px;
  left: -23px;
}

.tooltip::after {
  @include tooltipArrow;
  left: 45%;
  width: 18px;
  height: 18px;
}

.clone:hover .tooltip.tooltipClone {
  top: -40px;
  width: 75px;
}

.delete:hover .tooltip.tooltipDelete {
  top: -85px;
  right: 0px;
  width: 185px;
}
.tooltipDelete.tooltip::after {
  @include tooltipArrow;
  left: 70%;
  width: 18px;
  height: 18px;
}

.edit:hover .tooltip::after {
  bottom: -7px;
  width: 12px;
  height: 12px;
  left: 35%;
}

.versionId .tooltip::after {
  @include tooltipArrow;
  left: 30%;
  bottom: -7px;
  width: 12px;
  height: 12px;
}

.formId .tooltip::after {
  @include tooltipArrow;
  left: 18%;
  bottom: -7px;
  width: 12px;
  height: 12px;
}

.manage-versions:hover .tooltip::after {
  @include tooltipArrow;
  left: 55%;
  width: 18px;
  height: 18px;
}

.clone:hover .tooltip.firstRow::after,
.view:hover .tooltip.firstRow::after,
.delete:hover .tooltip.firstRow::after,
.manage-versions:hover .tooltip.firstRow::after {
  @include tooltipArrow;
  transform: rotate(225deg);
  top: -10px;
}
.edit:hover .tooltip.firstRow::after {
  @include tooltipArrow;
  transform: rotate(225deg);
  top: -6px;
}

tr.firstTr td:nth-child(8):hover {
  z-index: 3;
}

.clone:hover .tooltip.first_tooltip_clone {
  position: fixed;
  top: 200px;
  right: 100px;
  width: 75px;
}

.view:hover .tooltip.first_tooltip_preview {
  position: fixed;
  top: 180px;
  right: 55px;
}

.delete:hover .tooltip.first_tooltip_delete {
  position: fixed;
  top: 180px;
  right: 60px;
  width: 185px;
}
/* KEYFRAMES */
@keyframes tooltips-html {
  to {
    opacity: 1;
    transform: translate(0, 5px);
  }
}

/* STICKY FIRST AND LAST COLUMN */
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
}

.first-col {
  width: 125px;
  min-width: 125px;
  max-width: 125px;
  left: 0;
  background-color: $grey-medium-color;
  border: 1px solid $base-border-color;
  .form-id {
    @include truncateText;
    cursor: pointer;
    min-width: 30px;
  }
  .inactive-form-id-icon {
    width: 20px;
    height: 18px;
    margin-top: -8px;
    cursor: pointer;
  }
  .form-id-icon {
    width: 20px;
    height: 18px;
    margin-top: -8px;
    cursor: pointer;
  }
  .sync-form-id-icon {
    width: 20px;
    height: 18px;
    margin-top: -8px;
    cursor: pointer;
  }
}

.first-inactive-col {
  background-color: $base-yellow-color;
}

.first-sync-error-col {
  background-color: $base-red-color;
}

.third-col {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  left: 461px;
  background-color: $grey-medium-color;
  border: 1px solid $base-border-color;
  .form-name-status {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #212b36;
    margin-top: 2px;
  }
  .empty-form-name-status {
    margin-top: 28px;
  }
  .form-name-status-icon {
    width: 16px;
    height: 16px;
    margin-top: -2px;
    margin-right: 8px;
  }
  .form-name-sync-icon {
    width: 16px;
    height: 14.2px;
    margin-right: 8px;
    margin-top: -5px;
  }
}

.second-col {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  left: 146px;
  background-color: $grey-medium-color;
  border: 1px solid $base-border-color;
}

.third-inactive-col {
  background-color: $base-yellow-color;
}

.third-sync-error-col {
  background-color: $base-red-color;
}

/* ORTHER */
.remove-padding {
  padding: 0;
}

.remove-margin {
  margin: 0;
}

.function-version {
  position: relative;

  .active-btn {
    @include baseFLex;
    margin-top: -10px;
    margin-right: 30px;

    width: 95px;
    height: 28px;
    padding: 4px 8px;
    border-radius: 4px;
    border: solid 2px $base-line-blue;
    background-color: $base-line-blue;

    font-family: $font-family;
    font-size: $base-font-size;
    font-weight: 600;
    text-align: center;
    color: $base-white-color;

    .icon {
      @include baseFLex;

      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: $base-white-color;
      margin-right: 3px;

      img {
        width: 6px;
        height: 7px;
        margin-top: 1px;
      }
    }
  }

  .active-confirm {
    position: absolute;
    z-index: 1;
    left: -137px;
    top: 36px;
    width: 351px;
    height: 140px;
    border: solid 1px $base-green-color;
    background-color: $base-blue-color;
    display: none;
    padding: 8px;
    border-radius: 6px;

    .active-header {
      @include flexSpaceBetween;
      font-family: $font-family;
      font-size: $base-font-size;
      font-weight: 500;
      color: $base-black-color;
    }
    .active-line {
      width: 326px;
      height: 0;
      margin: 3.5px 0 0 0.5px;
      border: solid 1px #c4cdd5;
    }
    .active-content {
      display: flex;
      padding: 8px 0;
      .icon {
        flex: 1;
      }
      .message {
        width: 308px;
        font-family: $font-family;
        font-size: $base-font-size;
        font-weight: 500;
        color: $base-black-color;
      }
    }
    .active-action {
      @include flexEnd;
      margin-top: 12px;
      .cancel {
        height: 20px;
        margin-right: 10px;
        font-family: $font-family;
        font-size: $base-font-size;
        font-weight: 500;
        text-align: center;
        color: #b00020;
        border: none;
        background-color: transparent;
      }
      .active {
        height: 28px;
        padding: 4px 5px 4px;
        border-radius: 4px;
        font-size: $base-font-size;
        font-family: $font-family;
        border: solid 2px $base-line-blue;
        background-color: $base-line-blue;
        font-weight: 500;
        color: $base-white-color;
        @include baseFLex;
      }
    }
  }

  .active-confirm::after {
    content: "";
    width: 18px;
    height: 18px;
    background: $base-blue-color;
    position: absolute;
    right: 48%;
    top: -10px;
    transform: rotate(45deg);
    border-top: 1px solid $base-green-color;
    border-left: 1px solid $base-green-color;
  }

  .inactive-btn {
    .icon img {
      width: 17px;
      height: 13px;
      margin-top: 1px;
      background-color: $base-green-color;
      margin-left: 3px;
    }
    background-color: $base-green-color;
    border: solid 2px $base-green-color;
  }

  .inactive-confirm {
    height: 50px;
    .inactive-header {
      justify-content: normal;
      margin-left: 8px;
    }
    .inactive-activated-text {
      flex: 2 1;
      margin-left: 6px;
      margin-top: 5px;
    }
  }
}

.form-list-function-version {
  .active-btn {
    margin-top: 0;
    font-size: $small-font-size;
    width: 72px;
    height: 22px;
    margin-right: 22.5px;
  }
}

.program-error {
  .form__filter--sorter__by-programs__view-more {
    margin: 0;
  }
}

@media only screen and (max-width: 1870px) {
  .manage-versions:hover .tooltip.first_tooltip_manager {
    right: 115px;
  }

  .clone:hover .tooltip.first_tooltip_clone {
    top: 230px;
    right: 120px;
  }
  .view:hover .tooltip.first_tooltip_preview {
    top: 187px;
    right: 46px;
  }
  .delete:hover .tooltip.first_tooltip_delete {
    top: 187px;
    right: 52px;
  }
}

@media only screen and (min-width: 1895px) {
  .manage-versions:hover .tooltip.first_tooltip_manager {
    right: 115px;
  }

  .clone:hover .tooltip.first_tooltip_clone {
    top: 230px;
    right: 120px;
  }
  .view:hover .tooltip.first_tooltip_preview {
    top: 187px;
    right: 46px;
  }
  .delete:hover .tooltip.first_tooltip_delete {
    top: 187px;
    right: 52px;
  }
}

.form-list-action-txt {
  flex: 2 1;
}

.form-list-action-icon {
  width: 25.3px;
  height: 25.4px;
  margin-right: 10px;
}

.edit:hover .edit-current-version-tooltip {
  left: 0;
  width: 138px;
  margin-top: -34px;
  margin-left: 6px;
  top: 0;
  padding: 5px 9px 6px 10px;
}

.edit:hover .consent-tooltip {
  margin-left: -2px;
  margin-top: -30px;
}
.donationSiteList {
  .dstableClass {
    overflow: scroll;
    max-height: calc(100vh - 300px);
  }

  .thClass {
    border: 1px solid #ddd;
  }

  .tdClass {
    border: 1px solid #ddd;
  }

  .filterClass {
    display: flex;
    padding-bottom: 12px;
  }
   .isFillter {
    border: solid 1px #1898db;
    opacity: 1;
  }
}

.bottom-filter-search .form__pagination__page__select__size select:after {
  @include tooltipArrow;
  left: 45%;
  width: 18px;
  height: 18px;
}
