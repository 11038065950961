/*****************************************************
custom nav bar colors
******************************************************/
.navbar-custom-for-form {
  background-color: #dbe6db;
  border-color: #2b92c0;
}
.navbar-custom-for-form .navbar-brand {
  color: #0c4c5c;
}
.navbar-custom-for-form .navbar-brand:hover,
.navbar-custom-for-form .navbar-brand:focus {
  color: #1a0505;
}
.navbar-custom-for-form .navbar-text {
  color: #0c4c5c;
}
.navbar-custom-for-form .navbar-nav > li > a {
  color: #0c4c5c;
}
.navbar-custom-for-form .navbar-nav > li > a:hover,
.navbar-custom-for-form .navbar-nav > li > a:focus {
  color: #1a0505;
}
.navbar-custom-for-form .navbar-nav > .active > a,
.navbar-custom-for-form .navbar-nav > .active > a:hover,
.navbar-custom-for-form .navbar-nav > .active > a:focus {
  color: #1a0505;
  background-color: #2b92c0;
}
.navbar-custom-for-form .navbar-nav > .open > a,
.navbar-custom-for-form .navbar-nav > .open > a:hover,
.navbar-custom-for-form .navbar-nav > .open > a:focus {
  color: #1a0505;
  background-color: #2b92c0;
}
.navbar-custom-for-form .navbar-toggle {
  border-color: #2b92c0;
}
.navbar-custom-for-form .navbar-toggle:hover,
.navbar-custom-for-form .navbar-toggle:focus {
  background-color: #2b92c0;
}
.navbar-custom-for-form .navbar-toggle .icon-bar {
  background-color: #0c4c5c;
}
.navbar-custom-for-form .navbar-collapse,
.navbar-custom-for-form .navbar-form {
  border-color: #0c4c5c;
}
.navbar-custom-for-form .navbar-link {
  color: #0c4c5c;
}
.navbar-custom-for-form .navbar-link:hover {
  color: #1a0505;
}

.navbar-custom-for-form .navbar-nav>li>a {
  padding-top:7px;
  padding-bottom:7px;
}

.navbar-custom-for-form.navbar {
  min-height:36px;
}

@media (max-width: 767px) {
  .navbar-custom-for-form .navbar-nav .open .dropdown-menu > li > a {
    color: #0c4c5c;
  }
  .navbar-custom-for-form .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-custom-for-form .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #1a0505;
  }
  .navbar-custom-for-form .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-custom-for-form .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-custom-for-form .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #1a0505;
    background-color: #2b92c0;
  }
  .inner-navbar-links {
    display: none;
  }
}

@media(min-width:768px) {
  .under-navbar-links {
    display: none;
  }
}

.navbar-inner {
  display: flex;
  justify-content: space-between;
  &:before, &:after{
    content: none;
  }
  padding: 0 15px;
}

.inner-navbar-links .navbar-site-link-list {
  white-space: nowrap;

  >li {
    float: none;
    display: inline-block;
  }
}

.under-navbar-links .navbar-site-link-list {
  margin: 8px 0;
}

.navbar-collapse {
  padding: 0;
}

.navbar-title {
  margin-left: 10px;
}

.navbar-title-vibrent {
  color: #800080;
}

.navbar-title-pmi {
  color: #3085BB;
}

.navbar-brand {
  padding: 0;
  clear: both;
  line-height: 0;
  height: auto;
}

.navbar-brand-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
}

.navbar-header {
  display: flex;
  flex-grow: 0;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
}
