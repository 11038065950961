/**************************************************
modal window size for ngdialog
***************************************************/

@import "common";

@-webkit-keyframes ngdialog-slidein {
  0% {
    top: -5px;
  }

  100% {
    top: 0;
  }
}

@keyframes ngdialog-slidein {
  0% {
    top: -5px;
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes ngdialog-slideout {
  0% {
    top: 0;
  }

  100% {
    top: -5px;
  }
}

@keyframes ngdialog-slideout {
  0% {
    top: 0;
  }

  100% {
    top: -5px;
  }
}

.ngdialog {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;

  .ngdialog-overlay {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  .ngdialog-content {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: ngdialog-slidein 0.5s;
    animation: ngdialog-slidein 0.5s;
  }
}

.ngdialog.ngdialog-closing {
  -webkit-animation: ngdialog-fadeout 0.5s;
  animation: ngdialog-fadeout 0.5s;

  .ngdialog-content {
    -webkit-animation: ngdialog-slideout 0.5s;
    animation: ngdialog-slideout 0.5s;
  }
}

.ngdialog.ngdialog-theme-default .ngdialog-close,
.ngdialog.ngdialog-theme-plain .ngdialog-close,
.ngdialog.ngdialog-theme-plain.custom-width-fix40 .ngdialog-close,
.ngdialog.ngdialog-theme-center .ngdialog-close
{
  cursor: pointer;
  background: transparent;
  border-radius: 3px;
  position: absolute;
  top: 3px;
  right: 3px;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;

  &:before {
    content: '\2715';
    position: absolute;
    color: #bbb;
    font-size: 20px;
    font-weight: 400;
    width: 26px;
    top: 4px;
    right: 2px;
  }

  &:hover:before {
    color: #777;
  }

  &:active:before {
    color: #555;
  }
}

.ngdialog.ngdialog-theme-plain .ngdialog-content {
  @include normal-text;
  width: 60%;
  background: #f0f0f0;
  border-radius: 5px;
  font-family: 'Helvetica', sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 30px auto;
  max-width: 100%;
  padding: 1em;
  position: relative;

  .modal-header {
    font-size: 1.4em;
  }
}

.ngdialog.ngdialog-theme-center .ngdialog-content {
  @include normal-text;
  width: 60%;
  background-color: $base-white-color;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 30px auto;
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  .modal-header {
    font-size: 1.4em;
    color: #003e65;
    font-weight: 500;
    border-bottom: none;
    padding-bottom: 0px;
  }

  .modal-footer {
    background-color: #f2f9fd;
    text-align: center;
    border: none;
    button {
      min-width: 135px;
      font-weight: 500;
    }
  }

  .modal-body {

    .form-item{
      margin-bottom: 10px;
    }

    .form-item-label{
      color:#667081;
      font-weight: 500;
    }

    .set-radio {
      cursor: pointer;
      margin-left: 15px;
      margin-right: 3px;
      width: 23px;
      height: 23px;
      vertical-align: text-bottom;

    }

    .import-item {
      input {
        @extend .set-radio;
        margin-left: 0px;
      }
      label {
        @extend .form-item-label;
        margin-right: 15px;
      }
    }

    .form-detail {
      max-width: 75%;
      margin: 13px 0 28px;
      padding: 16px 14.4px 16px 16px;
      border-radius: 4px;
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
      background-color: #f0f9fd;
      label, span {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #1f2b37;
      }
      label {
        font-weight: 600;
      }
    }

    .form-detail-label {
      @extend .form-item-label;
      max-width: 75%;
      height: 20px;
      margin-left: 15px;
    }

    .program-selection {
      margin-left: 15px;
      margin-top: 13px;
    }

    .system-info-banner {
      display: inline-block;
      width: fit-content;
      margin-bottom: 0px;
      padding: 8px;
      border-radius: 4px;
      border: solid 1px #006fb3;
      span {
        font-weight: 500;
        color: #212b36;
        font-size: 14px;
      }
    }

  }

  img{
    vertical-align: sub;
  }

}

.set-inline{
  display: inline-block;
}

.ngdialog.ngdialog-theme-plain.custom-width-fix40 .ngdialog-content {
  @include normal-text;
  width: 40%;
  background: #f0f0f0;
  border-radius: 5px;
  font-family: 'Helvetica', sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 30px auto;
  max-width: 100%;
  padding: 1em;
  position: relative;

  .modal-header {
    font-size: 1.4em;
  }
}

.ngdialog.cancellation-dialog, .ngdialog.order-details-dialog {
  .ngdialog-content {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $base-light-blue-color;
  }
  .ngdialog-close {
    width: 20px;
    height: 20px;
    top: 8px;
    right: 10px;
    background: $gray-close-color;
    mask: url("/assets/images/times.png") no-repeat center/contain;
    -webkit-mask: url("/assets/images/times.png") no-repeat center/contain;
    &:before{
      content: '';
    }
  }
}

.ngdialog.order-details-dialog {
  .ngdialog-content {
    padding: 32px 50px 16px 32px;
    background-color: $base-white-color;
    top: 80px;
    animation: ngdialog-fadein 0.5s;
    .modal-header {
      padding: 0px 0px 16px 0px;
      border-bottom: solid 2px $light-grey;
      .modal-title {
      font-size: 20px;
      }
      .cancel-order {
        position: relative;
        float: right;
        width: 126px;
        height: 36px;
        border-radius: 4px;
        border: solid 2px $red-border-color;
        background-color: $base-white-color;
        color: $light-red-color;
        font-weight: 600;
      }
      .re-order {
        float: right;
        width: 88px;
        height: 36px;
        border-radius: 4px;
        border: solid 2px $blue-color;
        background-color: $blue-color;
        font-weight: 600;
        color: $base-white-color;
      }
    }
    .modal-body{
      padding: 22px 0px 0px 0px;
      .cancellation-wrapper {
        display: flex;
        font-size: 16px;
        padding: 8px;
        margin: 0px 0px 24px 0px;
        border-radius: 4px;
        border: solid 1px $light-grey;
        background-color: $ghost-white-color;
        color: $base-black-color;
        font-weight: normal;
        .info-icon{
          @include infoIcon;
          margin-top: 2px;
        }
        .cancellation-reason {
          padding-left: 6px;
          .cancel-title {
            font-weight: 600;
          }
        }
      }
      .first-row-detail, .second-row-detail {
        padding-bottom: 10px;
        .order-detail-panel {
          height: 203px;
          -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
          border: solid 0.8px $base-border-color;
          .panel-heading {
            height: 38px;
            border: solid 1px $light-grey;
            background-color: $base-background-color;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: $base-black-color;
            text-transform: uppercase;
          }
          .panel-body {
            border-top: none;
            .detail-item {
              padding-bottom: 16px;
              .title {
                padding: 0px;
                color: $base-black-color;
              }
              .value-detail {
                color: $base-light-blue-color;
                .semi-bold {
                  font-weight: 600;
                }
                .order-status {
                  border-radius: 16px;
                  display: initial;
                  padding: 4px 8px;
                  font-weight: 600;
                }
                .order-status.error {
                  @include orderError;
                }
                .order-status.in_progress {
                  @include orderInProgress;
                }
                .order-status.initiated {
                  @include orderInitiated;
                }
                .order-status.cancelled {
                  @include orderCancelled;
                }
                .order-status.completed {
                  @include orderCompleted;
                }
              }
            }

          }
        }

      }
      .second-row-detail{
        height: auto;
        .order-detail-panel {
          min-height: 263px;
          height: auto;
        }
      }
      .tracking-details.to-participant {
        padding-bottom: 10px;
      }
      .tracking-details {
        padding: 0px 15px;
        .tracking-title {
          font-size: 15px;
          font-weight: 600;
          line-height: 1.53;
          letter-spacing: 1.09px;
          text-align: left;
          color: $base-mirage-color;
          border: solid 1px $light-grey;
          height: 53px;
          padding: 15px 8px;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
        }
        .tracking-order-detail {
          margin: 0px;
          font-size: 14px;
          .table__common table {
            font-size: 14px;
            tbody {
              tr:hover {
                background-color: none;
              }
              tr {
                border: solid 1px $light-grey;
                background-color: $ghost-white-color;
                .shipping-status {
                  font-weight: 600;
                }
                .shipping-id {
                  width: 27%;
                }
                .shipping-status, .shipped-on, .delivered-on {
                  width: 20%;
                }
                .carrier-code {
                  width: 13%;
                }
              }
            }

          }
        }


      }
    }
  }
}


.ngdialog.ngdialog-theme-plain.custom-width-large {
  .ngdialog-content {
    width: 88%;
  }
}

.ngdialog.ngdialog-theme-center.custom-width-small {
  .ngdialog-content {
    width: 40%;
  }
}

.ngdialog.ngdialog-theme-plain.custom-width-small {
  .ngdialog-content {
    width: 500px;
  }
}

.ngdialog.cancellation-dialog {
  background: rgba(0, 0, 0, 0);
  .ngdialog-content {
    width: 435px !important;
    padding: 16px 14px 16px 38px;
    position: absolute;
    right: 50px;
    top: 54px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.19);
    border: solid 1px $warning-color;
    background-color: $warning-background-color;
    font-size: 14px;
    font-weight: 500;
    .cancel-modal-header {
      border-bottom: none;
      color: $base-black-color;
      padding: 0px;
      width: 330px;
      .warning-icon {
        position: absolute;
        width: 21px;
        height: 18px;
        background-color: $warning-color;
        left: 10px;
        mask: url("/assets/images/exclamation-triangle.png") no-repeat center/contain;
        -webkit-mask: url("/assets/images/exclamation-triangle.png") no-repeat center/contain;
      }
    }
    .cancel-modal-body {
      padding: 10px 0px 0px 0px;
      .cancel-reason-title {
        padding-bottom: 8px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 600;
      }
      .cancel-select {
        height: 36px;
        border-radius: 4px;
        background-color: $base-white-color;
        color: $base-light-blue-color;
        padding: 0px 6px;
      }

      .cancel-reason {
        margin-top: 10px;
        .cancel-reason-text {
          width: 100%;
          height: 90px;
          border-radius: 4px;
          border: solid 1px $base-border-color;
          background-color: $base-white-color;
          padding: 10px;
          color: rgba(33, 43, 54, 0.69);
        }
        .cancel-reason-text::placeholder {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
    .cancel-modal-footer {
      padding: 18px 0px 0px 0px;
      border-top: none;
      .btn-cancel-order {
        border: solid 2px $blue-color;
        background-color: $blue-color;
        height: 28px;
        padding: 0px 5px;
        font-weight: 500;
      }
      .btn-cancel-order[disabled], .btn-cancel-order[disabled]:hover {
        border: solid 2px $blue-color;
        background-color: $blue-color;
        color: $base-white-color;
      }

      .btn-no {
        border: none;
        background: transparent;
        color: $base-black-color;
        font-weight: 500;
      }
    }
  }
  .ngdialog-content::after {
    content: "";
    z-index: -99;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 5px;
    box-sizing: border-box;
    transform-origin: 0 0;
    width: 20px;
    height: 20px;
    border: 1px solid $base-white-color;
    border-color: transparent transparent $warning-color $warning-color;
    transform:  rotate(135deg) skew(-10deg, -10deg);
    background-color: $warning-background-color;
  }
  .ngdialog-close {
    width: 11px;
    height: 16px;
    top: 14px;
    right: 14px;
    background: $base-black-color;
  }
}

.ngdialog.viewjson-dialog{
    overflow: auto;
    .ngdialog-content {
        background-color: $base-white-color;
    }
    .modal-header {
        color: $base-light-blue-color;
        padding: 32px 50px 16px 32px;
        font-family: Poppins;
        padding: 0px 0px 8px 0px !important;
        font-weight: 500;
        line-height: 1.56;
        .modal-title {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.5;
            text-align: left;
            color: #003e65;
        }
    }
    .modal-body{
        .json-rectangle{
            width: auto;
            height: 551px;
            margin: 16px 180px 0px 180px;
            border: solid 1px $light-grey;
            .json-header, .json-footer{
                border: solid 1px $light-grey;
                background-color: $ghost-white-color;
                width: auto;
            }
            .json-header{
                height: 40px;
              }
            .json-footer{
               height: 25px;
               .line-count{
                   padding-left: 32px;
                   font-size: 12px;
               }
            }
            .json-body{
                overflow-y: auto;
                height: 485px;
                width: 100%;
                padding: 0px 32px;
                white-space: pre-line;
                color: $base-green-color;
                font-family: Poppins;
                font-size: 14px;
            }
            .copy-img-content{
                float: right;
                display: flex;
                padding: 10px 16px 11px;
            }
            .clone-icon{
                height: 17px;
                width: 17px;
                margin-top: 2px;
                background-color: $blue-color;
                mask: url("/assets/images/clone.png") no-repeat center/contain;
                -webkit-mask: url("/assets/images/clone.png") no-repeat center/contain;
            }
            .copy-content{
                margin: -2px 0 0 8px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.5;
                color: $blue-color;
            }
          }
    }
        .ngdialog-close {
            width: 14px;
            height: 21px;
            top: 20px;
            right: 15px;
            background: $gray-close-color;
            mask: url("/assets/images/times.png") no-repeat center/contain;
            -webkit-mask: url("/assets/images/times.png") no-repeat center/contain;
            &:before{
              content: '';
            }
          }
}
.ngdialog .modal-footer {
  // avoids an issue in chrome browsers that causes buttons to display at the top of the dialog
  position: relative;
}

.flexible-container-600 {
  overflow: auto;
  min-height: 600px;
}

.modal-container {
  height: 100%;
  position: relative;
}

.modal-body-with-header {
  height: calc(100% - 65px);
  overflow-y: auto;
  overflow-x: hidden;
}

.ng-dialog-body-with-header-footer {
  height: calc(100% - 130px);
  overflow-y: auto;
  overflow-x: hidden;
}

.large-height-modal{
  .ngdialog-content {
    height: 640px!important;
  }
}
