/**************************************************
add a few animation classes for fun
***************************************************/
.vbr-animated.light-speed-in.ng-enter {
    animation: lightSpeedIn 0.5s;
}

.vbr-animated.flip-in-y.ng-enter {
    animation: flipInY 0.8s;
}

.vbr-animated.flip-out-y.ng-leave {
    animation: flipOutY 0.8s;
}

.vbr-animated.fade-in.ng-enter .vbr-animated.fade-in.ng-move {
    animation: fadeIn 1s;
}

.vbr-animated.fade-out.ng-leave .vbr-animated.fade-out.ng-move {
    animation: fadeOut 0.5s;
}

.vbr-animated.zoom-in.ng-enter {
    animation: zoomIn 0.8s;
}

.vbr-animated.zoom-out.ng-leave {
    animation: zoomOut 0.8s;
}

.vbr-animated.slide-in-right.ng-enter {
    animation: slideInRight 0.8s;
}

.vbr-animated.slide-in-down.ng-enter {
  animation: slideInDown 0.8s;
}

.vbr-animated.slide-in-up.ng-enter {
  animation: slideInUp 0.8s;
}

.vbr-animated.slide-in-left.ng-enter {
    animation: slideInLeft 0.8s, fadeIn 0.8s;
}

.vbr-animated.zoom-in-left.ng-enter {
    animation: zoomInLeft 0.8s;
}

.vbr-animated.zoom-out-left.ng-leave {
    animation: zoomOutLeft 0.8s;
}

@keyframes pulse-opacity {
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
}

.pulse-opacity-medium {
  animation: pulse-opacity 1.25s infinite ease-in-out;
}

/* selection highlight to show which element is being selected for 3 seconds */
.vbr-animated-selection-highlight {
  animation: bounceIn 0.8s, bounceIn 1s, bounceIn 1.2s;
  background: lightyellow;
  border: orange 1px dashed;
}

.rotate{
  -moz-transition: transform 0.25s ease-in-out;
  -webkit-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
}

.rotate.rotate-90{
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
