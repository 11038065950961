﻿@import "common";

$tableWidth: 1123px;

/* MIXIN */
@mixin successAlert {
  width: auto;
  height: 35px;
  padding: 7px 17px 8px 16px;
  border-radius: 4px;
  border: solid 1px $base-green-color;
  background-color: $base-blue-color;
  .message {
      width: 279px;
      height: 20px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: $base-black-color;
  }
  .close-icon {
    margin-left: 11px;
  }
}

.manage-form-container {
  z-index: 10;
  width: 1164px;
  margin-left: auto;
  position: absolute;
  background-color: $base-white-color;
  box-sizing: border-box;
  right: 0;
  display: block;
  height: 100vh;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #e4e4e4;
  overflow: hidden;
  .manage-form-header {
    background-color: #f4f6f8;
    border-bottom: 1px solid $base-grey-light-color;
    .manage-form-title {
      padding-top: 12px;
      padding-left: 16px;
      .manage-pen-icon {
        width: 22px;
        height: 19px;
        margin-top: -6px;
      }
      .manage-close-icon {
        width: 40px;
        height: 40px;
        float: right;
        margin-right: 22px;
        margin-top: -12px;
      }
      .manage-title-text {
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #00578c;
        padding-left: 4px;
      }
    }
    .manage-form-basic-info {
      font-family: 'Poppins', sans-serif;
      font-size: 13px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      border: 1px solid #ebebeb;
      padding: 9.4px 64.4px 6.2px 16px;
      border-radius: 4px;
      background-color: $base-white-color;
      margin: 3px 18px 6.2px 17.4px;
      .manage-form-basic-title {
        display: flex;
        .basic-info {
          flex: 3 1;
          font-weight: 600;
          color: #9b9595;
        }
        .date-of-creation {
          margin-top: -3.5px;
          flex: 2 1;
          .creation-title {
            font-weight: 600;
          }
          .creation-value, .modified-value {
            padding-left: 2px;
            font-weight: normal;
          }
          .modified-title {
            padding-left: 16px;
            font-weight: 600;
          }
        }
      }
      .manage-form-basic-content {
        padding-top: 12px;
        color: #171725;
        .creation-title {
          font-weight: 600;
        }
      }
    }
  }
  .manage-form-version-history {
    height: calc(100% - 255px);
    overflow-y: auto;
    padding-top: 14px;
    padding-left: 18px;
    padding-right: 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    .manage-form-version-title {
      margin-top: 11px;
      display: flex;
      justify-content: space-between;
      height: 46px;

      .version-history {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #0a4b6d;
      }

      .alert-and-search {
        display: flex;
        .delete-restore-version-alert {
          display: none;
          @include successAlert;
          margin-top: -5px;
          margin-right: 7.6px;
          .close-icon {
            margin-left: 20px;
          }
          .version-number {
            text-decoration: underline;
          }
        }
      }

    }
    .manage-form-search {
      margin-top: -10px;
      margin-right: 2px;
      .form__pagination {
        height: 46px;
      }
      .form__filter--search__input span.search-btn {
        height: 39.9px;
      }
      .form__pagination {
        .form__pagination__page.active::before {
          bottom: -8px;
        }
      }
      .form__filter--search__input {
        right: 0;
      }
    }
    .bottom-filter-search {
      display: flex;
      justify-content: flex-end;
      margin: 0 -12px 0 0;
      .form__pagination {
        height: 46px;
      }
    }

    .not-exist-delete-form {
      padding-bottom: 41px;
    }

    @media (max-height: $screenSize689) {
      .bottom-filter-search {
        margin: 14px -22px 6px 0;;
        position: unset;
        left: unset;
        right: unset;
        bottom: unset;
      }
    }

    .manage-form-search .form__filter--search__input {
      position: relative;
    }

    .manage-form-search .form__filter--search__input input[type=text]#search {
      height: 46px;
      width: 474px;
    }

    .manage-form-version-history-container {
      position: absolute;
      width: $tableWidth;
      margin-top: 60px;
      height: calc(100% - 88px);

      .manage-form-version-table {
        padding-bottom: 20px;

        .manage-form-table {
          width: $tableWidth;
          overflow-x: auto;

          .manage-form-thead {
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.02px;
            color: #131523;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.23);
            background-color: #ebf5fa;

            .manage-form-th-current-version {
              padding: 15px 17px 14px 8px;
              width: 150px;
            }

            .manage-form-th-previous-version {
              padding: 15px 17px 14px 8px;
              width: 153px;
            }

            .manage-form-th-creation {
              padding: 0 17px 21px 9px;
              width: 120px;
            }

            .drc-manage-form-th-creation {
              padding: 0 17px 21px 5px;
              width: 120px;
            }

            .manage-form-th-creator {
              padding: 0 17px 21px 20px;
              width: 120px;
            }

            .drc-manage-form-th-creator {
              padding: 0 17px 21px 10px;
              width: 120px;
            }

            .manage-form-th-status {
              padding: 15px 82px 18px 15px;
              width: 210px;
              @include baseFLex;
            }

            .drc-manage-form-th-status {
              padding: 15px 82px 18px 0;
              width: 210px;
              @include baseFLex;
            }

            .manage-form-th-semantic-version {
              padding: 15px 17px 35px 8px;
              width: 131px;
            }

            .drc-manage-form-th-semantic-version {
              padding: 15px 17px 15px 0;
              width: 131px;
            }

            .not-questionaire-id-th {
              width: 287px;
            }

            .manage-form-th-questionaire-id {
              padding: 15px 17px 14px 8px;
              width: 164px;
            }

            .drc-manage-form-th-questionaire-id {
              padding: 15px 17px 15px 8px;
              width: 139px;
            }

            .manage-form-th-actions {
              padding: 15px 17px 34px 8px;
              width: 91px;
            }

            .drc-manage-form-th-actions {
              padding: 15px 17px 34px 8px;
              width: 91px;
            }
          }

          .manage-form-tr {
            border: solid 1px $base-grey-light-color;
            background-color: #fafafb;
          }

          .manage-form-tbody-no-data {
            border: solid 1px $base-grey-light-color;
            background-color: #fafafb;
            width: 1125px;

            .no-data-tr {
              @include baseFLex;
              height: 370px;
            }
          }

          .manage-form-tbody {
            font-family: 'Poppins',
            sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: 0.23px;
            text-align: left;
            color: #212b36;
            margin-left: -1px;
            padding-right: 4px;

            .manage-form-td-current-version {
              padding: 12px 17px 18px 8px;
              width: 150px;

              .current-version-versionId {
                display: inline-block;
                max-width: 50px;
                @include truncateText;
              }

              .current-version-id {
                margin-left: 2px;
                display: inline-block;
                max-width: 55px;
                @include truncateText;
              }
            }

            .manage-form-td-previous-version {
              padding: 12px 17px 18px 8px;
              width: 153px;

              .previous-version-versionId {
                display: inline-block;
                max-width: 125px;
                @include truncateText;
              }
            }

            .manage-form-td-creation {
              padding: 12px 17px 4px 22px;
              width: 120px;
            }

            .manage-form-td-creator {
              padding: 12px 17px 24px 18px;
              width: 120px;
              color: #0098db;
              text-decoration: underline;
              max-width: 120px;
              @include truncateText;
              .td-creator {
                cursor: pointer;
              }
            }

            .manage-form-td-status {
              padding: 12px 17px 18px 34px;
              width: 210px;

              .active-status-version {
                color: #131523;
                display: inline-block;
                max-width: 46px;
                @include truncateText;
              }

              .inactive-status-version {
                color: $base-grey-medium-color;
                display: inline-block;
                max-width: 46px;
                @include truncateText;
              }

              .manage-form-status-btn {
                width: 99px;
                height: 28px;
                margin-top: -3px;
                cursor: pointer;
              }

              .active-status-id {
                color: #131523;
                margin-left: 12px;
                display: inline-block;
                max-width: 51px;
                @include truncateText;
                padding-right: 21px;
              }

              .inactive-status-id {
                color: $base-grey-medium-color;
                margin-left: 12px;
                display: inline-block;
                max-width: 51px;
                @include truncateText;
              }

              .td-status {
                display: inline-flex;
                float: right;
                margin-top: 6px;
              }

              .td-status-icon {
                display: inline-flex;
              }
            }

            .drc-manage-form-td-status {
              padding: 12px 17px 18px 24px;
              width: 210px;
            }

            .manage-form-td-semantic-version {
              padding: 12px 17px 14px 7px;
              width: 141px;

              .semantic-version {
                display: inline-block;
                max-width: 115px;
                @include truncateText;
              }
            }

            .drc-manage-form-td-semantic-version {
              padding: 12px 17px 14px 5px;
              width: 141px;
            }

            .not-questionaire-id-td {
              width: 280px;

              .semantic-version {
                max-width: 250px;
              }
            }

            .manage-form-td-questionaire-id {
              padding: 12px 17px 14px 8px;
              width: 154px;

              .questionaire-id {
                display: inline-block;
                max-width: 125px;
                @include truncateText;
              }
            }

            .drc-manage-form-td-questionaire-id {
              padding: 12px 17px 14px 8px;
              width: 139px;
            }

            .manage-form-td-actions {
              padding: 12px 17px 29px 7px;
              width: 91px;

              .version-menu {
                position: relative;
              
                .delete-confirm {
                  position: absolute;
                  z-index: 1;
                  right: -17px;
                  top: 31px;
                  width: 353px;
                  height: 225px;
                  border: solid 1px #f1c21b;
                  background-color: #fef9e7;
                  display: none;
                  padding: 17px;
                  border-radius: 6px;

                  font-family: $font-family;
                  font-size: $base-font-size;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.43;
                  letter-spacing: normal;
                  text-align: left;
                  color: $base-black-color;;
              
                  .delete-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    .triangle-icon {
                      margin-top: 5px;
                    }
                    .question {
                      margin-left: 10px;
                    }
                  }
                  .delete-line {
                    width: 320px;
                    height: 1px;
                    margin-top: 7.3px;
                    margin-bottom: 7.7px;
                    background-color: $base-border-color;
                  }
                  .delete-content {
                    margin-bottom: 8.6px;
                    .message {
                      width: 319px;
                      height: 100px;
                      .delete-text {
                        text-decoration: underline;
                      }
                    }
                  }
                  .delete-action {
                    @include flexEnd;
                    .cancel-button {
                      margin: 4px 10px 4px 0;
                      text-align: center;
                      border: none;
                      background-color: transparent;
                    }
                    .yes-button {
                      width: 68px;
                      height: 28px;
                      padding: 4px 22px 4px 22px;
                      border-radius: 4px;
                      border: solid 2px #b00720;
                      background-color: #b00020;
                      .text {
                        color: #fff;
                      }
                    }
                  }
                }
              
                .delete-confirm::after {
                  content: "";
                  width: 18px;
                  height: 18px;
                  background: #fef9e7;
                  position: absolute;
                  right: 9%;
                  top: -10px;
                  transform: rotate(45deg);
                  border-top: 1px solid #f1c21b;
                  border-left: 1px solid #f1c21b;
                }
              }

              .actions-manage {
                margin-left: 12.8px;
              }

              .edit-icon {
                width: 17.4px;
                height: 17.4px;
                cursor: pointer;
                display: block;
                margin-top: 10px;
              }

              .show-action {
                width: 32px;
                height: 26px;
                background: $base-white-color;
                border-radius: 2px;
                box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%);
                display: block;
                margin-top: 3px;
                .icon {
                  @include baseFLex;
                  min-width: 30px;
                  height: 30px;
                  flex-direction: inherit;
                  margin-left: -3px;
                  margin-top: -3px;
                  span {
                    display: flex;
                    flex-direction: row;
                    width: 5px;
                    height: 5px;
                    background-color: $light-blue-color;
                    border-radius: 6px;
                    margin: 0 3px 0 0;
                  }
                }
                .not-show-menu {
                  span {
                    background-color: $base-grey-color;
                  }
                }
              }

              .show-menu {
                color: #007bc7;
              }

              .close-menu {
                color: #566774;
              }

              .manage-form-dropdown-menu {
                right: -14px;
                margin-top: 10px;
                width: 255.5px;
                // height: 355px;
                box-shadow: 0 1px 8px 0 rgb(0 0 0 / 13%);
                background-color: $base-white-color;
              }

              .manage-form-menu {
                width: 22px;
              }

              // .custom-manage-form-dropdown-menu {
              //   height: 385px;
              // }
              .manage-form-dropdown-menu::after {
                content: "";
                width: 14px;
                height: 14px;
                background: $base-white-color;
                position: absolute;
                top: -8px;
                transform: rotate(45deg);
                border-top: 1px solid $grey-bold-color;
                border-left: 1px solid $grey-bold-color;
                left: 84%;
              }

              .manage-form-li {
                font-size: 14px;
                color: #454f5b;
                font-weight: 500;
                line-height: 1.5;
                letter-spacing: normal;
                margin-left: 16px;
                margin-top: 7px;
              }

              .manage-from-version-compare-img {
                width: 15px;
                height: 15px;
                margin-right: 6px;
                margin-top: -2px;
              }

              .download-version-img {
                width: 13px;
                margin-right: 8px;
              }
              .replace-pdf-img {
                width: 30px;
                height: 30px;
                margin-right: 0;
                margin-left: -9px;
                margin-top: -10px;
              }
              .sync-drc-img {
                width: 14px;
                height: 14px;
              }
              .node-list-img {
                width: 13px;
                height: 14px;
              }
              .schema-img {
                width: 12px;
                height: 15px;
              }
              .codebook-img {
                width: 13px;
                height: 14px;
                margin-right: 5px;
              }
              .download-all-version-img {
                font-size: 12px;
                margin-left: 2px;
                margin-right: 4px;
                color: #25a7e0;
              }
              .preview-img {
                width: 17px;
                height: 14px;
                margin-right: 4px;
              }
              .flowchart-img {
                width: 17px;
                height: 14px;
                margin-right: 4px;
              }
              .download-img {
                width: 13px;
                height: 14px;
                margin-right: 7px;
              }
            }
            .drc-manage-form-td-actions {
              padding: 12px 17px 29px 3px;
              width: 91px;
            }
            .manage-form-status-icon {
              width: 22px;
              height: 22px;
            }
            .active-confirm {
              left: -120px;
            }
          }

        }

        .sorter {
          display: flex;
          flex-direction: column;
          margin-left: 4px;
          cursor: pointer;
          position: relative;

          .up {
            position: absolute;
            color: $base-grey-medium-color;
          }

          .up.active {
            color: $base-blue-light-color;
          }

          .down {
            color: $base-grey-medium-color;
          }

          .down.active {
            color: $base-blue-light-color;
          }
        }

        .sorter.active .up,
        .sorter.active .down {
          color: $base-blue-light-color  !important;
        }

        .sorting-column {
          @include baseFLex;
        }
      }

      .manage-deleted-form-version-history {
        padding-bottom: 41px;
        .manage-deleted-form-line {
          width: $tableWidth;
          height: 1px;
          margin: 16px 3.5px 0 0.5px;
          border: solid 1px $base-grey-light-color;
        }

        .manage-deleted-form-version-title {
          display: flex;
          justify-content: left;
          align-items: center;
          height: 55px;
          .version-history {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
            color: #0a4b6d;
            margin-right: 17px;
          }

        }

        .manage-deleted-form-version-table {
          padding-bottom: 8px;
  
          .manage-deleted-form-table {
  
            width: $tableWidth;
            overflow-x: auto;
            height: 100px;
  
            .manage-deleted-form-thead {
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.29;
              letter-spacing: 1.02px;
              color: #131523;
              box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.23);
              background-color: #ebf5fa;
  
              .manage-deleted-form-th-current-version {
                padding: 15px 103px 18px 8px;
                width: 150px;
              }
  
              .manage-deleted-form-th-previous-version {
                padding: 14px 37px 14px 9px;
                width: 153px;
              }
  
              .manage-deleted-form-th-creation {
                padding: 1px 73px 21px 37px;
                width: 120px;
              }

              .manage-deleted-form-th-creator {
                padding: 1px 133px 21px 56px;
                width: 120px;
              }
  
              .manage-deleted-form-th-actions {
                padding: 15px 20px 34px 0px;
                width: 91px;
                text-align: right;
                }
            }
  
            .manage-deleted-form-tr {
              border: solid 1px #ebcccc;
              background-color: #fff6f6;
            }
  
            .manage-deleted-form-tbody {
              font-family: 'Poppins',
              sans-serif;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.56;
              letter-spacing: 0.23px;
              text-align: left;
              color: $base-black-color;
              margin-left: -1px;
  
              .manage-deleted-form-td-current-version {
                padding: 12px 122px 18px 8px;
                width: 150px;
  
                .current-version-versionId {
                  display: inline-block;
                  max-width: 50px;
                  @include truncateText;
                }
  
                .current-version-id {
                  margin-left: 2px;
                  display: inline-block;
                  max-width: 55px;
                  @include truncateText;
                }
              }
  
              .manage-deleted-form-td-previous-version {
                padding: 12px 17px 18px 8px;
                width: 153px;
  
                .previous-version-versionId {
                  display: inline-block;
                  max-width: 125px;
                  @include truncateText;
                }
              }
  
              .manage-deleted-form-td-creation {
                padding: 12px 17px 4px 52px;
                width: 120px;
              }

              .manage-deleted-form-td-creator {
                padding: 12px 17px 24px 58px;
                width: 120px;
                color: $base-line-blue;
                max-width: 120px;
                .creator-id {
                    text-decoration: underline;
                    @include truncateText;
                  }
              }
   
              .manage-deleted-form-td-actions {
                padding: 12px 17px 29px 29px;
                width: 91px;
              }
            }
  
          }
  
  
        }

        .manage-deleted-form-version-view-more {
          display: flex;
          justify-content: right;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.13;
          letter-spacing: normal;
          color: $base-line-blue;
          .text {
            text-decoration: underline;
          }
        }

      }

    }
  }

  /* CUSTOM SCROLL-BAR */
  ::-webkit-scrollbar {
    /* width */
    width: 8px;
    max-height: 8px;
    scroll-margin-left: 10px;
  }

  ::-webkit-scrollbar-track {
    /* Track */
    border-radius: 0px;
    background-color: $base-grey-light-color;
  }

  ::-webkit-scrollbar-thumb {
    /* Handle */
    border-radius: 3px;
    background-color: $base-grey-medium-color;
  }

  .dropdown__option__pagination {
    top: unset;
    margin-left: 6px;
    margin-top: -102px;
  }

}


  /* TOOLTIP */
 .manage-deleted-form-restore {
   position: relative;
  }

 .restore-icon:hover ~ .restore-tooltip {
  @include baseTooltip;
  display: flex;
  justify-content: center;
  align-items: center;
}

.restore-tooltip {
  position: absolute;
  display: none;
  width: 130px;
  height: 26px;
  left: -50px;

  padding: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px $grey-bold-color;
  background-color: $grey-medium-color;

  font-family: $font-family;
  font-size: $base-font-size;
  font-weight: 500;
  line-height: 2.43;
  text-align: left;
  color: $base-black-color;

}

.restore-tooltip::after {
  @include tooltipArrow;
  top: -8px;
  left: 48px;
  width: 14px;
  height: 14px;
  border-right: 0;
  border-bottom: 0;
  border-top: 1px solid $grey-bold-color;
  border-left: 1px solid $grey-bold-color;
}
