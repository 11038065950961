$white-color: #fff;
$border-color: #e4e4e4;
$form-title-color: #667081;
$base-bg: #f4f6f8;
$base-blue-color: #003e65;
$base-dark-color: #212b36;
$shadow-color: rgba(0, 0, 0, 0.16);

@mixin fontBase {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

@mixin scrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #dfe3e8;
    border-radius: 2.5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background-color: #919eab;
    background-clip: content-box;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #919eab;
  }
}

.form__entry {
  display: none;
  position: fixed;
  top: 64px;
  right: 0;
  height: calc(100vh - 64px);
  width: 48%;
  box-shadow: 0 3px 6px 0 $shadow-color;
  border: solid 1px $border-color;
  background-color: $white-color;
  z-index: 1;

  /* CONTAINER */
  .form__entry__container {
    margin: 16px 24px 16px 16px;
    @include scrollbar;

    /* HEADER */
    .form__entry__header {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      .form__entry__container__title {
        @include fontBase;
        font-size: 22px;
        line-height: normal;
        color: $form-title-color;
      }

      .form__entry__container__subtitle {
        @include fontBase;
        line-height: normal;
        color: $form-title-color;
      }

      .form__entry__header__close__form__entry {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }

    /* CONTENT */
    .form__entry__list {
      height: calc(100vh - 176px);
      overflow-y: auto;
      .panel-group {
        margin-bottom: 0;
        padding-right: 8px;
        .form__entry__item {
          margin-top: 0;
          margin-bottom: 8px;
          border-radius: 4px;
          border-bottom: 0;
          cursor: pointer;
          .form__entry__content__img__toggle {
            width: 10px;
            height: 16px;
          }
          .panel-title {
            .accordion-toggle {
              text-decoration: none;
            }
          }
          .panel-heading {
            width: 100%;
            min-height: 60px;
            max-height: 80px;
            padding: 10px 16px;
            box-shadow: 0 1px 3px 0 $shadow-color;
            background-color: $base-bg;

            .form__entry__content__title {
              @include fontBase;
              font-weight: 600;
              line-height: 1.71;
              color: $base-blue-color;

              .long__name {
                position: relative;

                .long__name__popup {
                  @include fontBase;
                  display: none;
                  position: absolute;
                  top: 20px;
                  max-width: 420px;
                  min-width: 360px;
                  word-wrap: break-word;
                  padding: 4px 18px;
                  box-shadow: 0 0 4px 0 $shadow-color;
                  border: solid 1px #d9e0e8;
                  background-color: $base-bg;
                  color: $base-dark-color;
                  z-index: 1;
                }
              }

              .long__name:hover > .long__name__popup {
                display: block;
              }
            }
            .page__id {
              @include fontBase;
              line-height: 1.71;
              letter-spacing: normal;
              color: $base-blue-color;
            }
          }

          @media (max-width: 1400px) {
            .panel-heading {
              max-height: initial;
            }
          }

          .form__entry__content__selected {
            @include fontBase;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: normal;
            margin-top: 2px;
            .form__entry__content__selected__text {
              color: #4caf50;
            }
            .form__entry__content__selected__answer {
              color: $base-dark-color;

              .form__entry__content__selected__answer__second__row {
                margin-top: 2px;
              }
            }
            .form__entry__content__selected__answer.line__height {
              line-height: 1.6;
            }
            .form__entry__content__not__selected__answer {
                color: $form-title-color;
            }
            .form__entry__content__view_more,
            .form__entry__content__view_less {
              @include fontBase;
              font-size: 12px;
              color: #0098db;
            }
          }
          .panel-body {
            max-height: 200px;
            padding: 16px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border: solid 1px #dfe3e8;
            cursor: default;
            overflow-y: auto;

            .form__entry__content {
              .question {
                @include fontBase;
                line-height: 1.57;
                color: $base-blue-color;

                .question__q {
                  @include fontBase;
                  font-size: 22px;
                  font-weight: bold;
                  line-height: 1;
                  color: #00578c;
                  margin-right: 4px;
                }
              }
              .possible__answers {
                @include fontBase;
                font-weight: 600;
                line-height: 1.71;
                color: $base-dark-color;
              }
              .answers {
                @include fontBase;
                line-height: 1.71;
                margin-top: 13px;
                color: $base-dark-color;
              }
            }
          }
        }
      }
      .form__entry__item.panel-open {
        .form__entry__content__img__toggle {
          transform: rotate(90deg);
        }
      }
      .form__entry__list__no__data {
        @include fontBase;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $base-dark-color;
      }
    }
  }
}

.form__entry.show {
  display: block;
}
