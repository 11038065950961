﻿@import "common";

.dropdown-pagination {
  font-family: $font-family;
  width: 50px;
  height: 27px;
  margin: 0 8px;
  padding: 2px 6px 3px;
  border-radius: 4px;
  border: solid 1px $light-grey-color;
  background-color: $base-white-color;
  text-align: left;
}

.dropdown__option__pagination {
  font-family: $font-family;
  position: absolute;
  display: none;
  z-index: 1000;
  float: right;
  width: 50px;
  padding: 5.3px 8px 0 8px;
  font-size: $base-font-size;
  text-align: right;
  background-color: $base-white-color;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.13);
  top: -66px;
  list-style: none;
  margin-left: 10px;
}

.dropdown__option__pagination:before {
  content: "";
  position: absolute;
  top: 66px;
  left: 0;
  width: 50px;
  height: 38px;
}

.dropdown__option__pagination:after {
  content: "";
  z-index: -99;
  position: absolute;
  width: 15px;
  height: 13px;
  bottom: -10px;
  transform: rotate(-135deg);
  background: $base-white-color;
  border-top: 1px solid $grey-bold-color;
  border-left: 1px solid $grey-bold-color;
  transform-origin: 0 0;
  top: 76px;
  right: 0;
}

.active-page-option {
  border-radius: 5px;
  background-color: #0098db;
  color: #fff;
  min-width: 36px;
}

.pagination-down-icon {
  position: absolute;
  width: 18px;
  height: 18px;
}

.pagination-selected {
  width: 10px;
  height: 8px;
  position: absolute;
  margin-left: -12px;
  margin-top: 7px;
}
