//TODO: figure out the correct order of these and convert properly to full SASS
/*
* all I did was either convert our files from fileName.css to _fileName.scss or in the case of
* jslib files I copied the css into a new file named for sass
* this should be revisited to properly convert this over to sass
*/


@import 'scss/_mixins.scss';

$icon-font-path: '../../../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';

@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

@import '../../../../node_modules/shared_utilities/dist/styles.min.css';

@import '../../src/styles/formIcons/flaticon.css';

@import 'scss/fonts';

@import '../jslib/angular-wizard/_angular-wizard.scss';

@import '../jslib/angular-ui-tree/_angular-ui-tree.scss';

@import 'scss/cron-generator';

@import 'scss/main';

@import 'scss/sizing';

@import 'scss/tiles';

@import 'scss/acadiaCommon';

@import 'scss/angularTree';

@import 'scss/navBar';

@import 'scss/programs';

@import 'scss/progressBars';

@import 'scss/stringManagement';

@import 'scss/searchFilters';

@import 'scss/deepLinks';

@import 'scss/tables';

@import 'scss/modal';

@import 'scss/animations';

@import 'scss/form.display';

@import './formIcons/_flaticon.scss';

@import 'scss/bootstrap.custom';

@import 'scss/templateConfig';

@import "scss/custom.flowchart";

@import 'scss/print';

@import 'scss/flex';

@import 'scss/form';

@import 'scss/formTable';

@import 'scss/manageForm';

@import 'scss/pagination';

@import 'scss/preview';

@import 'scss/formEntryPreview';

@import 'scss/profileValuePreview';

@import 'scss/formNavigation';

@import 'scss/tooltip';

@import '../jslib/vibrent-sass/scss/base/variables';
@import '../jslib/vibrent-sass/scss/base/mixins';
@import '../jslib/vibrent-sass/scss/components/button';
@import 'scss/buttons';

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('./styles/fonts/glyphicons-halflings-regular.eot');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('./styles/fonts/glyphicons-halflings-regular.svg');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('./styles/fonts/glyphicons-halflings-regular.ttf');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('./styles/fonts/glyphicons-halflings-regular.woff');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('./styles/fonts/glyphicons-halflings-regular.woff2');
}
