@import "common";
/* VARIABLE */
$base-padding: 16px;
$base-margin: 16px;
$drop-down-width: 210px;
$drop-down-height: 46px;
$white-color: #FFF;
$base-filter-color: #007bc7;
$base-import-color: #0098db;
$base-pagination-color: #566774;

/* MIXIN */
@mixin customDropDownItem {
    width: $drop-down-width;
    padding: 10.2px 20.4px;
    background-color: #fff;
    border-width: 0;
    margin-top: $base-margin;
    filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.16));
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.16);

    li {
        display: flex;
        align-items: center;
        margin-top: 11px;

        input {
            padding-left: $base-padding;
            width: 18px;
            height: 21px;
            margin-top: 0;
            cursor: pointer;
        }

        .dropdown__menu__text {
            margin-left: 8px;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 500;
            color: #212b36;
        }

        input:checked~.dropdown__menu__text {
            font-weight: 600;
        }
    }
}

@mixin customDropDownProgram {
    width: 633px;
    height: 230.2px;
    padding: 10.2px 0;
    margin-left: -212px;
    background-color: #fff;
    border-width: 0;
    margin-top: $base-margin;
    filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.16));
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.16);

    .btn__search__icon {
        border-left: 0;
    }

    .radio-filter {
        input {
            padding-left: $base-padding;
            width: 18px;
            height: 21px;
            cursor: pointer;
        }
        label {
            margin-left: 4px;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 500;
            color: #212b36;
            vertical-align: bottom;
        }
    }

    .program-heading {
        font-family: 'Poppins', sans-serif;
        margin-bottom: 11px;
        font-weight: 600;
    }

    li {
        display: flex;
        align-items: center;
        margin-top: 11px;

        input {
            padding-left: $base-padding;
            width: 18px;
            height: 21px;
            margin-top: 0;
            cursor: pointer;
        }

        .dropdown__menu__text {
            margin-left: 8px;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 500;
            color: #212b36;

            // for long text
            display: block;
            display: -webkit-box;
            line-height: 16px;
            max-height: 20px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            visibility: visible;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100px;
        }

        input:checked~.dropdown__menu__text {
            font-weight: 600;
        }
    }

    .form__filter--sorter__by-programs__last-li {
        display: flex;
        justify-content: end;
        position: absolute;
        bottom: 10px;
    }

    .form__filter--sorter__by-programs__view-more {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #0098db;
        margin-top: 8px;
        cursor: pointer;
    }

    .search-group {
        position: relative;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;

        .search-program {
            border: solid 1px #dfe3e8;
        }

        input[type="text"] {
            height: 42px;
        }

        i {
            position: absolute;
            right: 16px;
            top: 10px;
            font-size: 21px;
            color: rgba(0, 0, 0, 0.4);
        }
    }

    .dropdown__menu__items {
        max-height: 130px;
        overflow-y: auto;
    }
}

@mixin customDropDownImport {
    filter: drop-shadow(0 0px 4px rgba(0, 0, 0, 0.13));
    border-width: 0;
    background-color: #fff;
    height: 165px;
    padding: 9px;
    margin-top: 12px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.13);

    li {
        cursor: pointer;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #212b36;
        padding: 8px 0 0;
    }
}

@mixin arrowUp {
    content: "";
    z-index: -99;
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    top: 0;
    box-sizing: border-box;
    border: 5px solid $white-color;
    border-color: transparent transparent $white-color $white-color;
    transform-origin: 0 0;
    transform: rotate(135deg);
    background: $white-color;
}

@mixin customBtnImport {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    border-radius: 4px;
    padding: 8px 10px;
    border: solid 2px #0098db;
}

@mixin customBtnImportText {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $white-color;
    margin-right: 4px;
}

@mixin paginationButton {
    font-size: 20px;
    line-height: 2.14;
    // color: #919eab;
    color: #1899d8;
    font-weight: 600;
}

@mixin paginationPage {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: $base-pagination-color;
}

@mixin paginationButtonParent {
    color: $white-color;
    width: 23px;
    height: 23px;
    border-radius: 3px;
    background-color: #1899d8;

    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 20px;
    }
}

/* FORM V2 */
.form-version-two-title {
    padding-left: 16px;
    margin-top: -3px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #0a4b6d;
    display: flex;
    justify-content: flex-end;

}

/* FORM FILTER*/
.form.form-filter {
    background-color: $white-color;
    border: solid 1px #ddd;
    padding: 12px 14px 16px $base-padding  !important;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    height: calc(100vh - 144px);

    .info-banner {
        display: inline-block;
        width: fit-content;
        margin-top: 15px;
        margin-bottom: 0px;
        padding: 8px;
        border-radius: 4px;
        border: solid 1px #006fb3;
    }

    .form__filter {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 46px;

        .form__filter--sorter__icon {
            display: flex;
            align-items: center;

            img {
                width: 29px;
                height: 25px;
            }
        }

        .form__filter--sorter {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            max-width: 699px
        }

        .form__filter__dropdown--category,
        .form__filter__dropdown--program,
        .form__filter__dropdown--days {
            @include customDropDownButton;
        }

        .dropdown__menu__days__item::before,
        .dropdown__menu__program__item::before,
        .dropdown__menu__category__item::before {
            content: '';
            position: absolute;
            top: -16px;
            left: 0;
            width: $drop-down-width;
            height: 16px;
        }

        .dropdown__menu__program__item::before {
            left: 215px;
        }

        .dropdown__menu__category__item,
        .dropdown__menu__days__item {
            @include customDropDownItem;
        }

        .dropdown__menu__program__item {
            @include customDropDownProgram;
        }

        .form__filter--reset button {
            width: 77px;
            height: 46px;
            padding: 13px 19px;
            border-radius: 4px;
            border: solid 1px #e9e9ea;
            background-color: #fff;

            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: normal;
            color: #1898db;
            opacity: 0.7;
        }

        .form__filter--reset.isFillter button {
            border: solid 1px #1898db;
            opacity: 1;
        }

        .formListV2__dropdown__txt {
            font-family: $font-family;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $base-light-blue-color;
            @include truncateText;
        }
    }

    .form__table__filter {
        flex: 1;
        overflow: hidden;
    }

    .dropdown__menu__category__item::after,
    .dropdown__menu__days__item::after {
        right: -4px;
        @include arrowUp;
    }

    .dropdown__menu__program__item::after {
        right: 44%;
        @include arrowUp;
    }

    .btn-dropdown {
        padding: 6px 16px;
    }

    input[type=checkbox].checkbox-filter {
        // accent-color: #003e65;

        height: 18px;
        width: 18px;
        vertical-align: middle;
        border-radius: 4px;
        border: 2px solid #c4cdd5;
        outline: none;
        -webkit-appearance: none;
        -webkit-transition: box-shadow 200ms;
    }

    input[type=checkbox].checkbox-filter:checked {
        border-color: #003e65;
        background-color: #003e65;
    }

    input[type=checkbox].checkbox-filter:checked::after {
        content: "";
        display: block;
        width: 8px;
        height: 14px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-left: -12px;
        margin-top: 0px;
    }
}

/* FORM SEARCH - PAGINATION */
.form__filter--search {
    position: relative;
    display: flex;

    .form__filter--search__navigate {
        margin-right: 82.14px;
    }

    .form__filter--search__navigate.manage-version {
        margin-right: 425px;
    }

    /* FROM INPUT*/
    .form__filter--search__input {
        position: absolute;
        right: 12px;

        input[type=text]#search {
            width: 0;
            float: right;
            height: 46px;
            transition: width 0.2s linear;
            z-index: -1;
            margin-right: -30px;
            border: solid 1px #dfe3e8;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            -webkit-transition: width 0.2s linear;
        }

        input[type=text]#search:focus {
            box-shadow: inset 0 0 0 #ddd;
            outline: 0 none;
        }

        ::placeholder {
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 500;
            color: #7e84a3;
        }

        span.search-btn {
            width: 82px;
            height: 46px;
            border-radius: 4px;
            border: solid 1.5px #dfe3e8;
            background-color: $white-color;
            padding: 0 6px;

            .search-text {
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                color: $white-color;
            }

            .search-icon {
                color: $white-color;
            }
        }

        .input-group {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
        }

        .input-group>input[type=text]#search {
            width: 480px;
            z-index: 1;
            margin-right: -2px;
        }

        .input-group>span.search-btn {
            background-color: $base-filter-color;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .input-group>span.search-btn>span>.search-text,
        .input-group>span.search-btn>span>.search-icon {
            color: $white-color;
        }
    }

    /* FORM PAGINATION */
    .form__pagination {
        height: 46px;
        border-radius: 4px;
        border: solid 1px #dfe3e8;
        background-color: $white-color;
        margin-right: 16px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 8px;

        // position: absolute;
        // right: 110px;

        .form__pagination__first {
            @include paginationButtonParent;
            margin: 0 8px 0 0;
        }

        .form__pagination__last {
            @include paginationButtonParent;
            margin: 0 8px;
        }

        .form__pagination__first i {
            margin-right: 2px;
        }

        .form__pagination__last i {
            margin-left: 2px;
        }

        .form__pagination__previous i {
            @include paginationButton;
            margin: 0 4px 0 0;
        }

        .form__pagination__next i {
            @include paginationButton;
            margin: 0 4px 0;
        }

        .form__pagination__previous.able_to_click,
        .form__pagination__next.able_to_click,
        .form__pagination__first.able_to_click,
        .form__pagination__last.able_to_click {
            pointer-events: none;
            i.previous,
            i.next {
                color: #919eab !important;
            }
        }

        .form__pagination__page {
            margin: 2px 0px 0;
            min-width: 23px;
            @include paginationPage;
        }

        .form__pagination__page__dot,
        .form__pagination__page__total {
            margin: 2px 8px 0;
            @include paginationPage;
        }

        .form__pagination__page.active {
            color: #006fb3;
            font-weight: 600;
            position: relative;
        }

        .form__pagination__page.active::before {
            content: "";
            position: absolute;
            height: 2px;
            background-color: #007bc7;
            // border: solid 2px #007bc7;
            bottom: -11px;

            left: 0px;
            right: 0px;
            margin: 0px auto;
        }

        .form__pagination__page__jump__to {
            span {
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                color: $base-pagination-color;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input {
                width: 41px;
                height: 27px;
                border-radius: 4px;
                border: solid 1px #c4cdd5;
                background-color: $white-color;
                outline: none;
                padding: 4px;
                font-family: 'Poppins', sans-serif;
                color: $base-pagination-color;
                -moz-appearance: textfield;
            }
        }

        .form__pagination__page__select__size {
            select {
                margin-left: 8px;
                min-width: 41px;
                height: 27px;
                padding: 2px 0 2px 2px;
                border-radius: 4px;
                border: solid 1px #c4cdd5;
                background-color: $white-color;
                outline: none;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                color: $base-pagination-color;
            }

            option {
                cursor: pointer;
                width: 300px;
                min-height: 60px;
                max-height: 300px;
                border-radius: 15px;
                background-color: rgb(250, 250, 250);
                box-shadow: 2px 4px 8px #c5c5c5;
                transition: all 300ms;
            }
        }

        .form__pagination__page__total_records {
            margin-left: 12px;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            color: $base-pagination-color;

            span {
                color: #006fb3;
            }
        }
    }
}

/* IMPORT EXPORT */
.function-group {
    display: flex;

    .btn.export__deleted__form {
        @include customBtnImport;
        background-color: #fafafb;
        margin-right: 8px;

        .export__deleted__form__text {
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: #1898db;
        }

        .export__deleted__form__icon img {
            width: 20px;
            height: 16px;
            margin: -3px 4px 0 0;
        }
    }

    .btn.formV2__help {
        margin-right: 16px;
        .export__deleted__form__icon img {
            height: 20px;
            margin: -2px 3px 0 -5px;
        }
    }

    .btn.formV2__only__help__btn {
        margin-right: 0;
    }

    .btn.import__form__file {
        @include customBtnImport;
        background-color: $base-import-color;
        margin-right: 8px;

        .import__form__file__text {
            @include customBtnImportText;
        }

        .import__form__file__icon img {
            width: 18px;
            height: 22px;
            margin: 0px 4px 0 0;
        }

        .caret {
            color: $white-color;
            width: 9px;
            border-right: 4.5px solid transparent;
            border-left: 4.5px solid transparent;
            border-top: 4.5px dashed;
        }
    }

    ul.import__form__file_dropdown {
        @include customDropDownImport;
        width: 170.67px;
    }

    ul.import__form__file_dropdown::after {
        @include arrowUp;
        top: 3px;
        right: -10px;
    }

    ul.import__form__file_dropdown::before {
        content: '';
        position: absolute;
        top: -16px;
        left: 0;
        width: 170.67px;
        height: 16px;
    }

    .btn.create__new__form {
        @include customBtnImport;
        background-color: $base-import-color;

        .create__new__form__text {
            @include customBtnImportText;
        }

        .create__new__form__icon img {
            width: 18px;
            height: 16px;
            margin: -2px 4px 0 0;
        }

        .caret {
            color: $white-color;
            width: 9px;
            border-right: 4.5px solid transparent;
            border-left: 4.5px solid transparent;
            border-top: 4.5px dashed;
        }
    }

    .dropdown_create__new__form:hover .dropdown-menu.create__new__form__dropdown {
        display: block;
    }

    .dropdown_create__new__form:not(:hover) .dropdown-menu.create__new__form__dropdown {
        display: none;
    }

    ul.create__new__form__dropdown {
        @include customDropDownImport;
        width: 180.7px;
    }

    ul.create__new__form__dropdown::after {
        @include arrowUp;
        top: 3px;
        right: -10px;
    }

    ul.create__new__form__dropdown::before {
        content: '';
        position: absolute;
        top: -16px;
        left: 0;
        width: 170.67px;
        height: 16px;
    }
}

/* OTHER */
.mr-16 {
    margin-right: $base-padding;
}

.ml-8 {
    margin-left: calc($base-padding / 2);
}

.program-empty,
.program-error {
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.manage-form-contain {
  margin-top: -7.2rem;
}
.selected-form__dashboard {
  margin-top: 7.2rem;
}

.program-selection-dropdown{
    .btn-dropdown {
        padding: 6px 16px;
    }
    .program-dropdown{
        @include customDropDownButton;
    }

    .dropdown-txt {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: $base-light-blue-color;
        @include truncateText;
    }

    .dropdown-menu-program::before {
        content: '';
        position: absolute;
        top: -16px;
        left: 0px;
        width: $drop-down-width;
        height: 16px;
    }

    .dropdown-menu-program {
        @include customDropDownProgram;
        width:389px;
        margin-left: 0px;

        .program-list{
            margin-bottom: 11px;
            font-weight: 600;
        }
    }

    .dropdown-menu-program::after {
        right: 330px;
        @include arrowUp;
    }

}


@media only screen and (max-width: 1400px){
    .form.form-filter {
        .form__filter {
            .form__filter__dropdown--category,
            .form__filter__dropdown--program {
                width: 155px;
                padding: 6px 8px !important;
            }

            .form__filter__dropdown--days {
                width: 145px;
                padding: 6px 8px !important;
            }

            .dropdown__menu__category__item {
                width: 155px;
            }

            .dropdown__menu__days__item {
                width: 145px !important;
            }
            .dropdown__menu__days__item::after {
                right: 4px;
            }
            .dropdown__menu__program__item::after {
                right: 49%;
            }
        }
    }

    .program-selection-dropdown {
        .program-dropdown{
            width: 155px;
            padding: 6px 8px !important;
        }
    }
}
