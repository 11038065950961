/*
---
name: Flex Helpers
category: helpers/flex
---
* All flex helpers must begin with `flex`
* Flex helpers are used to add flex properties easily in the DOM.
* Uses `.flex-{flex-command}-{flex position}` tag.
* flex-commands: `justify,align,align-self,justify-self`
* flex-posistions: `start,end,center,around,between`
* ex: `.flex-align-center`
```html
<div class="card flex flex-justify-between">
    <button class="vbr-btn">Hey</button>
    <button class="vbr-btn-clear">Bye</button>
</div>
<div class="card">More Content</div>
```
* Addiitonal flex helpers
* `flex-col` which is equivlent to `flex-direction:column`.
* `flex-wrap` which is equivlent to `flex-wrap:wrap`.
 */

$flex-directions: (start: flex-start, end: flex-end, center: center, around: space-around, between: space-between);

@each $direction, $d in $flex-directions {
  .flex-justify-#{$direction} {
    justify-content: $d;
  }

  .flex-align-#{$direction} {
    align-items: $d;
  }

  .flex-align-self-#{$direction} {
    align-self: $d;
  }

  .flex-justify-self-#{$direction} {
    justify-self: $d;
  }
}

.flex {
  display: flex;
  /* Notes */
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

$flex-growths: 1, 2, 3;

@each $growth in $flex-growths {
  .flex-grow-#{$growth} {
    flex-grow: $growth;
  }
}

// FLEXBOX MIXINS
// --------------------------------------------------
// http://philipwalton.github.io/solved-by-flexbox/
// https://github.com/philipwalton/solved-by-flexbox

@mixin display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin disabledValue($value: disabled) {
  @if $value == disabled {
    label {
      color: $brownish-grey;
    }

    input {
      border: 1px solid $white-four;
      background-color: $white-two;
      color: $black;
    }
  }
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  }
  @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }
  @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  }
  @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }

  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
