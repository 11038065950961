/**
 * Created by Jamie Nola on 01/24/2019
 */

.program–export-dialog {
  max-height: 700px;
  overflow-y: auto
}

.search {
  width: 100%;

  .clear-search {
    border-left: none;
    width: 1px;
  }

  .execute-search {
    width: 1px;
  }
}

@media only screen and (max-width: 991px){
  .hide-btn{
    display: none;
  }
}


.div-disabled
{
  pointer-events: none;
  opacity: 0.5;
  background: #CCC;
}