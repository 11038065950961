body {
  @include normal-text;

  background: #fafafa;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: 500;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.development {
  position: absolute;
  width: 149px;
  height: 149px;
  top: 0;
  left: 0;
  border: 0;
  z-index: 1000;
  background-image: url('../../assets/images/development_ribbon.png');
  background-position: center center;
  background-repeat: no-repeat;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc;
}

/* ==========================================================================
spacing in navbar
========================================================================== */
.pagination {
  &.no-margin {
    margin: 0;
  }
}

/* ==========================================================================
icons in navbar
========================================================================== */
.dropdown-menu .dropdown-menu-icon {
  text-align: center;
  min-width: 18px;
  margin-right: 5px;

  &.active {
    pointer-events: none;
  }
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px;
}

.hero-unit h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
}

.error {
  color: white;
  background-color: red;
}

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url('../../assets/images/hipster.png') no-repeat center top;
  background-size: contain;
}

.pad {
  padding: 10px;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url('../../assets/images/hipster2x.png') no-repeat center top;
    background-size: contain;
  }
}

.hand {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed
}

#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

.alert .popover pre {
  font-size: 10px;
}

/*Custom alerts for notification*/
.alerts .alert {
  text-overflow: ellipsis;
}

.alert pre {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
}

.voffset {
  margin-top: 2px;
}

.voffset1 {
  margin-top: 5px;
}

.voffset2 {
  margin-top: 10px;
}

.voffset3 {
  margin-top: 15px;
}

.voffset4 {
  margin-top: 30px;
}

.voffset5 {
  margin-top: 40px;
}

.voffset6 {
  margin-top: 60px;
}

.voffset7 {
  margin-top: 80px;
}

.voffset8 {
  margin-top: 100px;
}

.voffset9 {
  margin-top: 150px;
}

/* start Password strength bar style */
ul#strengthBar {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px;
}

.point:last {
  margin: 0 !important;
}

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;
}

/* end Password strength bar style */

.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
Acadia Custom CSS Modification
========================================================================== */
.acadia-main-background {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url('../../assets/images/acadia.jpg') no-repeat center top;
  background: url('../../assets/images/acadia.jpg') no-repeat center top;
  background-size: contain;
}

/* ==========================================================================
Error tree node
========================================================================== */
.angular-ui-tree-node-error {
  background-color: #ff6b6b !important;
}

.pmi-dashboard {
  border-left: solid grey 2px;
}

/* ==========================================================================
common classes
========================================================================== */
.white-space-pre {
  white-space: pre;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.break-word-all {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;

  /* This is the dangerous one in WebKit, as it breaks things wherever */
  /*word-break: break-all;*/
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.form-text-overflow {
    overflow-y: auto;
}

.form-text-overflow::-webkit-scrollbar-button {
    height: 100%; //for vertical scrollbar
  }

.overflow-wrap {
  overflow-wrap: break-word;
}

.indent-wrap-1em {
  padding-left: 1em;
  text-indent: -1em;

  * {
    text-indent: 0;
  }
}

.text-disabled {
  color: #B0B0B0;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.link-disabled {
  color: #B0B0B0;
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-table {
  display: table;
}

.display-table-row {
  display: table-row;
}

.display-table-cell {
  display: table-cell;
}

.add-box-shadow-small {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5), 0 4px 14px 0 rgba(0, 0, 0, 0.5);
}

.breadcrumb-container {
  overflow: hidden;
}

.breadcrumb-list {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #999;
  margin-top: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.breadcrumb-spacer {
  padding: 0 3px;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

select:disabled {
  opacity: 0.5;
}

.yellow-background{
  background-color: yellow;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
