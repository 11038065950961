/*
 * Created by Jamie Nola on 12/10/2018
 */

$string-mgmt-header-color: #d9edf7;
$string-mgmt-border-color: #999;
$string-mgmt-bg-color: #d6d6d6;
$string-mgmt-text-color: #404040;
$string-mgmt-feature-panel-bg-color: #d9edf7;
$string-mgmt-feature-bg-color: #f5f5f5;
$string-mgmt-feature-border-color: #a6bac4;
$string-mgmt-selected-bg-color: #fff1ce;
$string-mgmt-selected-border-color: #ccbe9b;
$string-mgmt-default-bg-color: lightgray;
$string-mgmt-default-border-color: gray;
$string-mgmt-default-text-color: #888;
$string-mgmt-custom-bg-color: #e2fde2;
$string-mgmt-custom-border-color: #a2d2a5;
$string-mgmt-main-bg-color: #ccc;
$string-mgmt-detail-bg-color: #f5f5f5;

.string-management {
  .main {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    background: $string-mgmt-bg-color;
    height: 720px;
    border: 1px solid $string-mgmt-border-color;
    padding-top: 10px;
    color: $string-mgmt-text-color;
  }

  .feature-panel {
    width: 35%;
    max-width: 435px;
    padding-right: 0;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .detail-panel {
    padding-left: 0;
    border-left: 1px solid $string-mgmt-border-color;
    flex: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: $string-mgmt-detail-bg-color;
  }

  .panel-header {
    background: $string-mgmt-header-color;
    padding: 5px 15px;
    margin: 0;
    border-top: 1px solid $string-mgmt-border-color;
    border-bottom: 1px solid $string-mgmt-border-color;
    font-size: 1.5em;
  }

  .feature-main, .detail-main {
    overflow-y: auto;
    margin: 10px 0 0 0;
  }

  .feature-main {
    .no-filter-results {
      margin: 8px 10px;
    }

    .feature {
      border-radius: 0;
      border: none;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &.hidden {
        display: none;
      }
    }

    .panel-group .panel + .panel {
      margin-top: 0;
    }

    .panel-heading {
      padding: 0;
    }

    .panel-group {
      margin-bottom: 0;
    }

    .panel.panel-open .panel-collapse {
      border-bottom: 1px solid $string-mgmt-feature-border-color;
    }

    .panel-collapse {
      background: $string-mgmt-feature-panel-bg-color;
    }

    .panel-body {
      border: none !important;
      padding: 8px 10px;
    }

    .feature-title {
      border-top: 1px solid $string-mgmt-feature-border-color;
      border-bottom: 1px solid $string-mgmt-feature-border-color;
      margin: 0;
      padding: 3px 10px 3px 15px;
      background: $string-mgmt-feature-bg-color;
      display: flex;
      justify-content: space-between;
      line-height: normal;

      &.custom {
        background-color: $string-mgmt-custom-bg-color;
        border-color: $string-mgmt-custom-border-color;
      }

      &.selected {
        background-color: $string-mgmt-selected-bg-color;
        border-color: $string-mgmt-selected-border-color;
      }

      .feature-title-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .feature-title-count {
        white-space: nowrap;
        padding-left: 5px;
      }
    }

    .string {
      margin-top: 8px;
      padding: 3px 5px;
      border: 1px solid $string-mgmt-feature-border-color;
      background: $string-mgmt-feature-bg-color;

      &:first-of-type {
        margin-top: 0;
      }

      &.custom {
        background-color: $string-mgmt-custom-bg-color;
        border-color: $string-mgmt-custom-border-color;
      }

      &.selected {
        background-color: $string-mgmt-selected-bg-color;
        border-color: $string-mgmt-selected-border-color;
      }

      .string-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .detail-main {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.string-management .detail-main, .string-locale-detail-table {
  .editor-title {
    margin: 0;
  }

  .panel {
    border: 1px solid $string-mgmt-border-color;

    &:not(.panel-open) {
      .panel-heading {
        border-radius: 3px;
      }
    }
  }

  .panel-heading {
    background: $string-mgmt-header-color;
    padding: 5px;
  }

  .panel-body {
    border-top: 1px solid $string-mgmt-border-color !important;
  }
}

// string input
.string-value-checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;

  .title {
    font-size: 1.1em;
    margin: 0 10px;
    line-height: normal;
  }
}

.string-value-editor-input {
  @extend .string-value-checkbox;

  .is-custom-checkbox.is-hidden {
    opacity: 0;
    pointer-events: none;
  }

  .title {
    min-width: 65px;
    max-width: 100px;
  }

  .value {
    flex: 1;

    textarea {
      min-height: 32px;
      padding: 5px;
      width: 100%;
      border-color: $string-mgmt-border-color;
      border-radius: 3px;

      &.has-label {
        border-radius: 0 3px 3px 3px;
      }

      &:disabled {
        border-color: $string-mgmt-default-border-color;
        color: $string-mgmt-default-text-color;
      }
    }
  }

  .string-status {
    background: $string-mgmt-default-bg-color;
    border: 1px solid $string-mgmt-default-border-color;
    border-bottom: none;
    padding: 3px 8px;
    display: inline-block;
    border-radius: 3px 3px 0 0;
    z-index: 0;

    &.custom {
      background-color: $string-mgmt-custom-bg-color;
      border-color: $string-mgmt-custom-border-color;
    }
  }
}

.string-mgmt-edit-dialog {
  .ngdialog-content {
    padding: 0 !important;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $string-mgmt-border-color;
  }

  .string-editor-dialog-header {
    margin: 0;
    padding: 0;
  }

  .modal-header {
    padding: 1em;
    padding-bottom: 0.75em;
    background: $string-mgmt-header-color;
    border-color: $string-mgmt-border-color;

    a {
      color: $string-mgmt-text-color;

      &:hover:not(:active) {
        color: $string-mgmt-default-text-color;
      }
    }
  }

  .modal-body {
    padding: 1em 2.5em;
  }

  .modal-footer {
    padding: 1em 2.5em;
    padding-top: 0;
    border: none;
  }
}
.panel-label{
  text-indent: 15px;
}
