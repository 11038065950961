    /*
    Flaticon icon font: Flaticon
    Creation date: 23/02/2018 23:32
    */

    @font-face {
  font-family: "Flaticon";
  src: url(./styles/formIcons/Flaticon.eot);
  src: url(./styles/formIcons/Flaticon.eot#iefix) format("embedded-opentype"),
       url(./styles/formIcons/Flaticon.woff) format("woff"),
       url(./styles/formIcons/Flaticon.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
    margin-left: 0;
  }
}

    [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
      font-family: Flaticon;
      font-style: normal;
      margin-left: 0;
    }

    .ficon {
      font-size: 28px;
    }

    .ficon-sm {
      font-size: 20px;
  }

    .ficon-xs {
      font-size: 14px;
    }

    .ficon-lg {
      font-size: 40px;
    }

    .ficon-hg {
      font-size: 60px;
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-collapse-button:before { content: "\f100"; }
.flaticon-expand-button:before { content: "\f101"; }
.flaticon-text-editor:before { content: "\f102"; }
.flaticon-transport:before { content: "\f103"; }
.flaticon-mountain:before { content: "\f104"; }
.flaticon-tracks:before { content: "\f105"; }
.flaticon-road-and-tracks:before { content: "\f106"; }
.flaticon-settings:before { content: "\f107"; }
.flaticon-controls:before { content: "\f108"; }
.flaticon-push-notification-1:before { content: "\f109"; }
.flaticon-view-selection:before { content: "\f10a"; }
.flaticon-personal-form:before { content: "\f10b"; }
.flaticon-pause:before { content: "\f10c"; }
.flaticon-exit:before { content: "\f10d"; }
.flaticon-user-info1:before { content: "\f10e"; }
.flaticon-internet:before { content: "\f10f"; }
.flaticon-reset-field-entry-value:before { content: "\f110"; }
.flaticon-pdf1:before { content: "\f111"; }
.flaticon-set-current-page:before { content: "\f112"; }
.flaticon-email:before { content: "\f113"; }
.flaticon-page-link:before { content: "\f114"; }
.flaticon-bucket:before { content: "\f115"; }
.flaticon-avatar:before { content: "\f116"; }
.flaticon-link:before { content: "\f117"; }
.flaticon-weight:before { content: "\f118"; }
.flaticon-window:before { content: "\f119"; }
.flaticon-arrows-down:before { content: "\f11a"; }
.flaticon-pen:before { content: "\f11b"; }
.flaticon-line:before { content: "\f11c"; }
.flaticon-justify-center:before { content: "\f11d"; }
.flaticon-photo:before { content: "\f11e"; }
.flaticon-justify-right:before { content: "\f11f"; }
.flaticon-justiiy-left:before { content: "\f120"; }
.flaticon-take-picture:before { content: "\f121"; }
.flaticon-notification:before { content: "\f122"; }
.flaticon-slider:before { content: "\f123"; }
.flaticon-workflow:before { content: "\f124"; }
.flaticon-padding:before { content: "\f125"; }
.flaticon-padding-right:before { content: "\f126"; }
.flaticon-padding-left:before { content: "\f127"; }
.flaticon-padding-bottom:before { content: "\f128"; }
.flaticon-padding-top:before { content: "\f129"; }
.flaticon-margin-top:before { content: "\f12a"; }
.flaticon-margin-bottom:before { content: "\f12b"; }
.flaticon-margin-left:before { content: "\f12c"; }
.flaticon-margin:before { content: "\f12d"; }
.flaticon-view-input:before { content: "\f12e"; }
.flaticon-function:before { content: "\f12f"; }
.flaticon-check-boxes:before { content: "\f130"; }
.flaticon-circle:before { content: "\f131"; }
.flaticon-color-picker:before { content: "\f132"; }
.flaticon-line-dashed:before { content: "\f133"; }
.flaticon-click:before { content: "\f134"; }
.flaticon-clicker:before { content: "\f135"; }
.flaticon-label-reference:before { content: "\f136"; }
.flaticon-boolean-selector:before { content: "\f137"; }
.flaticon-text-check:before { content: "\f138"; }
.flaticon-target:before { content: "\f139"; }
.flaticon-change-setting:before { content: "\f13a"; }
.flaticon-text-label:before { content: "\f13b"; }
.flaticon-section-normal:before { content: "\f13c"; }
.flaticon-file-submit:before { content: "\f13d"; }
.flaticon-condensed:before { content: "\f13e"; }
.flaticon-form:before { content: "\f13f"; }
.flaticon-party:before { content: "\f140"; }
.flaticon-state:before { content: "\f141"; }
.flaticon-come-in:before { content: "\f142"; }
.flaticon-font-large:before { content: "\f143"; }
.flaticon-gear:before { content: "\f144"; }
.flaticon-video-click:before { content: "\f145"; }
.flaticon-title:before { content: "\f146"; }
.flaticon-days:before { content: "\f147"; }
.flaticon-color-palate:before { content: "\f148"; }
.flaticon-number-fun:before { content: "\f149"; }
.flaticon-handshake:before { content: "\f14a"; }
.flaticon-youtube:before { content: "\f14b"; }
.flaticon-preview:before { content: "\f14c"; }
.flaticon-eye-crossed:before { content: "\f14d"; }
.flaticon-form-black:before { content: "\f14e"; }
.flaticon-check-narrow:before { content: "\f14f"; }
.flaticon-expand:before { content: "\f150"; }
.flaticon-refresh:before { content: "\f151"; }
.flaticon-round-remove-button:before { content: "\f152"; }
.flaticon-radio-button:before { content: "\f153"; }
.flaticon-rectangle:before { content: "\f154"; }
.flaticon-state-rule:before { content: "\f155"; }
.flaticon-eye:before { content: "\f156"; }
.flaticon-sections:before { content: "\f157"; }
.flaticon-clock:before { content: "\f158"; }
.flaticon-number-circle:before { content: "\f159"; }
.flaticon-symbol:before { content: "\f15a"; }
.flaticon-actions:before { content: "\f15b"; }
.flaticon-person-info:before { content: "\f15c"; }
.flaticon-pencil:before { content: "\f15d"; }
.flaticon-wheel:before { content: "\f15e"; }
.flaticon-width:before { content: "\f15f"; }
.flaticon-height:before { content: "\f160"; }
.flaticon-trigger:before { content: "\f161"; }
.flaticon-check-black:before { content: "\f162"; }
.flaticon-custom:before { content: "\f163"; }
.flaticon-function-mathematical-symbol:before { content: "\f164"; }
.flaticon-form-read:before { content: "\f165"; }
.flaticon-check-mark:before { content: "\f166"; }
.flaticon-horizontal:before { content: "\f167"; }
.flaticon-vertical:before { content: "\f168"; }
.flaticon-editing:before { content: "\f169"; }
.flaticon-slider-small:before { content: "\f16a"; }
.flaticon-id-card:before { content: "\f16b"; }
.flaticon-radio-buttons:before { content: "\f16c"; }
.flaticon-dropdown:before { content: "\f16d"; }
.flaticon-font-type:before { content: "\f16e"; }
.flaticon-copy-document:before { content: "\f16f"; }
.flaticon-save:before { content: "\f170"; }
.flaticon-text-small:before { content: "\f171"; }
.flaticon-square:before { content: "\f172"; }
.flaticon-spinner-of-dots:before { content: "\f173"; }
.flaticon-font:before { content: "\f174"; }
.flaticon-day:before { content: "\f175"; }
.flaticon-user-info:before { content: "\f176"; }
.flaticon-line-height-1:before { content: "\f177"; }
.flaticon-push-notification:before { content: "\f178"; }
.flaticon-profile-image:before { content: "\f179"; }
.flaticon-trigger1:before { content: "\f17a"; }
.flaticon-profile-program:before { content: "\f17b"; }
.flaticon-phone:before { content: "\f17c"; }
.flaticon-divider:before { content: "\f17d"; }
.flaticon-font-size:before { content: "\f17e"; }
.flaticon-line-height:before { content: "\f17f"; }
.flaticon-save-black:before { content: "\f180"; }
.flaticon-section-page:before { content: "\f181"; }
.flaticon-input-text:before { content: "\f182"; }
.flaticon-refresh-page-arrow-shape:before { content: "\f183"; }
.flaticon-identical:before { content: "\f184"; }
.flaticon-usb-input:before { content: "\f185"; }
.flaticon-variable-symbol-in-window:before { content: "\f186"; }
.flaticon-signature-with-a-pen:before { content: "\f187"; }
.flaticon-disabled:before { content: "\f188"; }
.flaticon-section:before { content: "\f189"; }
.flaticon-arrows:before { content: "\f18a"; }
.flaticon-margin-right:before { content: "\f18b"; }
.flaticon-view-slider:before { content: "\f18c"; }

    $font-Flaticon-collapse-button: "\f100";
    $font-Flaticon-expand-button: "\f101";
    $font-Flaticon-text-editor: "\f102";
    $font-Flaticon-transport: "\f103";
    $font-Flaticon-mountain: "\f104";
    $font-Flaticon-tracks: "\f105";
    $font-Flaticon-road-and-tracks: "\f106";
    $font-Flaticon-settings: "\f107";
    $font-Flaticon-controls: "\f108";
    $font-Flaticon-push-notification-1: "\f109";
    $font-Flaticon-view-selection: "\f10a";
    $font-Flaticon-personal-form: "\f10b";
    $font-Flaticon-pause: "\f10c";
    $font-Flaticon-exit: "\f10d";
    $font-Flaticon-user-info1: "\f10e";
    $font-Flaticon-internet: "\f10f";
    $font-Flaticon-reset-field-entry-value: "\f110";
    $font-Flaticon-pdf1: "\f111";
    $font-Flaticon-set-current-page: "\f112";
    $font-Flaticon-email: "\f113";
    $font-Flaticon-page-link: "\f114";
    $font-Flaticon-bucket: "\f115";
    $font-Flaticon-avatar: "\f116";
    $font-Flaticon-link: "\f117";
    $font-Flaticon-weight: "\f118";
    $font-Flaticon-window: "\f119";
    $font-Flaticon-arrows-down: "\f11a";
    $font-Flaticon-pen: "\f11b";
    $font-Flaticon-line: "\f11c";
    $font-Flaticon-justify-center: "\f11d";
    $font-Flaticon-photo: "\f11e";
    $font-Flaticon-justify-right: "\f11f";
    $font-Flaticon-justiiy-left: "\f120";
    $font-Flaticon-take-picture: "\f121";
    $font-Flaticon-notification: "\f122";
    $font-Flaticon-slider: "\f123";
    $font-Flaticon-workflow: "\f124";
    $font-Flaticon-padding: "\f125";
    $font-Flaticon-padding-right: "\f126";
    $font-Flaticon-padding-left: "\f127";
    $font-Flaticon-padding-bottom: "\f128";
    $font-Flaticon-padding-top: "\f129";
    $font-Flaticon-margin-top: "\f12a";
    $font-Flaticon-margin-bottom: "\f12b";
    $font-Flaticon-margin-left: "\f12c";
    $font-Flaticon-margin: "\f12d";
    $font-Flaticon-view-input: "\f12e";
    $font-Flaticon-function: "\f12f";
    $font-Flaticon-check-boxes: "\f130";
    $font-Flaticon-circle: "\f131";
    $font-Flaticon-color-picker: "\f132";
    $font-Flaticon-line-dashed: "\f133";
    $font-Flaticon-click: "\f134";
    $font-Flaticon-clicker: "\f135";
    $font-Flaticon-label-reference: "\f136";
    $font-Flaticon-boolean-selector: "\f137";
    $font-Flaticon-text-check: "\f138";
    $font-Flaticon-target: "\f139";
    $font-Flaticon-change-setting: "\f13a";
    $font-Flaticon-text-label: "\f13b";
    $font-Flaticon-section-normal: "\f13c";
    $font-Flaticon-file-submit: "\f13d";
    $font-Flaticon-condensed: "\f13e";
    $font-Flaticon-form: "\f13f";
    $font-Flaticon-party: "\f140";
    $font-Flaticon-state: "\f141";
    $font-Flaticon-come-in: "\f142";
    $font-Flaticon-font-large: "\f143";
    $font-Flaticon-gear: "\f144";
    $font-Flaticon-video-click: "\f145";
    $font-Flaticon-title: "\f146";
    $font-Flaticon-days: "\f147";
    $font-Flaticon-color-palate: "\f148";
    $font-Flaticon-number-fun: "\f149";
    $font-Flaticon-handshake: "\f14a";
    $font-Flaticon-youtube: "\f14b";
    $font-Flaticon-preview: "\f14c";
    $font-Flaticon-eye-crossed: "\f14d";
    $font-Flaticon-form-black: "\f14e";
    $font-Flaticon-check-narrow: "\f14f";
    $font-Flaticon-expand: "\f150";
    $font-Flaticon-refresh: "\f151";
    $font-Flaticon-round-remove-button: "\f152";
    $font-Flaticon-radio-button: "\f153";
    $font-Flaticon-rectangle: "\f154";
    $font-Flaticon-state-rule: "\f155";
    $font-Flaticon-eye: "\f156";
    $font-Flaticon-sections: "\f157";
    $font-Flaticon-clock: "\f158";
    $font-Flaticon-number-circle: "\f159";
    $font-Flaticon-symbol: "\f15a";
    $font-Flaticon-actions: "\f15b";
    $font-Flaticon-person-info: "\f15c";
    $font-Flaticon-pencil: "\f15d";
    $font-Flaticon-wheel: "\f15e";
    $font-Flaticon-width: "\f15f";
    $font-Flaticon-height: "\f160";
    $font-Flaticon-trigger: "\f161";
    $font-Flaticon-check-black: "\f162";
    $font-Flaticon-custom: "\f163";
    $font-Flaticon-function-mathematical-symbol: "\f164";
    $font-Flaticon-form-read: "\f165";
    $font-Flaticon-check-mark: "\f166";
    $font-Flaticon-horizontal: "\f167";
    $font-Flaticon-vertical: "\f168";
    $font-Flaticon-editing: "\f169";
    $font-Flaticon-slider-small: "\f16a";
    $font-Flaticon-id-card: "\f16b";
    $font-Flaticon-radio-buttons: "\f16c";
    $font-Flaticon-dropdown: "\f16d";
    $font-Flaticon-font-type: "\f16e";
    $font-Flaticon-copy-document: "\f16f";
    $font-Flaticon-save: "\f170";
    $font-Flaticon-text-small: "\f171";
    $font-Flaticon-square: "\f172";
    $font-Flaticon-spinner-of-dots: "\f173";
    $font-Flaticon-font: "\f174";
    $font-Flaticon-day: "\f175";
    $font-Flaticon-user-info: "\f176";
    $font-Flaticon-line-height-1: "\f177";
    $font-Flaticon-push-notification: "\f178";
    $font-Flaticon-profile-image: "\f179";
    $font-Flaticon-trigger1: "\f17a";
    $font-Flaticon-profile-program: "\f17b";
    $font-Flaticon-phone: "\f17c";
    $font-Flaticon-divider: "\f17d";
    $font-Flaticon-font-size: "\f17e";
    $font-Flaticon-line-height: "\f17f";
    $font-Flaticon-save-black: "\f180";
    $font-Flaticon-section-page: "\f181";
    $font-Flaticon-input-text: "\f182";
    $font-Flaticon-refresh-page-arrow-shape: "\f183";
    $font-Flaticon-identical: "\f184";
    $font-Flaticon-usb-input: "\f185";
    $font-Flaticon-variable-symbol-in-window: "\f186";
    $font-Flaticon-signature-with-a-pen: "\f187";
    $font-Flaticon-disabled: "\f188";
    $font-Flaticon-section: "\f189";
    $font-Flaticon-arrows: "\f18a";
    $font-Flaticon-margin-right: "\f18b";
    $font-Flaticon-view-slider: "\f18c";
